import React from 'react';
import moment from 'moment';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { apiGetTrabajadores } from '../../../lib/apiSys21';
import { apiUpdateDetailsCard } from '../../../lib/apiPaseLista';

const cardsStatus = [
    { id: 1, name: 'En proceso' },
    { id: 2, name: 'Pendiente' },
    { id: 3, name: 'Por validar' },
    { id: 4, name: 'Pendiente validar RH' },
    { id: 5, name: 'Validado-RH' },
    { id: 6, name: 'incidencia' },
    { id: 7, name: 'Incidencia supervisor' },
    { id: 8, name: 'Incidencia RH' },
];

const horarios = [
    { id: 1, turno: 'Matutino', hora_fin: '13:00:00', hora_inicio: '8:00:00' },
    { id: 2, turno: 'Vespertino', hora_fin: '18:00:00', hora_inicio: '13:00:00' },
];

const CardInfo = props => {
    const { card: cardinfo, NotificationManager, id, editable } = props;

    const [card, setCard] = React.useState(cardinfo);
    const [isEditing, setIsEditing] = React.useState(false);
    const [typeaheadSupervisor, setTypeaheadSupervisor] = React.useState({ isLoading: false, options: [] });
    const [typeaheadCuadrillero, setTypeaheadCuadrillero] = React.useState({ isLoading: false, options: [] });
    const [editedInfo, setEditedInfo] = React.useState({
        id_supervisor: null,
        id_cuadrillero: null,
        id_horario: cardinfo.id_horario,
        horas_extra: 0,
    });

    React.useEffect(() => {
        setCard(cardinfo);
    }, [cardinfo]);

    //region typeaheadsupervisor
    const handleSelectedSupervisorOnTypeahead = obj => {
        if (obj.length > 0) {
            let supervisor = obj[0];
            setEditedInfo({ ...editedInfo, id_supervisor: supervisor.sys21_id });
        }
    };

    const searchSupervisor = search => {
        apiGetTrabajadores({ search, supervisor: true }).then(response => {
            if (response.error) {
                NotificationManager.error('Error al buscar trabajadores', '', 5000);
            } else {
                setTypeaheadSupervisor({
                    isLoading: false,
                    options: response.rows.map(elm => ({ ...elm, id_name: elm.sys21_id + ' ' + elm.nombre })),
                });
            }
        });
    };
    //endregion

    //region typeaheadcuadrillero
    const handleSelectedCuatrilleroOnTypeahead = obj => {
        if (obj.length > 0) {
            let cuadrillero = obj[0];
            setEditedInfo({ ...editedInfo, id_cuadrillero: cuadrillero.sys21_id });
        }
    };

    const searchCuadrillero = search => {
        apiGetTrabajadores({ search, supervisor: false }).then(response => {
            if (response.error) {
                NotificationManager.error('Error al buscar trabajadores', '', 5000);
            } else {
                setTypeaheadCuadrillero({
                    isLoading: false,
                    options: response.rows.map(elm => ({ ...elm, id_name: elm.sys21_id + ' ' + elm.nombre })),
                });
            }
        });
    };
    //endregion

    const changeIsEditing = () => {
        if (isEditing) {
            setEditedInfo({ id_supervisor: null, id_cuadrillero: null, id_horario: null, horas_extra: null });
        }
        setIsEditing(!isEditing);
    };

    const changeHorario = e => {
        setEditedInfo({ ...editedInfo, id_horario: e.target.value });
    };

    const saveInfo = () => {
        console.log('info', editedInfo);
        let obj = {};
        for (const key in editedInfo) {
            if (editedInfo[key]) {
                obj[key] = editedInfo[key];
            }
        }
        apiUpdateDetailsCard(id, obj).then(response => {
            if (response.error) {
                NotificationManager.error(response.message, 'Error', 5000);
            } else {
                NotificationManager.success('Se han guardado los cambios', '', 5000);
                setCard(response.obj_tarjeta);
                setIsEditing(false);
                changeIsEditing();
            }
        });
    };

    const changeExtraHours = e => {
        setEditedInfo({ ...editedInfo, horas_extra: e.target.value });
    };

    return (
        <div className="card separate">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 col-12">
                        <h5>
                            <i className="fas fa-id-card" /> Información tarjeta
                        </h5>
                    </div>

                    <div className="col-md-6 col-12 tar">
                        {editable &&
                            (!isEditing ? (
                                <button className="btn btn-lgt btn-success" onClick={changeIsEditing}>
                                    <i className="fas fa-edit" /> Editar información
                                </button>
                            ) : (
                                <React.Fragment>
                                    <button className="btn btn-lgt btn-danger" onClick={changeIsEditing}>
                                        <i className="fas fa-times" /> Cancelar
                                    </button>
                                    <button className="btn btn-lgt btn-success" onClick={saveInfo}>
                                        <i className="fas fa-save" /> Guardar
                                    </button>
                                </React.Fragment>
                            ))}
                    </div>
                </div>
                <hr />
                <div>
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <label className="lbl-title">Folio</label>
                            <p>{card.folio}</p>
                        </div>
                        <div className="col-md-2 col-12">
                            <label className="lbl-title">Estado</label>
                            <p>{cardsStatus.find(elm => elm.id === card.id_estatus)?.name}</p>
                        </div>
                        <div className="col-md-2 col-12">
                            <label className="lbl-title">Fecha</label>
                            <p>{moment(card.createdAt).format('DD/MMM/YYYY')}</p>
                        </div>
                        <div className="col-md-2 col-12">
                            <label className="lbl-title">Día</label>
                            <p>{card.dia}</p>
                        </div>
                        <div className="col-md-2 col-12">
                            <label className="lbl-title">No de semana</label>
                            <p>{card.semana}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3 col-12">
                            <label className="lbl-title">No. de trabajadores</label>
                            <p>{card.num_trabajadores}</p>
                        </div>
                        <div className="col-md-3 col-12 tac">
                            <label className="lbl-title">Turno</label>
                            <p>{card.horario}</p>
                            {/* E D I T A B L E */}
                        </div>
                        <div className="col-md-2 col-12 tac">
                            <label className="lbl-title">Hora inico</label>
                            <p>{card.hora_inicio}</p>
                        </div>
                        <div className="col-md-2 col-12 tac">
                            <label className="lbl-title">Hora fin </label>
                            <p>{card.hora_fin}</p>
                        </div>
                        <div className="col-md-2 col-12 tac">
                            <label className="lbl-title">Horas extra </label>
                            <p>{card.horas_extra}</p>
                        </div>
                    </div>

                    {isEditing && (
                        <div className="row">
                            <div className="col-md-3 col-12"></div>
                            <div className="col-md-3 col-12 tac">
                                <label className="lbl-title">Cambiar Turno</label>
                                <select className="form-control" value={editedInfo.id_horario} onChange={changeHorario}>
                                    {horarios.map(elm => (
                                        <option key={elm.id} value={elm.id}>
                                            {elm.turno}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-2 col-12"></div>
                            <div className="col-md-2 col-12"></div>
                            <div className="col-md-2 col-12 tac">
                                <label className="lbl-title">Cambiar horas extra </label>
                                <input className="form-control" value={editedInfo.horas_extra} onChange={changeExtraHours} />
                            </div>
                        </div>
                    )}
                    <hr />

                    <div className="row">
                        <div className="col-md-4 col-12">
                            <label className="lbl-title">Apuntador</label>
                            <p>{card.apuntador.id + ' - ' + card.apuntador.nombre}</p>
                        </div>
                        <div className="col-md-4 col-12">
                            <label className="lbl-title">Supervisor</label>
                            <p>{card.supervisor.id + ' - ' + card.supervisor.nombre}</p>
                            {/* E D I T A B L E */}
                        </div>
                        <div className="col-md-4 col-12">
                            <label className="lbl-title">Cuadrillero</label>
                            <p>{card.cuadrillero.id + ' - ' + card.cuadrillero.nombre}</p>
                            {/* E D I T A B L E */}
                        </div>
                    </div>

                    {isEditing && (
                        <div className="row">
                            <div className="col-md-4 col-12"></div>
                            <div className="col-md-4 col-12">
                                {card.id_estatus <= 3 && (
                                    <React.Fragment>
                                        <label className="lbl-title">Cambiar Supervisor por</label>
                                        <AsyncTypeahead
                                            id="typeaheadSupervisor"
                                            labelKey="id_name"
                                            multiple={false}
                                            minLength={1}
                                            onChange={handleSelectedSupervisorOnTypeahead}
                                            isLoading={typeaheadSupervisor.isLoading}
                                            filterBy={['nombre']}
                                            selectHintOnEnter={true}
                                            clearButton={true}
                                            searchText="Buscando..."
                                            options={typeaheadSupervisor.options}
                                            placeholder="Ingrese nombre de supervisor"
                                            onSearch={query => searchSupervisor(query)}
                                            renderMenuItemChildren={(option, props) => {
                                                return <div key={option.id}>{option.id_name}</div>;
                                            }}
                                        />
                                    </React.Fragment>
                                )}
                            </div>
                            <div className="col-md-4 col-12">
                                <label className="lbl-title">Cambiar Cuadrillero por</label>
                                <AsyncTypeahead
                                    id="typeaheadCuadrillero"
                                    labelKey="id_name"
                                    multiple={false}
                                    minLength={1}
                                    onChange={handleSelectedCuatrilleroOnTypeahead}
                                    isLoading={typeaheadCuadrillero.isLoading}
                                    filterBy={['nombre']}
                                    selectHintOnEnter={true}
                                    clearButton={true}
                                    searchText="Buscando..."
                                    options={typeaheadCuadrillero.options}
                                    placeholder="Ingrese nombre de cuadrillero"
                                    onSearch={query => searchCuadrillero(query)}
                                    renderMenuItemChildren={(option, props) => {
                                        return <div key={option.id}>{option.id_name}</div>;
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

CardInfo.defaultProps = {
    supervisor: { id: 0, nombre: '' },
    cuadrillero: { id: 0, nombre: '' },
    apuntador: { id: 0, nombre: '' },
};

export default CardInfo;
