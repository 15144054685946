import React from 'react';
import { Modal } from 'react-bootstrap';

const modalDesactivar = (props) => {
    const { show,
        handleClose, type,
        typeUser, name,
        confirm } = props;

    const handleConfirm = () => {
        confirm();
        handleClose();
    }

    return (


        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{type === "desactivar" ? 'Desactivar' : 'Activar'} usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="tac">Está a punto de {type} a un {typeUser}, <br/> una vez desactivado este usuario no será autorizado para acceder a la plataforma o aplicación.</div>
                <div className="tac separate">¿{type === "desactivar" ? 'Desactivar' : 'Activar'} usuario <b>{name}</b>?</div>
                <div className="tac separete">
                    <button className="btn btn-success btn-lgt" onClick={handleConfirm}>Confirmar</button>
                </div>
            </Modal.Body>
        </Modal>

    );
}

modalDesactivar.defaultProps = {
    show: false,
    name: '',
    type: 'desactivar',
    typeUser: '',
    confirm: () => { },
    handleClose: () => { },
}


export default modalDesactivar;