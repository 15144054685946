import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/** Old props
 * card
 * history
 * confirm
 * onCardClick
 * goToDetails
 */

moment.updateLocale('es', {
  monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
});

const PaselistaCard = ({ listMode, card, onButtonClick, selecting }) => {
  const cardStatus = React.useMemo(() => {
    switch (listMode) {
      case 'secretario':
        return {
          1: 'En proceso',
          2: 'Pendiente',
          3: 'Por validar',
          4: 'Validado',
          5: 'Validado-RH',
          6: 'incidencia',
          7: 'En proceso',
          8: 'Incidencia RH',
        };
      case 'rh':
        return {
          1: 'En proceso',
          2: 'Pendiente',
          3: 'Por validar',
          4: 'Pendiente RH',
          5: 'Validado-RH',
          6: 'incidencia',
          7: 'En proceso',
          8: 'Incidencia RH',
        };
      default:
        return [];
    }
  }, [listMode]);

  const buttonConfig = React.useMemo(() => {
    const buttonConfig = {
      text: 'Detalles',
      class: 'btn-primary',
    };

    if (selecting) {
      if (card.selected) {
        buttonConfig.text = 'Seleccionado';
        buttonConfig.class = 'btn-success';
      } else {
        buttonConfig.text = 'Seleccionar';
        buttonConfig.class = 'btn-info';
      }
    }
    return buttonConfig;
  }, [selecting, card.selected]);

  return (
    <div className="col-12 col-md-4 separate">
      <div className="card agrocard">
        <div className="card-body">
          <div className="row no-pd-row">
            <label className="col-md-8 lbl-card-title">
              <b>{moment(card.createdAt).format('DD/MMM/YYYY')}</b>
            </label>
            <label className="col-md-4 tar lbl-card-title">
              <b>Semana {card.semana}</b>
            </label>
          </div>

          <div className="row no-pd-row">
            <label className="col-md-8 lbl-card-title">
              Folio:<b> {card.folio}</b>
            </label>

            <label className="col-md-4 tar lbl-card-title">
              {card.trab_manual === 1 && (
                <label title="Trabajadores manuales" className="badge badge-danger">
                  <i className="fas fa-exclamation-triangle" />{' '}
                </label>
              )}
              {card.modificado === 1 && (
                <label title="La tarjeta se ha modificado" className="badge badge-warning">
                  <i className="fas fa-exclamation-triangle" />{' '}
                </label>
              )}
            </label>
          </div>

          <hr />

          <div className="row no-pd-row">
            <div className="col-md-6">
              <label className="lbl-card-title lblstatus">Estado</label>
              <label className="status">{cardStatus[card.id_estatus]}</label>
            </div>
            <div className="col-md-6">
              <label className="lbl-card-title">Turno</label>
              <p>{card.horario}</p>
            </div>
          </div>

          <div>
            <label className="lbl-card-title">Supervisor</label>
            <p>{card.supervisor}</p>
          </div>

          <div className="row no-pd-row">
            <div className="col-md-6">
              <label className="lbl-card-title">Actividades</label>
              <p className="cut">{card.actividades}</p>
            </div>
            <div className="col-md-6">
              <label className="lbl-card-title">No. de trabajadores</label>
              <p>{card.trabajadores}</p>
            </div>
          </div>

          <div>
            <label className="lbl-card-title">Lotes</label>
            <p className="cut">{card.lotes}</p>
          </div>

          <div>
            <label className="lbl-card-title">Cultivos</label>
            <p className="cut">{card.cultivos}</p>
          </div>

          <div>
            <button onClick={() => onButtonClick(card.id)} className={`btn btn-lgt w-100 ${buttonConfig.class}`}>
              {buttonConfig.text}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

PaselistaCard.propTypes = {
  listMode: PropTypes.string,
  card: PropTypes.any,
  onButtonClick: PropTypes.func,
  selecting: PropTypes.bool,
};

PaselistaCard.defaultProps = {
  listMode: 'secretario',
  card: {},
  onButtonClick: f => f,
  selecting: false,
};

export default PaselistaCard;
