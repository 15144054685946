import React from "react";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { apiGetActividades } from "../../lib/apiSys21";

const hoursOptions = [
    { value: 0.5, hour: '00:30' },
    { value: 1.0, hour: '01:00' },
    { value: 1.5, hour: '01:30' },
    { value: 2.0, hour: '02:00' },
    { value: 2.5, hour: '02:30' },
    { value: 3.0, hour: '03:00' },
    { value: 3.5, hour: '03:30' },
    { value: 4.0, hour: '04:00' },
    { value: 4.5, hour: '04:30' },
    { value: 5.0, hour: '05:00' },
    { value: 5.5, hour: '05:30' },
    { value: 6.0, hour: '06:00' },
    { value: 6.5, hour: '06:30' },
    { value: 7.0, hour: '07:00' },
    { value: 7.5, hour: '07:30' },
    { value: 8.0, hour: '08:00' },
    { value: 8.5, hour: '08:30' },
    { value: 9.0, hour: '09:00' },
    { value: 9.5, hour: '09:30' },
    { value: 10.0, hour: '10:00' },
    { value: 10.5, hour: '10:30' },
]

const CardActivities = (props) => {
    const { changeTab, NotificationManager, saveActivities } = props;
    const typeaheadRef = React.useRef(null);
    const [typeaheadObj, setTypeaheadObj] = React.useState({ isLoading: false, options: [] });
    const [selectedActivities, setSelectedActivities] = React.useState([]);

    const sortAscArray = (array) => {
        return array.sort(function (a, b) { return a.sys21_id - b.sys21_id })
    }

    const handleSelectedElementOnTypeahead = (obj) => {
        if (obj.length === 0) {
            typeaheadRef.current._instance.clear();
        } else {
            const elm = obj[0];
            if (!selectedActivities.find(activity => activity.sys21_id === elm.sys21_id)) {
                let newArray = [{ ...elm, numero_horas: 0.5, hora_extra: false }, ...selectedActivities];
                setSelectedActivities(sortAscArray(newArray));
                typeaheadRef.current._instance.clear();
            } else {
                NotificationManager.warning("La actividad seleccionada ya se encuentra en la lista", "", 5000);
            }
        }
    }

    const deleteActivityFromList = (id_actividad) => {
        setSelectedActivities(selectedActivities.filter(worker => worker.sys21_id !== id_actividad));
    }

    const searchElement = (search) => {
        setTypeaheadObj({ isLoading: true, options: [] });
        apiGetActividades({ supervisor: 'false', search, limit: 7 })
            .then(response => {
                if (response.error) {

                } else {
                    setTypeaheadObj({ isLoading: false, options: response.rows.map(elm => ({ ...elm, id_name: elm.sys21_id + ' - ' + elm.nombre })) });
                }
            })
            .catch()
            .finally(response => {
                setTypeaheadObj({ isLoading: false });
            })
    }

    const setExtraHours = (id_activity) => {
        setSelectedActivities(selectedActivities.map(elm => elm.sys21_id === id_activity ?
            { ...elm, hora_extra: !elm.hora_extra }
            : { ...elm, hora_extra: false }));
    }

    const setHours = (e, id_activity) => {
        setSelectedActivities(selectedActivities.map(elm => elm.sys21_id === id_activity ?
            { ...elm, numero_horas: e.target.value }
            : { ...elm }));
    }

    const MoveForward = () => {
        if (selectedActivities.length > 0) {
            saveActivities(selectedActivities.map(a => ({ id: a.sys21_id, hora_extra: a.hora_extra, numero_horas: a.numero_horas })));
            changeTab(3);
        } else {
            NotificationManager.warning('No has seleccionado actividades', '', 5000);
        }
    }

    return <div className="card-body">
        <div className="form">
            <div className="row separate">
                <div className="col-md-6">
                    <label><b>Elegir actividades</b></label>
                    <AsyncTypeahead
                        ref={typeaheadRef}
                        id={"typeahead"}
                        labelKey='id_name'
                        minLength={1}
                        onChange={handleSelectedElementOnTypeahead}
                        isLoading={typeaheadObj.isLoading}
                        filterBy={['nombre']}
                        selectHintOnEnter={true}
                        clearButton={true}
                        searchText="Buscando..."
                        options={typeaheadObj.options}
                        placeholder={"Ingrese nombre de la actividad "}
                        onSearch={(query) => searchElement(query)}
                        renderMenuItemChildren={(option, props) => {
                            return <div key={option.id}>
                                {option.id_name}
                            </div>
                        }
                        }
                        required={true}
                    />
                </div>
            </div>
            <div className="col-12">
                <p><b>Actividades seleccionadas</b></p>
            </div>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>ID</th>
                            <th>Nombre de actividad</th>
                            <th>Horas</th>
                            <th>Horas Extras</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedActivities.map(activity =>
                            <tr key={activity.sys21_id}>
                                <td onClick={() => deleteActivityFromList(activity.sys21_id)}><i className="fas fa-times" /></td>
                                <td>{activity.sys21_id}</td>
                                <td>{activity.nombre}</td>
                                <td>
                                    <select className="form-control" value={activity.numero_horas} onChange={(e) => setHours(e, activity.sys21_id)}>
                                        {
                                            hoursOptions.map(hour =>
                                                <option key={hour.hour} value={hour.value}>{hour.hour}</option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td >
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={"check-" + activity.sys21_id} checked={activity.hora_extra} onChange={() => setExtraHours(activity.sys21_id)} />
                                        <label className="custom-control-label" htmlFor={'check-' + activity.sys21_id} ></label>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="tac separate">
            <button className="btn btn-primary btn-lgt " onClick={() => changeTab(1)}>Anterior</button>
            <button className="btn btn-success btn-lgt " onClick={MoveForward}>Siguiente</button>
        </div>
    </div>
}

export default CardActivities;