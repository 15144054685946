import { baseUrl, POST, fetchData } from './constants';

const authUrl = baseUrl + 'auth/';

export const apiSignIn = async (body) => {
    const url = authUrl + "login";

    const request = {
        method: POST,
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(body)
    };

    return fetch(url, request)
        .then(response => response.json());
};