import React from 'react';
import { NotificationManager } from 'react-notifications';
import CardInfo from './cardInfo';
import CardActivities from './cardActivities';
import CardWorkers from './cardWorkers';
import { apiValidateCardData, apiCreateCard } from '../../lib/apiPaseLista';
import moment from 'moment';

const NewCardContainer = props => {
  const [tabActive, setTabActive] = React.useState(1);
  const [verifiedData, setVerifiedData] = React.useState({
    lotes: [],
    id_supervisor: 0,
    id_cuadrillero: 0,
    id_horario: 1,
    fecha: moment()
      .startOf('day')
      .toISOString(),
  });

  const [activitiesList, setActivitiesList] = React.useState([]);

  const changeTab = tab => {
    setTabActive(tab);
  };

  const dataVerifications = form => {
    let obj = {
      ...form,
      lotes: form.lotes.map(lote => lote.sys21_id),
      fecha: moment()
        .startOf('day')
        .toISOString(),
    };
    apiValidateCardData(obj)
      .then(response => {
        if (response.error) {
          NotificationManager.error(response.message, '', 5000);
        } else {
          setVerifiedData(obj);
          changeTab(2);
        }
      })
      .catch(error => {
        NotificationManager.error('error', '', 5000);
      });
  };

  const saveActivities = activities => {
    setActivitiesList(activities);
  };

  const createCard = workers => {
    console.log({ ...verifiedData, trabajadores: workers, actividades: activitiesList });
    apiCreateCard({ ...verifiedData, trabajadores: workers, actividades: activitiesList })
      .then(response => {
        if (response.error) {
          NotificationManager.error(response.message, '', 5000);
        } else {
          NotificationManager.success('Se ha creado la tarjeta', '', 5000);
          props.history.push('/paselista/detalle/' + response.tarjeta.id);
        }
      })
      .catch();
  };

  return (
    <div>
      <div className="separate">
        <h4>
          <i className="fas fa-arrow-left" /> Crear Tarjeta
        </h4>
      </div>

      <div className="card">
        <div className="card-tabs">
          <div className="row">
            <div className="col-4 tac">
              <label className={tabActive === 1 ? 'active' : ''}>Información de tarjeta</label>
            </div>
            <div className="col-4 tac">
              <label className={tabActive === 2 ? 'active' : ''}>Actividades</label>
            </div>
            <div className="col-4 tac">
              <label className={tabActive === 3 ? 'active' : ''}>Trabajadores</label>
            </div>
          </div>
        </div>

        <div className={tabActive === 1 ? '' : 'hide'}>
          <CardInfo NotificationManager={NotificationManager} changeTab={changeTab} dataVerifications={dataVerifications} />
        </div>
        <div className={tabActive === 2 ? '' : 'hide'}>
          <CardActivities NotificationManager={NotificationManager} changeTab={changeTab} saveActivities={saveActivities} />
        </div>
        <div className={tabActive === 3 ? '' : 'hide'}>
          <CardWorkers
            NotificationManager={NotificationManager}
            changeTab={changeTab}
            createCard={createCard}
            verifiedData={{ fecha: verifiedData.fecha, id_horario: verifiedData.id_horario }}
          />
        </div>
      </div>
    </div>
  );
};

export default NewCardContainer;
