import React from 'react';

/* const cardsStatus = [
    { id: 1, name: 'En proceso' },
    { id: 2, name: 'Pendiente' },
    { id: 3, name: 'Por validar' },
    { id: 4, name: 'Pendiente validar RH' },
    { id: 5, name: 'Validado-RH' },
    { id: 6, name: 'incidencia' },
    { id: 7, name: 'Incidencia supervisor' },
    { id: 8, name: 'Incidencia RH' },
]; */

const PaselistaTabs = ({ active, counters, mode, onTabClick, onSpecialClick }) => {
  const tabs = React.useMemo(() => {
    switch (mode) {
      case 'secretario':
        return [
          { name: 'Pendientes', value: 2, counterName: 'pendientes' },
          { name: 'Por validar', value: 3, counterName: 'por_validar' },
          { name: 'Incidencias', value: 6, counterName: 'incidencias' },
          { name: 'Todas las tarjetas', value: 0, counterName: 'total' },
        ];
      case 'rh':
        return [
          { name: 'Pendientes', counterName: 'pendientes', value: 4 },
          { name: 'Incidencias', counterName: 'incidencias', value: 8 },
          { name: 'Terminadas', counterName: 'terminadas', value: 5 },
        ];
      default:
        return [];
    }
  }, [mode]);

  const specialButtons = React.useMemo(() => {
    switch (mode) {
      case 'secretario':
        return [];
      case 'rh':
        return [{ text: 'Bono de 7 días', value: 'bono' }];
      default:
        return [];
    }
  }, [mode]);

  return (
    <div className="tabs-wrapper">
      <div className="btn-group btn-tabs" role="group">
        {tabs.map(tab => (
          <button
            key={tab.name}
            type="button"
            onClick={() => onTabClick(tab.value)}
            className={'btn btn-light' + (active === tab.value ? ' active' : '')}
          >
            <label>{tab.name}</label>
            {(counters[tab.counterName] !== undefined || counters[tab.counterName] !== null) && (
              <span className="badge badge-secondary" style={{ marginLeft: '.5rem' }}>
                {counters[tab.counterName]}
              </span>
            )}
          </button>
        ))}
      </div>
      <div className="tabs-special-buttons">
        {specialButtons.map(btn => (
          <button key={btn.value} type="button" className="btn btn-success" onClick={onSpecialClick}>
            {btn.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default PaselistaTabs;
