import React from 'react';
import { Switch, Redirect, useRouteMatch, Route } from 'react-router-dom';
import IncidentsApuntador from './incidentsApuntador';

const Incidents = props => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={match.url} to={`${match.url}/apuntador`} />
      <Route exact path={`${match.url}/apuntador`} render={() => <IncidentsApuntador {...props} />} />
    </Switch>
  );
};

export default Incidents;
