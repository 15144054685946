
import React from 'react';
import { apiGetSingleActividad, apiGetActivityType, apiSetActivityType } from '../../lib/apiSys21';
import { useLocation, withRouter } from 'react-router-dom';

const ActividadDetail = (props) => {
    const { state: locationState } = useLocation();

    const { id } = props.match.params;
    const { NotificationManager } = props;
    const [actividad, setActividad] = React.useState(() => {
        if (locationState && locationState.extra) {
            return { id: 0, sys21_id: 0, nombre: '', variedades: [], indicador: locationState.extra.indicador };
        }
        return { id: 0, sys21_id: 0, nombre: '', variedades: [] };
    });
    const [activityTypes, setActivityTypes] = React.useState([]);
    const [selectedType, setSelectedType] = React.useState(0);


    React.useEffect(() => {
        apiGetSingleActividad(id)
            .then(response => {
                if (response.error) {

                } else {
                    setSelectedType(response.id_tipo_actividad);
                    setActividad(prev => ({...prev, ...response}));
                }
            })
    }, [id]);

    React.useEffect(() => {
        apiGetActivityType()
            .then(response => {
                if (response.error) {

                }
                else {
                    setActivityTypes(response);
                }
            })

    }, [])

    const changeActivityType = (e) => {
        const { value } = e.target;
        apiSetActivityType(id, { id_tipo_actividad: value })
            .then(response => {
                if (response.error) {
                    NotificationManager.error(response.message, 'Error', 5000);
                } else {
                    NotificationManager.success('Actualizado con éxito', '', 5000);
                    setSelectedType(value);
                    setActividad({ ...actividad, id_tipo_actividad: value, tipo_actividad: activityTypes.find(elm => elm.id === parseInt(value, 10)).nombre });
                }
            })
    }

    console.log(actividad);

    return <div className="card">
        <div className="card-body">
            <div><h5>{actividad.sys21_id} {actividad.nombre_act}</h5></div>
            <hr />
            <div className="row separate no-pd-row">
                <div className="col-md-3 col-12">
                    <p className="lbl-title">Tipo de actividad</p>
                    <label>{actividad.tipo_actividad}</label>
                    {/* <select className="form-control limit" value={selectedType} onChange={changeActivityType}>
                        {
                            activityTypes.map(elm =>
                                <option key={elm.id} value={elm.id}>{elm.nombre}</option>
                            )
                        }
                    </select> */}
                </div>
                <div className="col-md-3 col-12">
                    <p className="lbl-title">Importe diario</p>
                    <label>{actividad.importe_diario}</label>
                </div>
                <div className="col-md-3 col-12">
                    <p className="lbl-title">Hora extra</p>
                    <label>{actividad.hora_extra}</label>
                </div>
                <div className="col-md-3 col-12">
                    <p className="lbl-title">Destajo</p>
                    <label>{actividad.destajo}</label>
                </div>
            </div>

            <div className="row separate no-pd-row">
                <div className="col-md-3 col-12">
                    <p className="lbl-title">Sueldo variable</p>
                    <label>{actividad.sueldo_variable}</label>
                </div>
                <div className="col-md-3 col-12">
                    <p className="lbl-title">Destajo variable</p>
                    <label>{actividad.nombre_variable || "No disponible"}</label>
                </div>
                <div className="col-md-3 col-12">
                    <p className="lbl-title">Indicador</p>
                    <label>{actividad.indicador ? <i className={'fas fa-lg fa-check-square'} /> : <i className={'fas fa-lg fa-times'} />}</label>
                </div>
            </div>
        </div>
    </div>
}

export default withRouter(ActividadDetail);