import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormInput, WafoFormSelect } from '@wafo/forms';
import WafoDateTime from '../../../Components/Forms/CustomForms/DateTime/datetime';
import styles from './lista.module.css';
import BooleanToggle from '../../../Components/Forms/CustomForms/BooleanToggle/booleanToggle';

const PaselistaFilters = ({ onFilter, onClear, initialFilters, disable }) => {
  const [formKey, setFormKey] = React.useState(Math.random());

  const handleClear = () => {
    setFormKey(Math.random());
    onClear();
  };

  const handleSubmit = (form, values) => {
    if (form.valid) {
      onFilter(values);
    }
  };

  return (
    <div className="card separate">
      <div className="card-body" style={{ paddingBottom: 0 }}>
        <WafoForm key={formKey} formId="form-material" locale="es" onSubmit={handleSubmit} values={initialFilters}>
          <WafoFormSelect
            name="type"
            defaultValue="Tipo de búsqueda"
            customClass="col-2 col-md-2"
            options={[
              { value: 'supervisor', display: 'Supervisor' },
              { value: 'apuntador', display: 'Apuntador' },
              { value: 'trabajador', display: 'Trabajador' },
              { value: 'lote', display: 'Lote' },
              { value: 'actividad', display: 'Actividad' },
            ]}
            extraProps={{
              className: 'form-control select-input-append',
            }}
            validations={{ required: false }}
          />

          <WafoFormInput
            type="text"
            name="search"
            placeholder="Buscar en tarjetas"
            customClass="col-10 col-md-10"
            extraProps={{
              className: 'form-control append-input',
            }}
            validations={{ required: false, maxLength: 254 }}
          />

          <WafoFormSelect
            name="turno"
            label=""
            defaultValue="Seleccione turno"
            customClass="col-2 col-md-2"
            options={[
              { value: '0', display: 'Ambos turnos' },
              { value: '1', display: 'Matutino' },
              { value: '2', display: 'Vespertino' },
            ]}
            extraProps={{
              className: 'form-control limit',
            }}
            validations={{ required: false }}
          />

          <WafoDateTime name="fecha_inicial" customClass="col-2 col-md-2" handleChange />

          <WafoDateTime name="fecha_final" customClass="col-2 col-md-2" handleChange />

          <BooleanToggle name="indirecta" customClass="col-12 col-md-4" handleChange />

          <div className={`col-12 col-md-2 ${styles['filter-buttons']}`}>
            <button className="btn btn-primary" onClick={handleClear} disabled={disable}>
              Limpiar
            </button>
            <button className="btn btn-success" onClick={f => f} disabled={disable}>
              Buscar
            </button>
          </div>
        </WafoForm>
      </div>
    </div>
  );
};

PaselistaFilters.propTypes = {
  onFilter: PropTypes.func,
  onClear: PropTypes.func,
  initialFilters: PropTypes.any,
  disable: PropTypes.bool,
};

PaselistaFilters.defaultProps = {
  onFilter: f => f,
  onClear: f => f,
  initialFilters: {},
  disable: false,
};

export default PaselistaFilters;
