
import React from 'react';
import { apiGetSingleCultivo } from '../../lib/apiSys21';
import { withRouter } from 'react-router-dom';

const CultivoDetail = (props) => {
    const { id } = props.match.params;
    const [cultivo, setCultivo] = React.useState({ id: 0, sys21_id: 0, nombre: '', variedades: [] });

    React.useEffect(() => {
        apiGetSingleCultivo(id)
            .then(response => {
                if (response.error) {

                } else {
                    setCultivo(response);
                }
            })
    }, [id]);

    return <div className="card">
        <div className="card-body">
            <div><h5>{cultivo.sys21_id} {cultivo.nombre}</h5></div>
            <hr />
            <div>
                <label>Variedades del cultivo</label>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nombre variedad</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            cultivo.variedades.map(elm =>
                                <tr key={elm.id_variedad}>
                                    <td>{elm.id_variedad}</td>
                                    <td>{elm.nombre}</td>
                                </tr>)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}

export default withRouter(CultivoDetail);