import React from 'react';
import CuadrillerosTable from './cuadrillerosTable';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import CuadrilleroAsignar from './cuadrilleroAsignar';

const Cuadrilleros = () => {
  const { url } = useRouteMatch();

  return (
    <React.Fragment>
      <div className="page-title ">
        <h3>
          <i className="fas fa-address-card" /> Cuadrilleros
        </h3>
      </div>
      <div>
        <Switch>
          <Route exact path={url} component={CuadrillerosTable} />
          <Route exact path={`${url}/asignar`} component={CuadrilleroAsignar} />
        </Switch>
      </div>
    </React.Fragment>
  );
};

export default Cuadrilleros;
