import React from 'react';
import { connect } from 'react-redux';
import './style.scss';

const Loader = ({ showLoader }) => {
  return (
    <div className={'loader-container ' + (showLoader ? '' : 'hider')}>
      <div className="spinner-border"></div>
    </div>
  );
};

Loader.defaultProps = {
  showLoader: false,
};

export default connect(
  state => ({
    showLoader: state.loader.show,
  }),
  {},
)(Loader);
