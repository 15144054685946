import React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { apiGetActividades } from '../../../lib/apiSys21';

const EditableLote = (props) => {
    const { lote, deleteLote, updateActivity, NotificationManager } = props;
    const [selectedActivityList, setselectedActivityList] = React.useState([]);
    const [typeahead, setTypeahead] = React.useState({ isLoading: false, options: [] });
    const typeaheadRef = React.useRef(null);

    React.useEffect(() => {
        //console.log("actividades", lote.actividades);
        setselectedActivityList(lote.actividades.map(elm => ({ ...elm, total: parseInt(elm.total, 10) })));
    }, [lote]);

    const sortAscArray = (array) => {
        return array.sort(function (a, b) { return a.sys21_id - b.sys21_id })
    }

    const handleSelectedActivityOnTypeahead = (obj) => {
        let activity = obj[0];
        //validar que no este ya agregada en la lista 
        if (!selectedActivityList.find(a => a.sys21_id === activity.sys21_id)) {

            let listActivity = sortAscArray([{ ...activity, total: 0, jornales:0 }, ...selectedActivityList]);
            updateActivity(listActivity, lote);
            setselectedActivityList(listActivity);
            typeaheadRef.current._instance.clear()
        }
        else {
            NotificationManager.warning('la actividad ya está en la lista', '', 5000);
        }
        typeaheadRef.current._instance.focus()
    }

    const searchActivity = (search) => {
        apiGetActividades({ search })
            .then(response => {
                if (response.error) {

                } else {
                    setTypeahead({ isLoading: false, options: response.rows.map(elm => ({ ...elm, id_name: elm.sys21_id + ' - ' + elm.nombre })) })
                }
            })
            .catch()

    }

    const deleteActivity = (activity) => {
        if (selectedActivityList.length === 1) {
            NotificationManager.warning('Debe existir al menos una actividad por cada lote. Antes de eliminar agrega otra actividad.', '', 5900);
        } else {
            let listActivity = selectedActivityList.filter(elm => elm.sys21_id !== activity.sys21_id);
            updateActivity(listActivity, lote);
            setselectedActivityList(listActivity);
        }
    }

    const handleTotalChange = (elm, e) => {
        let value = e.target.value;
        let listActivity = selectedActivityList.map(activity => activity.sys21_id === elm.sys21_id ? { ...activity, total: value } : activity);
        updateActivity(listActivity, lote);
        setselectedActivityList(listActivity);
        // let isNumber = /^[0-9]*$/.test(e.target.value);
        // if (isNumber && isNaN(isNumber)) {
        // }
    }

    const handleJoralChange = (elm, e) => {
        // let isNumber = /^[0-9]*$/.test(e.target.value);
        // if (isNumber) {
        let value = e.target.value;
        let listActivity = selectedActivityList.map(activity => activity.sys21_id === elm.sys21_id ? { ...activity, jornales: value } : activity);
        updateActivity(listActivity, lote);
        setselectedActivityList(listActivity);
        // }
    }

    return <div className="col-md-6">
        <div className="card lote-add">
            <div className="card-header">
                <div className="row">
                    <div className="col-md-10">
                        <input className="form-control" readOnly value={lote.sys21_id + ' - ' + lote.nombre} />
                    </div>
                    <div className="col-md-2">
                        <button className="btn btn-link" onClick={() => deleteLote(lote)} ><i className="fas fa-times" /> </button>
                    </div>
                </div>
            </div>
            <div className="lote-body">
                <div className="search">
                    <AsyncTypeahead
                        ref={(ref) => typeaheadRef.current = ref}
                        id="typeaheadActivity"
                        labelKey='id_name'
                        multiple={false}
                        minLength={1}
                        onChange={handleSelectedActivityOnTypeahead}
                        isLoading={typeahead.isLoading}
                        filterBy={['nombre']}
                        selectHintOnEnter={true}
                        clearButton={false}
                        searchText="Buscando..."
                        options={typeahead.options}
                        placeholder="Agregar Actividad"
                        onSearch={(query) => searchActivity(query)}
                        renderMenuItemChildren={(option, props) => {
                            return <div key={option.id}>
                                {option.id_name}
                            </div>
                        }
                        }
                    />
                </div>
                <div className="activities-container">
                    <div className="row no-row-margin activity-added" >
                        <div className="col-md-1">

                        </div>
                        <div className="col-md-6 tac">
                            <label><b>Actividad</b></label>
                        </div>
                        <div className="col-md-2">
                            <label><b>Total</b></label>
                        </div>
                        <div className="col-md-2">
                            <label><b>Jornal</b></label>
                        </div>
                    </div>
                    {
                        selectedActivityList.map(elm =>
                            <div className="row no-row-margin activity-added" key={elm.sys21_id}>
                                <div className="col-md-1" >
                                    <button className="btn btn-link" onClick={() => deleteActivity(elm)}><i className="fas fa-minus-circle" /></button>
                                </div>
                                <div className="col-md-6">
                                    <input title={elm.sys21_id + ' - ' + elm.nombre} className="form-control" value={elm.sys21_id + ' - ' + elm.nombre} readOnly />
                                </div>
                                <div className="col-md-2">
                                    <input type="number" className="form-control" value={elm.total} onChange={(e) => handleTotalChange(elm, e)} />
                                </div>
                                <div className="col-md-2">
                                    <input type="number" className="form-control" value={elm.jornales} onChange={(e) => handleJoralChange(elm, e)} />
                                </div>
                            </div>
                        )
                    }
                </div>

            </div>
        </div>


    </div>
}

export default EditableLote;