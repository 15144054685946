
import React from 'react';


const input = (props) => {
    return <React.Fragment>
        <input className="input form-control" {...props} />
        <div className="valid-feedback">
            {props.feedback.valid}
        </div>
        <div className="invalid-feedback">
            {props.feedback.invalid}
        </div>
    </React.Fragment>
}

input.defaultProps = {
    onClick: () => { },
    type: "text",
    value: "",
    onChange: () => { },
    feedback: { valid: '', invalid: '' }
}

export default input;