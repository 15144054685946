import React from 'react';
import PaselistaTabs from './Lista/paselistaTabs';
import {
  apiGetCounters,
  apiGetCards,
  apiGetSupervisorCards,
  apiGetRHCards,
  apiValidateRHCards,
} from '../../lib/apiPaseLista';
import PaselistaFilters from './Lista/paselistaFilters';
import moment from 'moment';
import useLoading from '../../hooks/useLoading';
import { NotificationManager } from 'react-notifications';
import PaselistaCard from './Lista/paselistaCard';
import PaselistaNoCards from './Lista/paselistaNoCards';
import { useLocation, useHistory } from 'react-router-dom';
import PaselistaRFID from './Lista/paselistaRFID';
import confirmModal from '../../Components/Modals/confirm';

// TODO:
// > Add page to filters keep in location state.

const today = moment().format('YYYY-MM-DD');
const weekStart = moment().startOf("week").format('YYYY-MM-DD');

const reducer = (state, action) => {
  switch (action.type) {
    case 'TABS_SET':
      return {
        ...state,
        tabs: {
          ...state.tabs,
          active: action.payload,
        },
        filters: initialState.filters,
        cards: initialState.cards,
      };
    case 'TABS_SET_COUNTERS':
      return {
        ...state,
        tabs: {
          ...state.tabs,
          counters: action.payload,
        },
      };
    case 'FILTERS_SET':
      return {
        ...state,
        filters: {
          ...action.payload,
        },
        cards: initialState.cards,
      };
    case 'FILTERS_CLEAR':
      return {
        ...state,
        filters: {
          ...initialState.filters,
        },
        cards: initialState.cards,
      };
    case 'FILTERS_RFID_SET':
      return {
        ...state,
        filters: {
          ...state.filters,
          rfid: action.payload,
          page: 1,
        },
      };
    case 'CARDS_PUSH':
      return {
        ...state,
        cards: {
          ...state.cards,
          count: action.payload.count,
          rows: [...state.cards.rows, ...action.payload.rows],
        },
      };
    case 'CARDS_SET':
      return {
        ...state,
        cards: {
          ...state.cards,
          count: action.payload.count,
          rows: action.payload.rows,
          page: 1,
        },
      };
    case 'CARDS_SET_PAGE':
      return {
        ...state,
        cards: {
          ...state.cards,
          page: action.payload,
        },
      };
    case 'MULTISELECT_SET_SELECTING':
      return {
        ...state,
        multiSelect: {
          ...state.multiSelect,
          selecting: action.payload,
        },
      };
    case 'MULTISELECT_CANCEL':
      return {
        ...state,
        multiSelect: initialState.multiSelect,
        cards: {
          ...state.cards,
          rows: state.cards.rows.map(card => ({ ...card, selected: false })),
        },
      };
    case 'MULTISELECT_TOGGLE_ID':
      return {
        ...state,
        cards: {
          ...state.cards,
          rows: state.cards.rows.map(card =>
            card.id === action.payload ? { ...card, selected: !card.selected } : card,
          ),
        },
      };
    default:
      return state;
  }
};

const initialState = {
  mode: null, // "secretario" || "rh"
  tabs: {
    active: 0,
    counters: {
      total: 0,
    },
  },
  filters: {
    type: '',
    search: '',
    turno: '0',
    fecha_inicial: weekStart,
    fecha_final: today,
    rfid: '',
    filtering: false,
  },
  cards: {
    count: null,
    rows: [],
    page: 1,
    limit: 9,
  },
  modals: {
    aprobarRH: true,
  },
  multiSelect: {
    selecting: false,
    selected: [],
  },
};

const PaseLista = ({ listMode, location: propsLocation }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState, initialState => {
    let preparedState = {
      ...initialState,
      mode: listMode,
      tabs: {
        ...initialState.tabs,
        active: listMode === 'secretario' ? 2 : 4,
      },
    };
    if (propsLocation.state) {
      preparedState = {
        ...preparedState,
        ...(propsLocation.state.tabs && { tabs: propsLocation.state.tabs }),
        ...(propsLocation.state.filters && { filters: propsLocation.state.filters }),
        ...(propsLocation.state.keepPage && {
          cards: {
            ...initialState.cards,
            page: propsLocation.state.keepPage,
          },
        }),
      };
    }
    return preparedState;
  });

  const { tabs, filters, cards, multiSelect } = state;

  const location = useLocation();
  const history = useHistory();
  const loading = useLoading();

  const getCards = React.useCallback(
    async (clearArray = false) => {
      try {
        const estimatedCards = cards.limit * cards.page;
        if (!clearArray && cards.count !== null && (cards.rows.length >= cards.count || estimatedCards === cards.rows.length)) {
          // IMPORTANT avoids infinite loop.
          // cards.count is null on first render. This allows to do the first call.
          // Check if cards length >= cards count, this means we already have all the cards, no need to query for more.
          // Check if estimatedcards equals the actual number of current cards (endpoint already called, no need to call again).
          return;
        }

        if (filters.search && !filters.type) {
          NotificationManager.warning('Seleccione el tipo de búsqueda', '', 5000);
          return;
        }

        loading.set();

        const queryParams = {
          fecha_inicial: filters.fecha_inicial,
          fecha_final: filters.fecha_final,
          ...(tabs.active !== 0 && { id_estatus: tabs.active }),
          ...(filters.turno !== '0' && { id_horario: filters.turno }),
          /* ...(filters.filtering && {
            fecha_inicial: filters.fecha_inicial,
            fecha_final: filters.fecha_final,
          }), */
          ...(filters.search &&
            filters.type && {
              search: filters.search,
              type_search: filters.type,
            }),
          ...(filters.indirecta && { indirecta: !!filters.indirecta }),
          limit: cards.limit,
          page: cards.page,
        };

        // This allows to get all cards previously loaded on first load (when comming back from details).
        if (cards.count === null && location.state && location.state.keepPage) {
          queryParams.page = 1;
          queryParams.limit = location.state.keepPage * cards.limit;
        }

        // TODO: Probar cuando Alexis repare el error 500.
        if (clearArray) {
          queryParams.page = 1;
          queryParams.limit = cards.page * cards.limit;
        }

        let response = { error: true, message: 'Hubo un error preparando la consulta' };

        if (filters.rfid) {
          response = await apiGetSupervisorCards({
            rfid: filters.rfid,
          });
        } else if (listMode === 'secretario') {
          response = await apiGetCards(queryParams);
        } else if (listMode === 'rh') {
          response = await apiGetRHCards(queryParams);
        }

        if (response.error) {
          NotificationManager.error(response.message, '', 5000);
        } else {
          dispatch({
            type: cards.page === 1 || clearArray ? 'CARDS_SET' : 'CARDS_PUSH',
            payload: {
              count: response.count,
              rows: response.rows,
            },
          });
        }

        loading.stop();
      } catch (error) {
        loading.stop();
        console.error(error);
      }
    },
    [tabs.active, filters, loading, cards, listMode, location.state],
  );

  // GET cards
  React.useEffect(() => {
    getCards();
  }, [getCards]);

  // GET counters
  React.useEffect(() => {
    async function getCounters() {
      try {
        const response = await apiGetCounters();
        if (!response.error) {
          dispatch({
            type: 'TABS_SET_COUNTERS',
            payload: response,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

    // Solo para secretario por que así esta hecha la llamada desde el back.
    if (listMode === 'secretario') {
      getCounters();
    }
  }, [listMode]);

  const goToDetails = id => {
    history.replace({
      pathname: location.pathname,
      state: {
        filters,
        tabs,
        // To know which page to load when back from details
        keepPage: cards.page,
      },
    });
    history.push(`/paselista/detalle/${id}`);
  };

  const clearFilters = () => {
    dispatch({ type: 'FILTERS_CLEAR', payload: null });
    history.replace({
      pathname: location.pathname,
    });
  };

  const handleOnScroll = ({ currentTarget, target }) => {
    if (currentTarget.scrollHeight - target.scrollTop === target.clientHeight) {
      const pages = Math.ceil(cards.count / 9);
      if (pages > cards.page) {
        dispatch({ type: 'CARDS_SET_PAGE', payload: cards.page + 1 });
      }
    }
  };

  const handleCardClick = id => {
    if (multiSelect.selecting) {
      dispatch({ type: 'MULTISELECT_TOGGLE_ID', payload: id });
    } else {
      goToDetails(id);
    }
  };

  const confirmMultiselect = () => {
    // If some day we have some other function with multiselect, we have to update this function...
    async function validateRHCards() {
      try {
        loading.set();
        const response = await apiValidateRHCards({
          tarjetas: cards.rows.filter(card => card.selected).map(card => card.id),
        });
        if (response.error) {
          if (response.filed) {
            NotificationManager.warning(response.message + ' ' + response.failed.toString(), '', 5000);
          } else {
            NotificationManager.error(response.message, '', 5000);
          }
        } else {
          NotificationManager.success('Se han validado las tarjetas correctamente', '', 5000);
          dispatch({ type: 'MULTISELECT_SET_SELECTING', payload: false });
          getCards(true);
        }
        loading.stop();
      } catch (error) {
        loading.stop();
        NotificationManager.error('Ocurrió un erro. Inténtelo más tarde.', '', 5000);
        console.error(error);
      }
    }

    confirmModal({
      message: (
        <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
          Se van a probar todas las tarjetas seleccionadas.
          <br />
          ¿Desea continuar?
        </p>
      ),
      buttons: [
        {
          label: 'Cancelar',
          class: 'btn-danger',
        },
        {
          label: 'Aceptar',
          class: 'btn-success',
          onClick: () => validateRHCards(),
        },
      ],
    });
  };

  return (
    <div className="container-page-wrapper">
      <div className="page-title ">
        <h3>
          <i className="fas fa-clipboard-list" /> Pase de lista
        </h3>
      </div>
      <PaselistaTabs
        active={tabs.active}
        counters={tabs.counters}
        mode={state.mode}
        onTabClick={tab => dispatch({ type: 'TABS_SET', payload: tab })}
        onSpecialClick={() => history.push('/paselista/bono')}
      />
      <PaselistaFilters
        onFilter={values => dispatch({ type: 'FILTERS_SET', payload: { ...values, filtering: true } })}
        onClear={clearFilters}
        initialFilters={filters}
        disable={!!filters.rfid}
      />
      {tabs.active === 3 && (
        <PaselistaRFID updateRFID={rfid => dispatch({ type: 'FILTERS_RFID_SET', payload: rfid })} />
      )}

      {/** <MultiselectControls> Sería buena idea que fuera un componente */}
      {listMode === 'rh' && (
        <div className="row justify-content-end">
          <div className="col-12 col-md-2">
            {!multiSelect.selecting && (
              <button
                className="btn btn-sm btn-success w100"
                onClick={() => dispatch({ type: 'MULTISELECT_SET_SELECTING', payload: true })}
                disabled={cards.count === 0}
              >
                <i className="fas fa-check" /> Aprobar varias tarjetas
              </button>
            )}
            {multiSelect.selecting && (
              <div className="buttons-group">
                <button className="btn btn-sm btn-danger w100" onClick={() => dispatch({ type: 'MULTISELECT_CANCEL' })}>
                  <i className="fas fa-times" /> Cancelar
                </button>
                <button className="btn btn-sm btn-success w100" onClick={confirmMultiselect}>
                  <i className="fas fa-check" /> Confirmar
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {/** </MultiselectControls> */}

      <div className="scrollable-div" onScroll={handleOnScroll}>
        {cards.count > 0 && (
          <div className="row">
            {cards.rows.map(card => (
              <PaselistaCard
                key={card.id}
                card={card}
                onButtonClick={handleCardClick}
                selecting={multiSelect.selecting}
                listMode={listMode}
              />
            ))}
          </div>
        )}
        {cards.count === 0 && <PaselistaNoCards />}
      </div>
    </div>
  );
};

export default PaseLista;
