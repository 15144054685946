import { baseUrl, GET, POST, PUT, CreateAuthRequest, makeUrlGET } from './constants';

const urlModule = baseUrl + "user/";
//region apuntador
export const apiGetPrompter = async (filters) => {

    let url = urlModule + "prompter/list?" + makeUrlGET(filters);
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiCreatePrompter = async (body) => {

    let url = urlModule + "prompter";
    let request = await CreateAuthRequest(POST, body, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiUpdatePrompter = async (id, body) => {

    let url = urlModule + "prompter?id=" + id;
    let request = await CreateAuthRequest(PUT, body, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetSinglePrompter = async (id) => {

    let url = urlModule + "prompter?id=" + id;
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

//endregion

//region secretario
export const apiGetSecretary = async (filters) => {

    let url = urlModule + "secretary/list?" + makeUrlGET(filters);
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiCreateSecretary = async (body) => {

    let url = urlModule + "secretary";
    let request = await CreateAuthRequest(POST, body, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiUpdateSecretary = async (id, body) => {

    let url = urlModule + "secretary?id=" + id;
    let request = await CreateAuthRequest(PUT, body, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetSingleSecretary = async (id) => {

    let url = urlModule + "secretary?id=" + id;
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

//endregion

//region SUPERVISOR
export const apiGetSupervisor = async (filters) => {

    let url = urlModule + "supervisor/list?" + makeUrlGET(filters);
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiCreateSupervisor = async (body) => {

    let url = urlModule + "supervisor";
    let request = await CreateAuthRequest(POST, body, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiUpdateSupervisor = async (id, body) => {

    let url = urlModule + "supervisor?id=" + id;
    let request = await CreateAuthRequest(PUT, body, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetSingleSupervisor = async (id) => {

    let url = urlModule + "supervisor?id=" + id;
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

//endregion

//region GERENTE
export const apiGetGerente = async (filters) => {

    let url = urlModule + "manager/list?" + makeUrlGET(filters);
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiCreateGerente = async (body) => {

    let url = urlModule + "manager";
    let request = await CreateAuthRequest(POST, body, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiUpdateGerente = async (id, body) => {

    let url = urlModule + "manager?id=" + id;
    let request = await CreateAuthRequest(PUT, body, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetSingleGerente = async (id) => {

    let url = urlModule + "manager?id=" + id;
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

//endregion

//region RH
export const apiGetRH = async (filters) => {

    let url = urlModule + "hr/list?" + makeUrlGET(filters);
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiCreateRH = async (body) => {

    let url = urlModule + "hr";
    let request = await CreateAuthRequest(POST, body, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiUpdateRH = async (id, body) => {

    let url = urlModule + "hr?id=" + id;
    let request = await CreateAuthRequest(PUT, body, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetSingleRH = async (id) => {

    let url = urlModule + "hr?id=" + id;
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

//endregion

//region ADMIN
export const apiGetAdmin = async (filters) => {

    let url = urlModule + "admin/list?" + makeUrlGET(filters);
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiCreateAdmin = async (body) => {

    let url = urlModule + "admin";
    let request = await CreateAuthRequest(POST, body, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiUpdateAdmin = async (id, body) => {

    let url = urlModule + "admin?id=" + id;
    let request = await CreateAuthRequest(PUT, body, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetSingleAdmin = async (id) => {

    let url = urlModule + "admin?id=" + id;
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

//endregion


export const apiChangeStatus = async (id) => {

    let url = urlModule + "status?id=" + id;
    let request = await CreateAuthRequest(PUT, null, true);

    return fetch(url, request)
        .then(response => response.json())
}