
import React from 'react';
import { apiGetSingleLote } from '../../lib/apiSys21';
import { withRouter } from 'react-router-dom';

const LoteDetail = (props) => {
    const { id } = props.match.params;
    const [lote, setLote] = React.useState({ id: 0, sys21_id: 0, nombre: '', variedades: [], actividades: [] });

    React.useEffect(() => {
        apiGetSingleLote(id)
            .then(response => {
                if (response.error) {

                } else {
                    setLote(response);
                }
            })
    }, [id]);

    return <div className="card">
        <div className="card-body">
            <div><h5>Información de lote</h5></div>
            <hr />
            <div className="row separate no-pd-row">
                <div className="col-md-3 col-12">
                    <p className="lbl-title">Id de lote</p>
                    <label>{lote.sys21_id}</label>
                </div>
                <div className="col-md-3 col-12">
                    <p className="lbl-title">Nombre de lote</p>
                    <label>{lote.nombre_lote}</label>
                </div>
                <div className="col-md-3 col-12">
                    <p className="lbl-title">Nomenclaturas</p>
                    <label>{lote.nomenclatura}</label>
                </div>
                <div className="col-md-3 col-12">
                    <p className="lbl-title">Hectáreas</p>
                    <label>{lote.hectareas}</label>
                </div>
            </div>

            <div className="row separate no-pd-row">
                <div className="col-md-3 col-12">
                    <p className="lbl-title">Id de empresa</p>
                    <label>{lote.id_empresa}</label>
                </div>
                <div className="col-md-3 col-12">
                    <p className="lbl-title">Nombre de empresa</p>
                    <label>{lote.nombre_empresa}</label>
                </div>
                <div className="col-md-3 col-12">
                    <p className="lbl-title">Activo</p>
                    <label>{lote.activo}</label>
                </div>

            </div>

            <div><h5>Información de cultivo</h5></div>
            <hr />
            <div className="row separate no-pd-row">
                <div className="col-md-3 col-12">
                    <p className="lbl-title">Id de cultivo</p>
                    <label>{lote.id_cultivo}</label>
                </div>
                <div className="col-md-3 col-12">
                    <p className="lbl-title">Nombre de cultivo</p>
                    <label>{lote.nombre_cultivo}</label>
                </div>
                <div className="col-md-3 col-12">
                    <p className="lbl-title">Id de variedad</p>
                    <label>{lote.id_variedad}</label>
                </div>
                <div className="col-md-3 col-12">
                    <p className="lbl-title">Nombre de variedad</p>
                    <label>{lote.nombre_variedad}</label>
                </div>
            </div>

            <div className="separate">
                <h5>Información de temporada</h5>
                <hr />
                <div className="row separate no-pd-row">
                    <div className="col-md-3 col-12">
                        <p className="lbl-title">Id de temporada</p>
                        <label>{lote.id_temporada}</label>
                    </div>
                    <div className="col-md-3 col-12">
                        <p className="lbl-title">Nombre de temporada</p>
                        <label>{lote.nombre_temporada}</label>
                    </div>
                    <div className="col-md-3 col-12">
                        <p className="lbl-title">Fecha inicial</p>
                        <label></label>
                    </div>
                    <div className="col-md-3 col-12">
                        <p className="lbl-title">Fecha final</p>
                        <label></label>
                    </div>
                </div>
            </div>

            {lote.actividades.length > 0 && (
                <React.Fragment>
                    <div><h5>Actividades</h5></div>
                    <div className="separate">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Nombre</th>
                                        <th>Monto</th>
                                        <th>Límite</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lote.actividades.map(a => (
                                        <tr key={a.id}>
                                            <td>{a.id_actividad}</td>
                                            <td>{a.nombre}</td>
                                            <td>{a.monto}</td>
                                            <td>{a.limite}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </React.Fragment>
            )}

        </div>
    </div>;
};

export default withRouter(LoteDetail);