import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import styles from './modals.module.css';

const ModalConfirm = ({ title = "Confirmar validación de tarjetas", message, buttons }) => {
  const closeModal = () => removeElement();

  const handleButtonClick = button => {
    if (button.onClick) {
      button.onClick();
    }
    closeModal();
  };

  return (
    <Modal show={true} onHide={closeModal} centered dialogClassName="modal-20w">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {typeof message === 'string' ? <div className={styles['confirm-message']}>{message}</div> : <>{message}</>}
        <div className={styles['confirm-buttons']}>
          {buttons.map((button, i) => (
            <button
              key={i}
              className={`btn ${button.class || 'btn-primary'} ${styles['button']}`}
              onClick={() => handleButtonClick(button)}
            >
              {button.label}
            </button>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

ModalConfirm.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  buttons: PropTypes.array,
};

ModalConfirm.defaultProps = {
  message: '¿Seguro de continuar?',
  buttons: [
    {
      label: 'Cancel',
      class: 'btn-danger',
      onClick: () => null,
    },
    {
      label: 'Confirm',
      onClick: () => null,
    },
  ],
};

function removeElement(id = 'wafo-confirm-alert') {
  const target = document.getElementById(id);
  unmountComponentAtNode(target);
  target.parentNode.removeChild(target);
}

export default function confirmModal(properties, id = 'wafo-confirm-alert') {
  let divTarget = document.getElementById(id);
  if (!divTarget) {
    divTarget = document.createElement('div');
    divTarget.setAttribute('id', id);
    document.body.appendChild(divTarget);
  }
  render(<ModalConfirm {...properties} />, divTarget);
}
