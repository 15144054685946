import React from 'react';
import moment from 'moment';

const CardValidations = (props) => {
    const { validations } = props;
    return <div className="card separate">
        <div className="card-body">
            <div className="">
                <h5><i className="fas fa-id-card" /> Validaciones</h5>
            </div>

            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Usuario</th>
                            <th>Rol</th>
                            <th>Fecha</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            validations.length > 0 ?
                                validations.map(validation =>
                                    <tr key={validation.id}>
                                        <td>{validation.id}</td>
                                        <td>{validation.nombre}</td>
                                        <td>{validation.rol}</td>
                                        <td>{moment(validation.fecha_validacion).format("DD/MM/YYYY hh:mm:ss a")}</td>
                                    </tr>)
                                :
                                <tr><td colSpan="4">Aun no hay validaciones</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}

export default CardValidations;