import React from 'react';
import InputValidator from '../../../Components/Generals/input';
import { apiCreateSupervisor, apiUpdateSupervisor, apiGetSingleSupervisor } from '../../../lib/apiUsers';
import { withRouter } from 'react-router-dom';
import ModalScanner from '../modalScanner';

const AddSupervisor = (props) => {
    const { id } = props.match.params;

    const [form, setForm] = React.useState({
        name: "",
        username: "",
        //password: "",
        pin: null,
        rfid: "",
        sys21_id: 0
    });

    const [showModalScan, setChowModalScan] = React.useState(false);

    const [passwords, setPasswords] = React.useState({ password: '', confirmPassword: '' });
    const [submited, setSubmited] = React.useState(false);

    React.useEffect(() => {
        if (id) {
            apiGetSingleSupervisor(id)
                .then(response => {
                    if (response.error) {

                    }
                    else {
                        setForm({ ...response, password: '' });
                    }
                })

        } else {

        }
    }, [id]);

    const setFormasValues = (e) => {
        const { name, value } = e.target;
        let valueFormat = value;
        if (name === "username") {
            valueFormat = value.replace(/\s/g, '')
        }

        setForm({ ...form, [name]: valueFormat });

    }

    const showMessage = () => {
        //console.log('match', passwords.match, typeof passwords.match);
        if (passwords.match !== undefined) {
            if (passwords.match) {
                return <label className="message"><i className="fas fa-check" /> Las contraseñas coinciden</label>
            }
            else {
                return <label className="message"><i className="fas fa-times" /> Las contraseñas no coinciden</label >
            }
        }
        else {
            return '';
        }
    }

    const submit = (e) => {
        e.preventDefault();

        if (e.target.checkValidity()) {
            if (id) {
                apiUpdateSupervisor(id, form)
                    .then(response => {
                        //mandar a pagina de detalle
                        if (response.error) {
                            props.NotificationManager.error(response.message, 'Algo salió mal', 5000);
                        }
                        else {
                            props.NotificationManager.success('Usuario actualizado con éxito', '', 5000);

                        }

                        //mostrar notificacion de exito
                        //limpiar fomulario maybe
                    })
                    .catch()
            }
            else {
                apiCreateSupervisor(form)
                    .then(response => {
                        //mandar a pagina de detalle
                        if (response.error) {
                            props.NotificationManager.error(response.message, 'Algo salió mal', 5000);
                        }
                        else {
                            props.NotificationManager.success('Usuario agregado con éxito', '', 5000);
                            props.history.push("/usuarios/detalles/supervisor/" + response.id);
                        }
                    })
                    .catch()
            }
        }
        else {
            //mostrar notificacion de que las contraseñas no coinciden o existe otro problema
        }

    }

    const Valitate = () => {
        console.log('validated');
        setSubmited(true);
    }

    const confirmScan = (card) => {
        console.log(card);
        setFormasValues({ target: { name: 'rfid', value: card } });
    }

    const handleShowModal = () => {
        setChowModalScan(!showModalScan);
    }

    return (<div>
        <div>
            <h5><i className="fas fa-arrow-left" onClick={() => props.history.goBack()} /> {id ? "Editar" : "Crear"} supervisor</h5>
        </div>
        <div className="card">
            <div className="card-body">
                <h5>Informacion de supervisor</h5>
                <hr />

                <form onSubmit={submit} className={"needs-validation" + (submited ? " was-validated" : "")} noValidate="novalidate">
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <label>Id sys21</label>
                            <InputValidator placeholder="Id sys21" name="sys21_id" value={form.sys21_id} onChange={setFormasValues} feedback={{ valid: "", invalid: "Este campo requerido" }} required />
                        </div>
                        <div className="col-md-4 col-12">
                            <label>Nombre del supervisor</label>
                            <InputValidator placeholder="Nombre del supervisor" name="name" value={form.name} onChange={setFormasValues} feedback={{ valid: "", invalid: "Este campo requerido" }} required />
                        </div>
                        <div className="col-md-4 col-12">
                            <label>Nombre de usuario</label>
                            <InputValidator placeholder="Nombre de usuario" name="username" value={form.username} onChange={setFormasValues} feedback={{ valid: "", invalid: "Este campo requerido y no acepta espacios" }} required />
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-md-4 col-12">
                            <label>contraseña</label>
                            <InputValidator type="password" placeholder="Contraseña" name="password" value={passwords.password} onChange={ChangePassword} feedback={{ valid: "", invalid: "Este campo es requerido" }} />
                        </div>
                        <div className="col-md-4 col-12">
                            <label>Repetir contraseña</label>
                            <InputValidator type="password" placeholder="Confirmar contraseña" name="confirmPassword" value={passwords.confirmPassword} onChange={ChangePassword} required feedback={{ valid: "", invalid: "Este campo es requerido" }} />
                        </div>
                        <div className="col-md-4 separate">
                            {
                                showMessage()
                            }
                        </div>
                    </div> */}
                    <div className="row separate">
                        <div className="col-md-4 col-12">
                            <label>Credencial RFID</label>
                            <InputValidator type="password"
                                placeholder="Credencial rfid" name="rfid"
                                value={form.rfid}
                                //onChange={ChangePassword}
                                onClick={handleShowModal}
                                feedback={{ valid: "", invalid: "Este campo es requerido" }} />
                        </div>
                        <div className="col-md-4 col-12">
                            <label>Pin</label>
                            <InputValidator type="password" placeholder="Pin, máximo 4 caracteres" name="pin" maxLength="4" value={form.pin} onChange={setFormasValues} required feedback={{ valid: "", invalid: "Este campo es requerido" }} />
                        </div>
                        <div className="col-md-4 separate">
                            {
                                showMessage()
                            }
                        </div>
                    </div>
                    <div className="tac separate">
                        <button className="btn btn-submit" type="submit" onClick={Valitate}>Guardar supervisor</button>
                    </div>
                </form>

                <ModalScanner
                    show={showModalScan}
                    confirm={confirmScan}
                    handleClose={handleShowModal}
                />

            </div>
        </div>
    </div>)
}

export default withRouter(AddSupervisor);