import React from 'react';


const SupervisoresList = (props) => {
    const { list } = props;

    return (
        <div className="card separate">
            <div className="card-body">
                <div>
                    <h5><i className="fas fa-users" /> Listado de supervisores</h5>
                </div>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Id de supervisor</th>
                                <th>Nombre de supervisor</th>
                                <th>Tarjetas pendientes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.length > 0 ?
                                    list.map(elm =>
                                        <tr key={elm.id}>
                                            <td>{elm.sys21_id}</td>
                                            <td>{elm.nombre}</td>
                                            <td>{elm.num_tarjetas}</td>
                                        </tr>
                                    )
                                    :
                                    <tr>
                                        <td colSpan={3}>No se encontraron datos</td>
                                    </tr>
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>)
}

export default SupervisoresList;