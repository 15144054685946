import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import AddApuntador from './Apuntadores/addApuntador';
import AddSecretario from './Secretarios/addSecretario';
import DetailsApuntador from './Apuntadores/detailsApuntador';
import DetailsSecretario from './Secretarios/detailsSecretario';
import AddGerente from './Gerentes/addGerente';
import DetailsGerente from './Gerentes/detailsGerente';
import AddSupervisor from './Supervisor/addSupervisor';
import DetailsSupervisor from './Supervisor/detailsSupervisor';
import AddAdmin from './Admin/addAdmin';
import DetailsAdmin from './Admin/detailsAdmin';
import AddRH from './RH/addRH';
import DetailsRH from './RH/detailsRH';

const detailsUser = (props) => {
    // const { pathname } = props.location;


    // const view = (pathname) => {
    //     switch (pathname) {
    //         case '/usuarios/agregar/apuntador':
    //             return <AddApuntador />
    //         case '/usuarios/agregar/secretario':
    //             return <AddDetailsSecretario />


    //         default:
    //             break;
    //     }
    // }

    const view = () => {
        return <Switch>
            <Route key="1" path="/usuarios/agregar/apuntador" exact={true} component={() => <AddApuntador {...props} />} />
            <Route key="2" path="/usuarios/detalles/apuntador/:id" exact={true} component={() => <DetailsApuntador {...props} />} />
            <Route key="3" path="/usuarios/editar/apuntador/:id" exact={true} component={() => <AddApuntador {...props} />} />
            <Route key="4" path="/usuarios/agregar/secretario" exact={true} component={() => <AddSecretario {...props} />} />
            <Route key="5" path="/usuarios/detalles/secretario/:id" exact={true} component={() => <DetailsSecretario {...props} />} />
            <Route key="6" path="/usuarios/editar/secretario/:id" exact={true} component={() => <AddSecretario {...props} />} />
            <Route key="7" path="/usuarios/agregar/supervisor" exact={true} component={() => <AddSupervisor {...props} />} />
            <Route key="8" path="/usuarios/detalles/supervisor/:id" exact={true} component={() => <DetailsSupervisor {...props} />} />
            <Route key="9" path="/usuarios/editar/supervisor/:id" exact={true} component={() => <AddSupervisor {...props} />} />
            <Route key="10" path="/usuarios/agregar/rh" exact={true} component={() => <AddRH {...props} />} />
            <Route key="11" path="/usuarios/detalles/rh/:id" exact={true} component={() => <DetailsRH {...props} />} />
            <Route key="12" path="/usuarios/editar/rh/:id" exact={true} component={() => <AddRH {...props} />} />
            <Route key="13" path="/usuarios/agregar/gerente" exact={true} component={() => <AddGerente {...props} />} />
            <Route key="14" path="/usuarios/detalles/gerente/:id" exact={true} component={() => <DetailsGerente {...props} />} />
            <Route key="15" path="/usuarios/editar/gerente/:id" exact={true} component={() => <AddGerente {...props} />} />
            <Route key="16" path="/usuarios/agregar/admin" exact={true} component={() => <AddAdmin {...props} />} />
            <Route key="17" path="/usuarios/detalles/admin/:id" exact={true} component={() => <DetailsAdmin {...props} />} />
            <Route key="18" path="/usuarios/editar/admin/:id" exact={true} component={() => <AddAdmin {...props} />} />
        </Switch>
    }


    return <div>
        <div className="page-title ">
            <h3><i className="fas fa-users" /> Usuarios</h3>
        </div>

        {view()}

    </div>
}

export default withRouter(detailsUser);