import React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { apiGetTrabajadores } from '../../../lib/apiSys21';
import { apiUpdateListWorkers } from '../../../lib/apiPaseLista';
import { hoursOptions } from '../../../lib/constants';
import styles from './cards.module.css';

const CardTrabajadores = props => {
    const { trabajadores, id, NotificationManager, cuadrillero, editable, is_destajo, es_indirecto } = props;
    const [trabajadoresList, setTrabajadoresList] = React.useState(trabajadores);
    const [isEditing, setIsEditing] = React.useState(false);
    const [typeaheadWorker, setTypeaheadWorker] = React.useState({ loading: false, options: [] });

    const typeaheadRef = React.useRef(null);

    React.useEffect(() => {
        setTrabajadoresList(trabajadores);
    }, [trabajadores]);

    const handleClickEliminar = worker => {
        if (worker.toEliminate) {
            setTrabajadoresList(
                trabajadoresList.map(elm => (elm.id_trabajador === worker.id_trabajador ? { ...elm, toEliminate: false } : elm)),
            );
        } else {
            setTrabajadoresList(
                trabajadoresList.map(elm => (elm.id_trabajador === worker.id_trabajador ? { ...elm, toEliminate: true } : elm)),
            );
        }
    };

    const searchWorkers = search => {
        setTypeaheadWorker({ ...typeaheadWorker, loading: true });
        apiGetTrabajadores({ search, supervisor: 'false' })
            .then(response => {
                if (response.error) {
                } else {
                    const typeah = {
                        ...typeaheadWorker,
                        loading: false,
                        options: response.rows.map(elm => ({ ...elm, id_name: elm.sys21_id + ' - ' + elm.nombre })),
                    };
                    //console.log("response", response, typeah);
                    setTypeaheadWorker(typeah);
                }
            })
            .catch()
            .finally(() => {
                //console.log(typeaheadWorker);
                //setTypeaheadWorker({ ...typeaheadWorker, loading: false });
            });
    };

    const handleSelectedWorkerOnTypeahead = obj => {
        const worker = obj[0];

        if (worker) {
            if (trabajadoresList.find(t => t.id_trabajador === worker.sys21_id)) {
                NotificationManager.warning('El trabajador seleccionado ya se encuentra en la lista', '', 5000);
            } else {
                if (cuadrillero.id === worker.sys21_id) {
                    NotificationManager.warning('El trabajador seleccionado esta asignado como cuadrillero', '', 5000);
                } else {
                    setTrabajadoresList([
                        {
                            id_trabajador: worker.sys21_id,
                            nombre_trabajador: worker.nombre,
                            hora_extra: false,
                            numero_horas: 0,
                        },
                        ...trabajadoresList,
                    ]);
                }
            }
        }
        //limpiar y auto focus con un ref
        typeaheadRef.current._instance.clear();
        typeaheadRef.current._instance.focus();
        //setTypeaheadWorker({ ...typeaheadWorker, loading: false, options: [] });
    };

    const changeIsEditing = () => {
        if (isEditing) {
            setTrabajadoresList(trabajadores);
        } else {
        }
        setIsEditing(!isEditing);
    };

    const saveWorkers = () => {
        apiUpdateListWorkers(id, {
            trabajadores: trabajadoresList
                .filter(elm => !elm.toEliminate)
                .map(elm => ({ id: elm.id_trabajador, destajo: 0, hora_extra: elm.hora_extra, numero_horas: elm.numero_horas })),
        })
            .then(response => {
                if (response.error) {
                    NotificationManager.error(response.message, '', 5000);
                } else {
                    NotificationManager.success('Se ha guardado correctamente', '', 5000);
                    setTrabajadoresList(response.trabajadores);
                    setIsEditing(false);
                }
            })
            .catch(() => {
                NotificationManager.error('Ocurrió un error. Inténtelo más tarde', '', 5000);
            });
    };

    const setExtraHours = id_worker => {
        setTrabajadoresList(
            trabajadoresList.map(elm => (elm.id_trabajador === id_worker ? { ...elm, hora_extra: !elm.hora_extra } : { ...elm })),
        );
    };

    const setHours = (e, id_activity) => {
        setTrabajadoresList(
            trabajadoresList.map(elm => (elm.id_trabajador === id_activity ? { ...elm, numero_horas: e.target.value } : { ...elm })),
        );
    };

    return (
        <div className="card separate">
            <div className="card-body">
                <div className="row no-row-margin">
                    <div className="col-md-6 col-12">
                        <h5>
                            <i className="fas fa-id-card" /> Trabajadores
                        </h5>
                    </div>

                    {/* <div className="col-md-6 col-12 tar">
                    {
                        editable &&
                        (!isEditing ?
                            <button className="btn btn-lgt btn-success" onClick={changeIsEditing}><i className="fas fa-edit" /> Editar trabajadores</button>
                            :
                            <React.Fragment>
                                <button className="btn btn-lgt btn-danger" onClick={changeIsEditing}><i className="fas fa-times" /> Cancelar</button>
                                <button className="btn btn-lgt btn-success" onClick={saveWorkers} ><i className="fas fa-save" /> Guardar</button>
                            </React.Fragment>)
                    }
                </div> */}
                </div>
                <div>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Nombre</th>
                                    {is_destajo && (
                                        <th>Actividades</th>
                                    )}
                                    {is_destajo ? (
                                        <React.Fragment>
                                            <th>Destajo</th>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <th>Horas laborales</th>
                                            <th>Horas extra</th>
                                        </React.Fragment>
                                    )}
                                    {es_indirecto && <th>Actividad</th>}
                                    {isEditing && <th>Eliminar</th>}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {isEditing && (
                                    <tr>
                                        <td colSpan={6}>
                                            <AsyncTypeahead
                                                ref={ref => (typeaheadRef.current = ref)}
                                                id="typeaheadWorker"
                                                labelKey="id_name"
                                                multiple={false}
                                                minLength={1}
                                                onChange={handleSelectedWorkerOnTypeahead}
                                                isLoading={typeaheadWorker.isLoading}
                                                filterBy={['nombre']}
                                                selectHintOnEnter={true}
                                                clearButton={false}
                                                searchText="Buscando..."
                                                options={typeaheadWorker.options}
                                                placeholder="Ingrese nombre de trabajador"
                                                onSearch={query => searchWorkers(query)}
                                                renderMenuItemChildren={(option, props) => {
                                                    return <div key={option.id}>{option.id_name}</div>;
                                                }}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {trabajadoresList.map(worker => (
                                    <tr className={worker.toEliminate ? 'to-eliminate' : ''} key={worker.id_trabajador}>
                                        <td>{worker.id_trabajador}</td>
                                        <td>{worker.nombre_trabajador}</td>
                                        {is_destajo && (
                                            <td>
                                                {(!worker.actividades || worker.actividades.length === 0) && (
                                                    <span>Sin actividades</span>
                                                )}
                                                {(worker.actividades && worker.actividades.length > 0) && (
                                                    <ul className={styles['trabajador-actividad-list']}>
                                                        {worker.actividades.map(a => (
                                                            <li key={a.sys21_id}>
                                                                <span>{a.nombre}</span>
                                                                <br/>
                                                                <span>Destajos: {a.destajo}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </td>
                                        )}
                                        {is_destajo ? (
                                            <td>{worker.destajo}</td>
                                        ) : (
                                            <React.Fragment>
                                                <td>
                                                    {
                                                        // isEditing ?
                                                        //     <div className="custom-control custom-checkbox">
                                                        //         <input type="checkbox" className="custom-control-input" id={"check-" + worker.id_trabajador} checked={worker.hora_extra} />
                                                        //         <label className="custom-control-label" for={'check-' + worker.id_trabajador} onClick={() => setExtraHours(worker.id_trabajador)}></label>
                                                        //     </div> :
                                                        //     (worker.hora_extra ? "Si" : 'No')
                                                        hoursOptions.find(o => o.value === parseFloat(worker.numero_horas))?.hour
                                                    }
                                                </td>
                                                <td>
                                                    {// isEditing && worker.hora_extra ?
                                                    //     <select className="form-control" value={parseFloat(worker.numero_horas)} onChange={(e) => setHours(e, worker.id_trabajador)}>
                                                    //         {
                                                    //             hoursOptions.map(hora => {
                                                    //                 console.log(hora)
                                                    //                 return <option key={hora.value} value={hora.value}>{hora.hour}</option>
                                                    //             }
                                                    //             )
                                                    //         }
                                                    //     </select>
                                                    //     :
                                                    //     (worker.numero_horas ? hoursOptions.find(o => o.value === parseFloat(worker.numero_horas)).hour : 0)
                                                    worker.horas_extra === '0.00'
                                                        ? '0:00'
                                                        : hoursOptions.find(o => o.value === parseFloat(worker.horas_extra)).hour}
                                                </td>
                                            </React.Fragment>
                                        )}
                                        {es_indirecto && <td>{worker.nombre_actividad}</td>}
                                        {isEditing && (
                                            <td>
                                                <button className="btn btn-danger btn-lgt" onClick={() => handleClickEliminar(worker)}>
                                                    {worker.toEliminate ? <i className="fas fa-ban" /> : <i className="fas fa-times" />}{' '}
                                                </button>
                                            </td>
                                        )}
                                        <td>
                                            {worker.manual === 1 && (
                                                <label className="color-red" title="Agregado manualmente">
                                                    <i className="fas fa-exclamation-triangle" />
                                                </label>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardTrabajadores;
