import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalScanner = (props) => {
    const { show, handleClose, confirm } = props;
    const [cardId, setCardId] = React.useState('');

    const Read = (key) => {
        if (key === "Enter") {
            //console.log(cardId);
            confirm(cardId);
            setCardId('');
            handleClose();

        } else {
            setCardId(cardId + key);
        }
    }

    return (
        <Modal show={show} onHide={handleClose} centered
            dialogClassName="modal-20w"
            onKeyPress={(e) => Read(e.key)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Escanear Credencial</Modal.Title>
            </Modal.Header>
            <Modal.Body  >
                <div className="tac" autoFocus tabIndex="0">
                    <label>Para terminar con el registro escanee la credencial RFID en el lector</label>
                    <button className="btn btn-success btn-lgt">Click para escanear</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

ModalScanner.defaultProps = {
    show: false,
    name: '',
    type: 'desactivar',
    typeUser: '',
    confirm: () => { },
    handleClose: () => { },
}


export default ModalScanner;