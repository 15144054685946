import React from 'react';


const Cultivo = (props) => {

    const { cultivo } = props;

    const sortAscArray = (array) => {
        return array.sort(function (a, b) { return a.sys21_id - b.sys21_id })
    }

    return <div className="col-6 pd-t">
        <div className="card">

            <div className="card-body">
                <div className="" style={{ display: "flex", justifyContent: "space-between" }}>
                    {cultivo.sys21_id && (
                        <label><b>{cultivo.sys21_id + ' - ' + cultivo.nombre_cultivo}</b></label>
                    )}
                    {!cultivo.sys21_id && (
                        <label><b>{cultivo.nombre_cultivo}</b></label>
                    )}
                    <span><b>Total:</b> {cultivo.total}</span>
                </div>
                <div className=" activities-container table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th className="tal">Actividad</th>
                                <th className="tar">Trabajadores</th>
                                <th className="tar">Horas</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sortAscArray(cultivo.actividades).map(activity =>
                                    <tr key={activity.sys21_id}
                                        className="activity-inlote">
                                        <td>
                                            {activity.nombre}
                                        </td>
                                        <td className="tar">
                                            {activity.trabajadores}
                                        </td>
                                        <td className="tar">
                                            {activity.total}
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>


}

export default Cultivo;