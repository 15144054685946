import React from 'react';
import { NotificationManager } from 'react-notifications';
import CardiInfo from './cardInfo';
import CardLotes from './cardLotes';
import CardTrabajadores from './cardTrabajadores';
import CardComments from './cardComentarios';
import CardActividades from './cardActivities';
import {
  apiGetSingleCard,
  apiApproveCard,
  apiAddIncidenciaSecretario,
  apiFinishIncidenciaSecretario,
  apiFinishIncidenciaRH,
  apiAddIncidenciaRH,
  apiValidateSupervisorCards,
  apiCancelCard,
} from '../../../lib/apiPaseLista';

import { useHistory, withRouter } from 'react-router-dom';
import loaderConnect from '../../../Components/Loader/loaderConnect';
import ModalConfirmSecretary from './modalConfirmSecretary';
import CardValidations from './cardValidations';
import ModalIncidecia from './modalIncidencia';
import ModalAprobar from './modalAprobar';
import useUser from '../../../hooks/useUser';
import CardCultivos from './cardCultivos';
import ConfirmModal from '../../../Components/Modals/confirm';

const reducer = (state, action) => {
  switch (action.type) {
    case 'setCard':
      return { ...state, card: action.payload };
    case 'setModalShow':
      return { ...state, showModal: action.payload };
    case 'setModalIncidenciaShow':
      return { ...state, modalIncideciashow: action.payload };
    case 'setShowModalAprobar':
      return { ...state, modalAprobar: action.payload };
    case 'setPermissions':
      return { ...state, permissions: action.payload };
    default:
      break;
  }
};

const initialState = {
  showModal: false,
  card: {
    id: 0,
    folio: '',
    id_estatus: 1,
    es_indirecto: false,
    estatus: '',
    createdAt: '2019-11-11T19:16:51.000Z',
    numero_dia: 0,
    semana: 0,
    num_trabajadores: 1,
    id_horario: 1,
    horario: '',
    hora_inicio: '',
    hora_fin: '',
    dia: '',
    supervisor: { nombre: '' },
    cuadrillero: { nombre: '' },
    apuntador: { id: 0, nombre: '' },
    lotes: [],
    trabajadores: [],
    validado: [],
    actividades: [],
    cultivos: [],
    is_destajo: 0,
  },
  modalIncideciashow: false,
  modalAprobar: false,
  permissions: {
    general_data: false,
    activities: false,
    lotes: false,
    workers: false,
    comments: true,
  },
};

const Detail = props => {
  const { id } = props.match.params;
  const { showLoader, card: preloadedCard } = props;
  const history = useHistory();

  const user = useUser();

  const [state, dispatch] = React.useReducer(reducer, initialState, () => {
    if (preloadedCard) {
      return {
        ...initialState,
        card: preloadedCard,
      };
    }
    return initialState;
  });
  const { card, showModal, modalIncideciashow, modalAprobar, permissions } = state;
  window.scrollTo(0, 0);

  React.useEffect(() => {
    //set permission
    console.log('user', user);

    switch (user.role_id) {
      case 1: //ADMIN
      case 2: //GERENTE
        dispatch({
          type: 'setPermissions',
          payload: {
            general_data: true,
            activities: true,
            lotes: true,
            workers: true,
            comments: true,
          },
        });
        break;
      case 3: //RH
        dispatch({
          type: 'setPermissions',
          payload: {
            general_data: false,
            activities: false,
            lotes: false,
            workers: true,
            comments: true,
          },
        });
        break;

      case 5: //SECRETARIO
        dispatch({
          type: 'setPermissions',
          payload: {
            general_data: true,
            activities: true,
            lotes: true,
            workers: false,
            comments: true,
          },
        });
        break;
      default:
        break;
    }
  }, [user]);

  React.useEffect(() => {
    if (!preloadedCard) {
      showLoader(true);
      apiGetSingleCard(id)
        .then(response => {
          if (response.error) {
          } else {
            dispatch({ type: 'setCard', payload: response });
            //console.log("paselistadetails", response);
          }
        })
        .catch()
        .finally(response => {
          showLoader(false);
        });
    }
  }, [id, showLoader, preloadedCard]);

  const ApproveCard = () => {
    showLoader(true);
    apiApproveCard(id)
      .then(response => {
        if (response.error) {
          NotificationManager.error(response.message, '', 5000);
        } else {
          NotificationManager.success('Se ha aprobado la tarjeta con éxito', '', 5000);
        }
      })
      .catch()
      .finally(response => {
        apiGetSingleCard(id)
          .then(response => {
            if (response.error) {
            } else {
              dispatch({ type: 'setCard', payload: response });
              console.log('paselistadetails', response);
            }
          })
          .catch()
          .finally(response => {
            showLoader(false);
          });
        showLoader(false);
      });
  };

  const handleModalApprove = () => {
    console.log('handle');
    dispatch({ type: 'setModalShow', payload: !showModal });
  };

  const confirmIncidencia = comentario => {
    switch (card.id_estatus) {
      case 1:
      case 2:
      case 3:
        apiAddIncidenciaSecretario(id, comentario).then(response => {
          if (response.error) {
          } else {
            dispatch({ type: 'setCard', payload: { ...card, id_estatus: 6, estatus: response.obj_tarjeta.estatus } });
            NotificationManager.success('Se ha creado la incidencia correctamente', '', 5000);
            handleShowModalIncidencia();
          }
        });
        break;
      case 4:
      case 5:
        apiAddIncidenciaRH(id, comentario).then(response => {
          if (response.error) {
          } else {
            dispatch({ type: 'setCard', payload: { ...card, id_estatus: 8, estatus: response.obj_tarjeta.estatus } });
            NotificationManager.success('Se ha creado la incidencia correctamente', '', 5000);
            handleShowModalIncidencia();
          }
        });
        break;

      case 6:
        apiFinishIncidenciaSecretario(id, comentario).then(response => {
          if (response.error) {
          } else {
            dispatch({
              type: 'setCard',
              payload: { ...card, id_estatus: response.obj_tarjeta.id_estatus, estatus: response.obj_tarjeta.estatus },
            });
            NotificationManager.success('Se ha finalizado la incidencia correctamente', '', 5000);
            handleShowModalIncidencia();
          }
        });
        break;
      case 8:
        apiFinishIncidenciaRH(id, comentario).then(response => {
          if (response.error) {
          } else {
            dispatch({
              type: 'setCard',
              payload: { ...card, id_estatus: response.obj_tarjeta.id_estatus, estatus: response.obj_tarjeta.estatus },
            });
            NotificationManager.success('Se ha finalizado la incidencia correctamente', '', 5000);
            handleShowModalIncidencia();
          }
        });
        break;

      default:
        break;
    }
  };

  const handleShowModalIncidencia = () => {
    dispatch({ type: 'setModalIncidenciaShow', payload: !modalIncideciashow });
  };

  const ValidateSupervisorCards = supervisor => {
    showLoader(true);
    apiValidateSupervisorCards({
      ...supervisor,
      tarjetas: [card.id],
    })
      .then(response => {
        if (response.error) {
          if (response.filed) {
            NotificationManager.warning(response.message + ' ' + response.failed.toString(), '', 5000);
          } else {
            NotificationManager.error(response.message, '', 5000);
          }
        } else {
          dispatch({ type: 'setCard', payload: { ...card, id_estatus: 4 } });
          NotificationManager.success('Se ha validado la tarjeta correctamente', '', 5000);
        }
      })
      .catch(() => {
        NotificationManager.error('Ocurrió un error. Inténtelo más tarde.', '', 5000);
      })
      .finally(() => {
        showLoader(false);
        //updateStatusCard
      });
  };

  const handleModalAprobarSupervisor = () => {
    dispatch({ type: 'setShowModalAprobar', payload: true });
  };

  const closeModalAprobar = () => {
    dispatch({ type: 'setShowModalAprobar', payload: false });
  };

  const handleCardCancel = () => {
    ConfirmModal({
      title: "Cancelar tarjeta",
      message: <p style={{ textAlign: "center" }}>
        <span>¿Esta seguro que desea cancelar esta tarjeta?</span>
        <br />
        <span>Una vez cancelada no podrá ser utilizada por el sistema.</span>
      </p>,
      buttons: [
        {
          label: 'Cancela',
          class: 'btn-danger',
          onClick: () => null,
        },
        {
          label: 'Confirmar',
          class: 'btn-success',
          onClick: () => doCancel(),
        },
      ]
    });

    async function doCancel() {
      showLoader(true);
      apiCancelCard(card.id)
        .then(response => {
          if (response.error) {
            NotificationManager.error(response.message, '', 5000);
          } else {
            // dispatch({ type: 'setCard', payload: { ...card, id_estatus: 99 } });
            NotificationManager.success('Se ha cancelado la tarjeta correctamente', '', 5000);
            history.goBack();
          }
        })
        .catch(() => {
          NotificationManager.error('Ocurrió un error. Inténtelo más tarde.', '', 5000);
        })
        .finally(() => {
          showLoader(false);
        })
    }
  }

  return (
    <div>
      {!preloadedCard && (
        <div className="row no-pd-row separate">
          <div className="col-md-6 col-12">
            <h3>
              <i className="fas fa-arrow-left" onClick={() => props.history.goBack()} /> Detalles de tarjeta
            </h3>
          </div>
          <div className="col-md-6 col-12 tar">
            {user.role_id === 1 && (
              <button className="btn btn-lgt btn-danger" onClick={handleCardCancel}>
                Eliminar tarjeta
              </button>
            )}
            {card.id_estatus <= 5 && (
              <>
                <button className="btn btn-lgt btn-warning" onClick={handleShowModalIncidencia}>
                  Crear Incidencia
                </button>
                {card.id_estatus === 2 && (
                  <button className="btn btn-success btn-lgt" onClick={handleModalApprove}>
                    <i className="fas fa-check" /> Aprobar tarjeta
                  </button>
                )}
                {card.id_estatus === 3 && (
                  <button className="btn btn-success btn-lgt" onClick={handleModalAprobarSupervisor}>
                    <i className="fas fa-check" /> Aprobar supervisor
                  </button>
                )}
              </>
            )}
            {(card.id_estatus === 6 || card.id_estatus === 8) && (
              <button className="btn btn-lgt btn-success" onClick={handleShowModalIncidencia}>
                Finalizar incidencia
              </button>
            )}
          </div>
          <div className="col-12 indicator-details tar">
            {card.trabajadores_manual && (
              <label className="badge badge-danger">
                <i className="fas fa-exclamation-triangle" /> Trabajadores manuales
              </label>
            )}
            {card.modificado && (
              <label className="badge badge-warning">
                <i className="fas fa-exclamation-triangle" /> La tarjeta se ha modificado
              </label>
            )}
          </div>
        </div>
      )}

      <CardiInfo
        id={id}
        NotificationManager={NotificationManager}
        card={card}
        editable={preloadedCard ? false : permissions.general_data}
      />
      {!preloadedCard && <CardValidations validations={card.validado} />}
      {!card.es_indirecto && (
        <CardActividades
          id={id}
          NotificationManager={NotificationManager}
          actividades={card.actividades}
          editable={false}
          // editable={permissions.activities}
          is_destajo={card.is_destajo}
        />
      )}

      <CardLotes
        id={id}
        NotificationManager={NotificationManager}
        lotes={card.lotes}
        editable={preloadedCard ? false : permissions.lotes}
      />

      <CardCultivos cultivos={card.cultivos} />

      <CardTrabajadores
        id={id}
        NotificationManager={NotificationManager}
        trabajadores={card.trabajadores}
        cuadrillero={card.cuadrillero}
        editable={permissions.workers}
        is_destajo={card.is_destajo}
        es_indirecto={card.es_indirecto}
      />

      {!preloadedCard && (
        <CardComments id={id} NotificationManager={NotificationManager} editable={permissions.comments} />
      )}

      <ModalConfirmSecretary show={showModal} confirm={ApproveCard} handleClose={handleModalApprove} />
      <ModalIncidecia
        show={modalIncideciashow}
        confirm={confirmIncidencia}
        handleClose={handleShowModalIncidencia}
        isAdd={card.id_estatus < 6}
      />

      <ModalAprobar show={modalAprobar} confirm={ValidateSupervisorCards} handleClose={closeModalAprobar} />
    </div>
  );
};

export default withRouter(loaderConnect(Detail));
