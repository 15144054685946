import React from 'react';
import { DataTable } from '@wafo/table';
import DateTime from 'react-datetime';
import moment from 'moment';
import styles from './bonusTable.module.css';
import { apiGetBonus, apiSaveBonus } from '../../../../lib/apiPaseLista';
import { CreateAuthRequest, baseUrl } from '../../../../lib/constants';
import FileSaver from 'file-saver';
import PaselistaTabs from '../../Lista/paselistaTabs';
import { useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import useLoading from '../../../../hooks/useLoading';

const BonusTable = () => {
  const [day, setDay] = React.useState(moment());
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const loading = useLoading();
  const history = useHistory();

  const week = React.useMemo(() => {
    const base = moment(day);
    return {
      from_date: base.startOf('isoWeek').format('YYYY-MM-DD'),
      to_date: base.endOf('isoWeek').format('YYYY-MM-DD'),
    };
  }, [day]);

  const doSearch = async () => {
    try {
      loading.set();
      const resp = await apiGetBonus({
        fecha_inicial: week.from_date,
        fecha_final: week.to_date,
      });
      if (!resp.error) {
        setRows(
          resp.rows.map(row => ({
            aprobado: {
              value: row.aprobado,
              id: row.id_trabajador,
            },
            id: row.id_trabajador,
            nombre: row.nombre,
          })),
        );
        setTotalRows(resp.count);
      } else {
        NotificationManager.error(resp.message);
        setRows([]);
        setTotalRows(0);
      }
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  };

  const saveChanges = async () => {
    try {
      if (rows.length !== 0) {
        loading.set();
        const resp = await apiSaveBonus({
          fecha_inicial: week.from_date,
          fecha_final: week.to_date,
          trabajadores: rows.map(row => ({
            id_trabajador: row.id,
            nombre: row.nombre,
            aprobado: row.aprobado.value,
          })),
        });
        if (resp.error) {
          NotificationManager.error(resp.message);
        } else {
          NotificationManager.success('Bonos asignados correctamente.', '', 5000);
        }
        loading.stop();
      }
    } catch (error) {
      loading.stop();
      NotificationManager.error('Ocurrió un erro. Inténtelo más tarde.', '', 5000);
    }
  };

  const generateExcel = async () => {
    try {
      loading.set();
      const request = await CreateAuthRequest('POST', {
        fecha_inicial: week.from_date,
        fecha_final: week.to_date,
        trabajadores: rows.map(row => ({
          id_trabajador: row.id,
          nombre: row.nombre,
          aprobado: row.aprobado.value,
        })),
      });
      const url = baseUrl + 'lista/bono/pdf';
      const resp = await fetch(url, request);
      console.log(resp);
      if (resp.error) {
        NotificationManager.error(resp.message);
      } else {
        const file = await resp.blob();
        FileSaver.saveAs(file, 'Reporte_bono');
      }
      loading.stop();
    } catch (error) {
      loading.stop();
      NotificationManager.error('Ocurrió un erro. Inténtelo más tarde.', '', 5000);
    }
  };

  const toggleCheck = (id, val) => {
    setRows(prev => {
      const helper = [...prev];
      const index = helper.findIndex(x => x.id === id);
      helper[index].aprobado = {
        ...helper[index].aprobado,
        value: val === 1 ? 0 : 1,
      };
      return helper;
    });
  };

  const columnsConfig = React.useMemo(
    () => ({
      aprobado: function col(val) {
        return (
          <button type="button" className="btn btn-link" onClick={() => toggleCheck(val.id, val.value)}>
            {val.value ? <i className="fas fa-check-square" /> : <i className="far fa-square" />}
          </button>
        );
      },
    }),
    [],
  );

  return (
    <div className="container-page-wrapper">
      <div className="page-title ">
        <h3>
          <i className="fas fa-clipboard-list" /> Pase de lista
        </h3>
      </div>
      <PaselistaTabs
        active={0}
        counters={{}}
        mode="rh"
        onTabClick={tab =>
          history.push({
            pathname: '/paselista/rh',
            state: {
              tabs: {
                active: tab,
                counters: {
                  total: 0,
                },
              },
            },
          })
        }
        onSpecialClick={f => f}
      />
      <div className="card separate">
        <div className="car-body">
          <div className="row no-row-margin justify-content-between">
            <div className="col-md-4">
              <DateTime
                closeOnSelect={true}
                dateFormat="DD/MMM/YYYY"
                placeholder="seleccione fecha"
                timeFormat={false}
                onChange={value => setDay(value)}
                value={day}
                viewMode="days"
                locale="es"
                isValidDate={false}
                inputProps={{
                  required: true,
                  // className: 'form-control limit',
                  className: styles['no-display'],
                  name: 'week',
                  id: 'week',
                }}
                className={styles['weekSelector']}
              />
              <label htmlFor="week" className={`form-control limit ${styles['label']}`}>
                <i className="fas fa-calendar" style={{ marginRight: '.5rem' }} />
                <span>
                  Semana actual {moment(week.from_date).format('DD/MM/YY')} - {moment(week.to_date).format('DD/MM/YY')}
                </span>
              </label>
            </div>
            <div className="col-md-2">
              <button className="btn btn-success w100" onClick={doSearch}>
                Buscar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['row-buttons']}>
        <button type="button" className="btn btn-primary" onClick={generateExcel} disabled={rows.length === 0}>
          <i className="fas fa-file-excel" /> Exportar Excel
        </button>
        <button type="button" className="btn btn-success" onClick={saveChanges} disabled={rows.length === 0}>
          <i className="fas fa-check" /> Aprobar Bono de 7 días
        </button>
      </div>

      <div className="card separate">
        <div className="card-body">
          <DataTable
            locale="es"
            columns={['', 'No. de Trabajador', 'Trabajador']}
            rows={rows}
            totalRows={totalRows}
            tableClass="table table-striped table-sm separate"
            onPagination={f => f}
            noRowsMessage="No se han encontrado resultados."
            initialPage={1}
            columnsConfig={columnsConfig}
            tableWrapperClass={styles['just-table']}
          />
        </div>
      </div>
    </div>
  );
};

export default BonusTable;
