import { baseUrl, GET, PUT, CreateAuthRequest } from './constants';

export const apiGetTarjetaFolio = async folio => {
  const url = baseUrl + 'lista/folio/' + folio;
  const request = await CreateAuthRequest(GET, null, true);

  return fetch(url, request).then(response => response.json());
};

export const apiUpdateIncidentApuntador = async (id, obj) => {
  const url = baseUrl + 'lista/' + id + '/incidencia/apuntador';
  const request = await CreateAuthRequest(PUT, obj, true);

  return fetch(url, request).then(response => response.json());
};
