import React from 'react';


const LoteResult = (props) => {
    const { lote } = props;
    const sortAscArray = (array) => {
        return array.sort(function (a, b) { return a.sys21_id - b.sys21_id })
    }
    return <div className="col-md-6 col-12 pd-t">
        <div className="card">
            <div className="card-header bg-green">
                <p>{lote.sys21_id + ' - ' + lote.nombre}</p>
                <em>{lote.nombre_cultivo}</em>
            </div>
            <div className="card-body">
                <div className=" activities-container table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="tac">Actividad</th>
                                <th className="tar">Total</th>
                                <th className="tar">Jornales</th>
                                <th className="tar">J. Nómina</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sortAscArray(lote.actividades).map(activity =>
                                    <tr key={activity.sys21_id}
                                        className={"activity-inlote " +
                                            (activity.actividad_tarjetas ? " actividad_tarjetas " : '') +
                                            (activity.actividad_capturada ? " actividad_capturada " : '')
                                        }>
                                        <td>
                                            {activity.sys21_id + ' - ' + activity.nombre}
                                        </td>
                                        <td className="tar">
                                            {activity.total}
                                        </td>
                                        <td className="tar">
                                            {activity.jornales_tarjetas === 1 && <i className="fas fa-exclamation-triangle"/>}
                                            {activity.jornales}
                                        </td>
                                        <td className="tar">
                                            {activity.jornales_nomina}
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>


}

export default LoteResult;