import { baseUrl, GET, POST, PUT, DELETE, CreateAuthRequest, makeUrlGET } from './constants';

const urlModule = baseUrl + "lista/";
//region secretario
export const apiGetCards = async (filters) => {

    let url = urlModule + "secretario/list?" + makeUrlGET(filters);
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetCounters = async () => {

    let url = urlModule + "count";
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetSingleCard = async (id) => {

    let url = urlModule + id;
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiApproveCard = async (id) => {

    let url = urlModule + id + '/finish';
    let request = await CreateAuthRequest(PUT, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetComments = async (id) => {

    let url = urlModule + "comentario/" + id;
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiSubmitComment = async (id, obj) => {

    let url = urlModule + 'comentario/' + id;
    let request = await CreateAuthRequest(POST, obj, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiAddIncidenciaSecretario = async (id, comentario) => {

    let url = urlModule + id + '/incidencia/add';
    let request = await CreateAuthRequest(PUT, { comentario }, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiFinishIncidenciaSecretario = async (id, comentario) => {

    let url = urlModule + id + '/incidencia/remove';
    let request = await CreateAuthRequest(PUT, { comentario }, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiAddIncidenciaRH = async (id, comentario) => {

    let url = urlModule + 'rh/' + id + '/add';
    let request = await CreateAuthRequest(PUT, { comentario }, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiFinishIncidenciaRH = async (id, comentario) => {

    let url = urlModule + 'rh/' + id + '/remove';
    let request = await CreateAuthRequest(PUT, { comentario }, true);

    return fetch(url, request)
        .then(response => response.json())
}

//endregion

//region supervisor
export const apiGetSupervisorCards = async (supervisor) => {

    let url = urlModule + "supervisor";
    let request = await CreateAuthRequest(POST, supervisor, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiValidateSupervisorCards = async (obj) => {

    let url = urlModule + "validate";
    let request = await CreateAuthRequest(PUT, obj, true);

    return fetch(url, request)
        .then(response => response.json())
}

//endregion

//region RH
export const apiGetRHCards = async (obj) => {

    let url = urlModule + "rh/list?" + makeUrlGET(obj);
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiValidateRHCards = async (obj) => {

    let url = urlModule + "rh";
    let request = await CreateAuthRequest(PUT, obj, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetBonus = async (obj) => {
    let url = urlModule + "bono?" + makeUrlGET(obj);
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json());
};

export const apiSaveBonus = async (obj) => {
    let url = urlModule + "bono";
    let request = await CreateAuthRequest(POST, obj, true);

    return fetch(url, request)
        .then(response => response.json());
};

//endregion

//region statistics
export const aapiGetStatistics = async (obj) => {

    let url = urlModule + "estadistica?" + makeUrlGET(obj);
    let request = await CreateAuthRequest(POST, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetCardStatistics = async (fecha) => {

    let url = urlModule + "tarjetas?fecha=" + fecha;
    let request = await CreateAuthRequest(POST, null, true);

    return fetch(url, request)
        .then(response => response.json())
}
//endregion


//region EDIT CARD DETAILS
export const apiUpdateListWorkers = async (id, obj) => {

    let url = urlModule + 'trab/' + id;
    let request = await CreateAuthRequest(PUT, obj, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiUpdateLotesList = async (id, obj) => {

    let url = urlModule + 'lotes/' + id;
    let request = await CreateAuthRequest(PUT, obj, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiUpdateActivitiesList = async (id, obj) => {

    let url = urlModule + 'act/' + id;
    let request = await CreateAuthRequest(PUT, obj, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiUpdateDetailsCard = async (id, obj) => {

    let url = urlModule + id;
    let request = await CreateAuthRequest(PUT, obj, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiCancelCard = async (id) => {

    let url = urlModule + id;
    let request = await CreateAuthRequest(POST, {}, true);

    return fetch(url, request)
        .then(response => response.json())
}
//endregion


//region NEW CARD
export const apiValidateCardData = async (obj) => {

    let url = urlModule + 'tarjeta/info';
    let request = await CreateAuthRequest(POST, obj, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiValidateCardWorkers = async (obj) => {

    let url = urlModule + 'tarjeta/trabajadores';
    let request = await CreateAuthRequest(POST, obj, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiCreateCard = async (obj) => {

    let url = urlModule + 'tarjeta';
    let request = await CreateAuthRequest(POST, obj, true);

    return fetch(url, request)
        .then(response => response.json())
}

//endregion

//region NEW CARD

export const apiGetGerente = async (obj) => {
    let url = baseUrl + "gerente?" + makeUrlGET(obj);
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json());
};

export const apiGetGerentePDF = async (obj) => {
    let url = baseUrl + "gerente/gen?" + makeUrlGET(obj);
    let request = await CreateAuthRequest(POST, null, true);

    return fetch(url, request);
};

//endregion