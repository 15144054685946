import React from 'react';
import moment from 'moment';

const ApuntadoresList = (props) => {
    const { list } = props;
    return (
        <div className="card separate">
            <div className="card-body">
                <div>
                    <h5><i className="fas fa-users" /> Listado de apuntadores</h5>
                </div>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Id de apuntador</th>
                                <th>Nombre de apuntador</th>
                                <th>Tarjetas pendientes</th>
                                <th>Última sincronización</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.length > 0 ?
                                list.map(elm =>
                                    <tr key={elm.id}>
                                        <td>{elm.id}</td>
                                        <td>{elm.nombre}</td>
                                        <td>{elm.num_tarjetas}</td>
                                        <td>{moment(elm.ultima_sinc).format("DD/MMM/YYYY")}</td>
                                    </tr>
                                )
                                :
                                <tr>
                                    <td colSpan={4}>No se encontraron datos</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>)
}

export default ApuntadoresList;