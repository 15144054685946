import React from 'react';
import { apiGetSingleGerente } from '../../../lib/apiUsers';
import { withRouter, Link } from 'react-router-dom';
import ModalDesactivar from '../modalDesactivar';
import { apiChangeStatus } from '../../../lib/apiUsers';

const DetailsApuntador = (props) => {

    const { id } = props.match.params;
    const { NotificationManager } = props;

    const [gerente, setGerente] = React.useState({ name: '', username: '', last_sync: '', device_id: '' });
    const [modalDesactivar, setModalDesactivar] = React.useState({
        show: false,
        name: gerente.name,
        activate: true,
        type: 'desactivar',
        typeUser: 'gerente',
        id: gerente.id
    });

    React.useEffect(() => {
        apiGetSingleGerente(id)
            .then(response => {
                if (response.error) {

                }
                else {
                    setGerente(response);
                    setModalDesactivar({
                        show: false,
                        name: response.name,
                        typeUser: 'gerente',
                        id: response.id
                    });
                }
            })
    }, [id])

    const handleClose = () => {
        setModalDesactivar({ ...modalDesactivar, show: false });
    }

    const ConfirmModalActivate = () => {
        apiChangeStatus(modalDesactivar.id)
            .then(response => {
                if (response.error) {
                    console.log("paso un error al desactivar");
                    NotificationManager.error("algo salio mal", '', 4000);
                } else {
                    setGerente({ ...gerente, active: !gerente.active });
                    
                    NotificationManager.success("Se ha cambiado el status del usuario exitósamente", '', 4000);
                }
            })
    }

    const ShowModal = () => {
        setModalDesactivar({
            ...modalDesactivar,
            type: !gerente.active ? 'activar' : 'desactivar',
            show: true,
        });
    }

    return (<div className="">
        <div className="row ">
            <div className="col-md-6 col-12">
                <h5><i className="fas fa-arrow-left" onClick={() => props.history.goBack()} /> Detalles de gerente</h5>
            </div>

            <div className="col-md-6 col-12 tar">
                {
                    gerente.active ?
                        <button className="btn btn-lgt btn-danger" onClick={ShowModal}><i className="fas fa-ban" /> Desactivar</button>
                        :
                        <button className="btn btn-lgt btn-info" onClick={ShowModal}><i className="fas fa-ban" /> Activar</button>
                }
                <Link to={"/usuarios/editar/gerente/" + id} className="btn btn-lgt btn-success"><i className="fas fa-edit" /> Editar</Link>
            </div>

        </div>
        <div className="card separate">
            <div className="card-body">
                <h4>Información gerente</h4>
                <hr />
                <div className="row">
                    <div className="col-md-4 col-12">
                        <p className="lbl-title">Nombre gerente</p>
                        <label>{gerente.name}</label>
                    </div>
                    <div className="col-md-4 col-12">
                        <p className="lbl-title">Nombre usuario</p>
                        <label>{gerente.username}</label>
                    </div>
                    <div className="col-md-4 col-12">
                        <p className="lbl-title">Última sincronización</p>
                        <label>{gerente.last_sync}</label>
                    </div>
                </div>
            </div>
        </div>
        <ModalDesactivar
            {...modalDesactivar}
            confirm={ConfirmModalActivate}
            handleClose={handleClose}
        />
    </div>)
}

export default withRouter(DetailsApuntador);