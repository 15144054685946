import React from 'react';
import { apiGetComments, apiSubmitComment } from '../../../lib/apiPaseLista';
import moment from 'moment';

const CardComments = (props) => {
    const { id, NotificationManager, editable } = props;
    const [comments, setComments] = React.useState({ count: 0, rows: [] });
    const [newComment, setNewComment] = React.useState('');

    React.useEffect(() => {
        apiGetComments(id)
            .then(response => {
                if (response.error) {

                } else {
                    setComments(response);
                }
            })
    }, []);

    const handleChangeNewComment = (e) => {
        setNewComment(e.target.value);
    }

    const SubmitComment = (e) => {
        e.preventDefault();
        apiSubmitComment(id, { comentario: newComment })
            .then(response => {
                if (response.error) {
                    NotificationManager.error(response.message, '', 5000);
                } else {
                    NotificationManager.success("Se ha agregado el comentario", '', 5000);
                    setComments({ count: comments.count + 1, rows: [response, ...comments.rows] });
                }
            })
            .catch()
            .finally(() => {
                setNewComment('');
            })
    }

    return <div className="card separate">
        <div className="card-body">
            <div>
                <h5><i className="fas fa-comments" /> Comentarios</h5>
                <hr />
            </div>
            <div >
                {
                    editable &&
                    <form onSubmit={SubmitComment}>
                        <label >Nuevo comentario</label>
                        <div className="input-group">
                            <input className="form-control" value={newComment} onChange={handleChangeNewComment} />
                            <div className="input-group-append">
                                <button className="btn btn-success btn-lgt"><i className="fas fa-plus" /> Agregar</button>
                            </div>
                        </div>
                    </form>
                }

                <div className="table-responsive separate">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Usuario</th>
                                <th>descripción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                comments.count > 0 ?
                                    comments.rows.map(comment =>
                                        <tr key={comment.id} className={!comment.sistema ? 'bg-card-error' : ''}>
                                            <td>{moment(comment.createdAt).format("DD/MMM/YYYY hh:mm:ss a")}</td>
                                            <td> {comment.name}</td>
                                            <td>{comment.comentario}</td>
                                        </tr>
                                    )
                                    :
                                    <tr>
                                        <td colSpan={3}>No hay comentarios</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
}

export default CardComments;