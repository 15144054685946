import React, { useState } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
import { apiSignIn } from '../../lib/apiAuth.js';
import logo from '../../imgs/Logo-agrofesa.gif';
// import Loader from '../../General Components/Loader/';
import jwtDecode from 'jwt-decode';
import './login.scss';

const Login = (props) => {

    const [formValues, changeValue] = useState({ user: '', password: '' });
    const setShowLoader = () => { }

    function ChangeValues(e) {
        const { name, value } = e.target;
        changeValue({ ...formValues, [name]: value });
    };

    const Submit = (e) => {
        e.preventDefault();
        console.log("login", "asdasd");
        if (e.target.checkValidity()) {
            // setShowLoader(true);
            console.log("loginxdxdxdx");
            apiSignIn({ password: formValues.password, user: formValues.user })
                .then(response => {
                    // setShowLoader(false);
                    console.log("login", response);
                    if (response.message !== "Conectado con exito.") {
                        NotificationManager.error('usuario y/o contraseña incorrectos.', '', 5000);
                    }
                    else {
                        localStorage.setItem('token', response.token);
                        NotificationManager.success('', '¡Bienvenido!', 700);

                        setTimeout(() => {
                            props.history.push('/inicio');
                        }, 700);
                    }
                })
                .catch(error => {
                    setShowLoader(false);
                    NotificationManager.error('Inténtelo más tarde.', 'Ocurrió un error', 4000)
                })
        }
        else {
            NotificationManager.error('usuario y/o contraseña incorrectos.', 'Error de validación', 4000);
        }
    };

    return (
        <div className="login">
            <div className="container">
                <div className="card" >
                    <div className="card-body af-cb">

                        {/* <h3 className="my-3"> Login:</h3> */}

                        <img src={logo} alt="agrofesa" />

                        <div className="tac">
                            <label className="welcome">Bienvenido al panel de control</label>
                        </div>


                        <form onSubmit={Submit}>
                            <div className="form-group">
                                <label className="label-title">Nombre de usuario</label>
                                <input
                                    name="user"
                                    id='user'
                                    value={formValues.user}
                                    type="text"
                                    onChange={ChangeValues}
                                    className="form-control input-af"
                                    required
                                    placeholder="Escribe tu nombre de usuario"
                                />
                            </div>

                            <div className="form-group">
                                <label className="label-title">Contraseña</label>
                                <input
                                    className="form-control input-af"
                                    name="password"
                                    id='password'
                                    value={formValues.password}
                                    type="password"
                                    onChange={ChangeValues}
                                    required
                                    placeholder="Escribe tu contraseña"
                                />
                            </div>

                            <div className="submit">
                                <button type="submit" className="btn btn-green">Iniciar sesión</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
};

export default Login;