import React from 'react';
import { WafoForm, WafoFormInput, WafoFormTextArea } from '@wafo/forms';
import styles from './incidents.module.css';
import useLoading from '../../hooks/useLoading';
import { apiGetTarjetaFolio, apiUpdateIncidentApuntador } from '../../lib/apiIncidents';
import CardDetails from '../PaseLista/CardDetails/cardDetails';

const IncidentsApuntador = props => {
  const { NotificationManager } = props;

  const [folioKey, setFolioKey] = React.useState(Math.random());
  const [card, setCard] = React.useState({});

  const loading = useLoading();

  const searchCard = async (form, values) => {
    if (form.valid) {
      try {
        loading.set();
        const response = await apiGetTarjetaFolio(values.folio);
        if (response.error) {
          NotificationManager.error(response.message, '', 5000);
        } else {
          setCard(response);
        }
        loading.stop();
      } catch (error) {
        loading.stop();
        NotificationManager.error('Ocurrió un error. Inténtelo más tarde.', '', 5000);
        console.error(error);
      }
    }
  };

  const updateCard = async (form, values) => {
    if (form.valid) {
      try {
        loading.set();
        const response = await apiUpdateIncidentApuntador(card.id, {
          comentario: values.comentario,
        });
        if (response.error) {
          NotificationManager.error(response.message, '', 5000);
        } else {
          NotificationManager.success('Tarjeta actualizada, incidencia creada.', '', 5000);
          // clear all.
          setCard({});
          setFolioKey(Math.random());
        }
        loading.stop();
      } catch (error) {
        loading.stop();
        NotificationManager.error('Ocurrió un error. Inténtelo más tarde.', '', 5000);
        console.error(error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-title">
        <h3>
          <i className="fas fa-exclamation-triangle" /> Incidentes apuntador
        </h3>
      </div>
      <div className="card">
        <div className="card-body">
          <WafoForm key={folioKey} formId="form-folio" locale="es" onSubmit={searchCard}>
            <div className="col-12">
              <div className="row justify-content-between">
                <WafoFormInput
                  type="text"
                  name="folio"
                  label={<b>Número de folio</b>}
                  placeholder="Introduzca folio"
                  customClass={`col-12 col-md-4 ${styles['wafoform-nomargin']}`}
                  validations={{ required: true }}
                  extraProps={{
                    className: 'form-control limit',
                    disabled: card.id,
                  }}
                />

                <div className={`col-12 col-md-4 ${styles['button-wrapper']}`}>
                  <button type="submit" className="btn btn-success form-control" disabled={card.id}>
                    Buscar tarjeta
                  </button>
                </div>
              </div>
            </div>
          </WafoForm>

          {card.id && (
            <div className={styles['well']}>
              <WafoForm formId="form-incident" locale="es" onSubmit={updateCard}>
                <div className="col-12">
                  <div className="row justify-content-between">
                    <WafoFormTextArea
                      name="comentario"
                      label={<b>Comentario de incidencia</b>}
                      placeholder="Escribe un comentario o la razón de la incidencia..."
                      customClass="col-12"
                      validations={{
                        minLength: 5,
                        maxLength: 255,
                        required: true,
                      }}
                    />

                    <div className="col-12">
                      <div className="row">
                        <div className="col-2">
                          <button type="button" className="btn btn-danger form-control" onClick={() => setCard({})}>
                            Cancelar
                          </button>
                        </div>
                        <div className="col-2">
                          <button type="submit" className="btn btn-success form-control">
                            Registrar incidencia
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </WafoForm>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className={styles['results-container']}>
            <h4>Información de tarjeta:</h4>
            {!card.id && (
              <div className={styles['empty']}>
                <p>Sin información para mostrar, realiza una busqueda por folio.</p>
              </div>
            )}
            {card.id && <CardDetails {...props} card={card} />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default IncidentsApuntador;
