import React from 'react';

const BooleanToggle = ({ name, customClass, handleInputChange, value, valid, touched, errors }) => {
  const toggleValue = () => {
    handleInputChange({
      target: { name, value: !value },
      type: 'change',
    });
  };

  return (
    <div className={`form-group ${customClass}`}>
      <button type="button" className="btn btn-light" onClick={toggleValue}>
        <i className={value ? 'fas fa-check-square' : 'far fa-square'} /> Indirectas
      </button>
      {!valid && touched && (
        <ul className="errors">
          {errors.map(error => (
            <li key={error.error}>{error.message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default BooleanToggle;
