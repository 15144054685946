import React from 'react';
import styles from './cuadrilleros.module.css';
import CustomTypeahead from '../NewCard/compTypeahead';
import { apiGetTrabajadores } from '../../lib/apiSys21';
import { DataTable } from '@wafo/table';
import useLoading from '../../hooks/useLoading';
import { NotificationManager } from 'react-notifications';
import { apiGetEmpleados, apiAsignCuadrillero, apiGetCuadrilleroTrabajadores } from '../../lib/apiCuadrilleros';
import { useHistory, useLocation } from 'react-router-dom';

const CuadrilleroAsignar = () => {
  const loading = useLoading();
  const history = useHistory();

  const { state } = useLocation();
  const cuadrillero = React.useMemo(() => {
    if (!state) return null;
    const { cuadrillero: initialCuadrillero } = state;
    return {
      ...initialCuadrillero,
      id_name: initialCuadrillero.id_cuadrillero + ' - ' + initialCuadrillero.nombre,
      sys21_id: initialCuadrillero.id_cuadrillero,
    };
  }, [state]);

  /** ********************************************** */
  /** Form */
  /** ********************************************** */
  const [form, setForm] = React.useState({
    id_cuadrillero: 0,
    trabajadores: [],
  });

  const changeSelected = React.useCallback((name, obj) => {
    if (obj.sys21_id !== 0) {
      setForm(prev => ({ ...prev, id_cuadrillero: obj.sys21_id }));
    }
  }, []);

  const selectTrabajador = React.useCallback(
    obj => {
      setForm(prev => {
        let index = prev.trabajadores.findIndex(x => x.sys21_id === obj.sys21_id);
        if (obj.sys21_id === form.id_cuadrillero) index = 1;
        return {
          ...prev,
          trabajadores: index !== -1 ? prev.trabajadores : [...prev.trabajadores, obj],
        };
      });
    },
    [form.id_cuadrillero],
  );

  const removeTrabajador = id => {
    setForm(prev => {
      const dummy = [...prev.trabajadores];
      const index = dummy.findIndex(x => x.sys21_id === id);
      dummy.splice(index, 1);

      return {
        ...prev,
        trabajadores: dummy,
      };
    });
  };

  const doSave = async () => {
    loading.set();
    try {
      const resp = await apiAsignCuadrillero(form.id_cuadrillero, {
        trabajadores: form.trabajadores.map(x => ({ id_trabajador: x.sys21_id })),
      });
      if (!resp.error) {
        NotificationManager.success('Asignación creada con exito', '', 5000);
        history.goBack();
      } else {
        NotificationManager.success('Problema al crear la asignación', '', 5000);
      }
    } catch (error) {
      NotificationManager.success('Problema al crear la asignación', '', 5000);
      console.error(error);
    }
    loading.stop();
  };

  React.useEffect(() => {
    async function getTrabajadores() {
      loading.set();
      try {
        const resp = await apiGetCuadrilleroTrabajadores(cuadrillero.sys21_id);
        setForm(prev => ({ ...prev, trabajadores: resp }));
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }
    if (cuadrillero) {
      getTrabajadores();
    }
  }, [cuadrillero]);

  /** ********************************************** */
  /** Table */
  /** ********************************************** */
  const tableRef = React.useRef();

  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const getData = React.useCallback(
    async ({ size, page, search }) => {
      loading.set();
      try {
        const resp = await apiGetEmpleados({
          page,
          limit: size,
          search,
          activo: true,
        });
        if (!resp.error) {
          setRows(
            resp.rows.map(row => ({
              id: row.sys21_id,
              name: row.nombre,
              options: row,
            })),
          );
          setTotalRows(resp.count);
        } else {
          NotificationManager.error(resp.message);
          setRows([]);
          setTotalRows(0);
        }
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    },
    [loading],
  );

  const columnsConfig = React.useMemo(
    () => ({
      id: val => <span>#{val}</span>,
      options: val => (
        <React.Fragment>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            title="Seleccionar"
            style={{ marginRight: '.5rem' }}
            disabled={!form.id_cuadrillero}
            onClick={() => selectTrabajador(val)}
          >
            <i className="fas fa-check" />
          </button>
        </React.Fragment>
      ),
    }),
    [form.id_cuadrillero, selectTrabajador],
  );

  return (
    <div className="card">
      <div className="card-body">
        <h5>Asignar cuadrillero y trabajadores</h5>
        <div className={styles['form']}>
          <div className="row">
            <div className="col-md-3">
              <label>
                <b>Seleccione cuadrillero</b>
              </label>
              <CustomTypeahead
                getElements={apiGetTrabajadores}
                name="Cuadrillero"
                filterBy={['nombre']}
                form={form}
                setSelectedElm={changeSelected}
                clear={true}
                paramsToSearch={{ supervisor: 'false' }}
                initialValue={cuadrillero}
              />
            </div>
          </div>
          <div style={{ padding: '.5rem' }} />
          <div className="row">
            <div className="col-12 col-md-6">
              <DataTable
                ref={tableRef}
                locale="es"
                columns={['ID', 'Nombre', 'Opciones']}
                rows={rows}
                totalRows={totalRows}
                tableClass="table table-striped table-sm separate"
                onPagination={getData}
                noRowsMessage="No se han encontrado resultados."
                initialPage={1}
                columnsConfig={columnsConfig}
                controls={
                  <React.Fragment>
                    <label>
                      <b>Seleccione trabajadores</b>
                    </label>
                  </React.Fragment>
                }
              />
            </div>
            <div className="col-12 col-md-6">
              <label>
                <b>Trabajadores seleccionados</b>
              </label>
              {form.trabajadores.length === 0 && (
                <div className={styles['empty']}>
                  <p>Seleccione cuadrillero y trabajadores.</p>
                </div>
              )}
              {form.trabajadores.length > 0 && (
                <ul className={styles['list']}>
                  {form.trabajadores.map(t => (
                    <li key={t.sys21_id} className={styles['li']}>
                      <span>
                        #{t.sys21_id} - {t.nombre}
                      </span>
                      <button
                        type="button"
                        className="btn btn-sm btn-danger"
                        title="Remover"
                        style={{ marginRight: '.5rem' }}
                        onClick={() => removeTrabajador(t.sys21_id)}
                      >
                        <i className="fas fa-trash-alt" />
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12" style={{ textAlign: 'center', margin: '1rem' }}>
              <button
                type="button"
                className="btn btn-danger"
                style={{ marginRight: '1rem' }}
                onClick={() => history.goBack()}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn btn-submit"
                disabled={!form.id_cuadrillero || !form.trabajadores.length}
                onClick={doSave}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CuadrilleroAsignar;
