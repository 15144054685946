import React from 'react';
import { NotificationManager } from 'react-notifications';
import DateTime from 'react-datetime';
import moment from 'moment';
import { apiGetCruce } from '../../../lib/apiCruceInfo';
import LoteResult from './loteResult';
import '../../../styles/datetime.scss';

const CrossResults = () => {
  const [selectedDate, setSelectedDate] = React.useState(moment());
  const [results, setResults] = React.useState({
    fecha: moment(selectedDate).format('YYYY-MM-DD'),
    lotes: [],
  });

  React.useEffect(() => {
    apiGetCruce(moment(selectedDate).format('YYYY-MM-DD'))
      .then(response => {
        if (response.error) {
          NotificationManager.error(response.message, '', 5000);
        } else {
          setResults({ ...response, lotes: sortAscArray(response.lotes) });
        }
      })
      .catch();
  }, [selectedDate]);

  const sortAscArray = array => {
    return array.sort(function(a, b) {
      return a.sys21_id - b.sys21_id;
    });
  };

  const valid = current => {
    const today = moment().endOf('day');
    return today.isSameOrAfter(current);
  };

  const handleChangeDTP = date => {
    setSelectedDate(moment(date));
  };

  return (
    <div className="cross-results">
      <div className="page-title">
        <h3>
          <i className="fas fa-clipboard-list" /> Pase de lista
        </h3>
      </div>
      <div className="card bg-card-violet">
        <label>
          <i className="fas fa-list-alt" /> Resultados
        </label>
      </div>

      <div className="row no-row-margin separate">
        <div className="col-md-4 card">
          <div className="card-body">
            <label>
              <b>Mostrando fecha</b>
            </label>
            <DateTime
              closeOnSelect={true}
              dateFormat="DD/MMM/YYYY"
              placeholder="seleccione fecha"
              timeFormat={false}
              onChange={handleChangeDTP}
              value={selectedDate}
              viewMode="days"
              locale="es"
              isValidDate={valid}
              inputProps={{
                required: true,
                className: 'form-control limit',
              }}
            />
          </div>
        </div>
        <div className="col-md-8 card">
          <div className="card-body guide">
            <h6>
              <b>Guía</b>
            </h6>
            <label>
              <div className="square yellow-sq"></div> Actividad capturada no se encuentra en tarjetas
            </label>
            <label>
              <div className="square red-sq"></div> Actividad está en tarjetas y no se ha capturado
            </label>
            <label>
              <i className="fas fa-exclamation-triangle" /> Los jornales capturados no coinciden
            </label>
          </div>
        </div>
      </div>
      <div className="row lote">
        {results.lotes.length > 0 ? (
          results.lotes.map(elm => <LoteResult lote={elm} key={elm.sys21_id} />)
        ) : (
          <div className="col-12">
            <h5> No hay registro de cruce para este día</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default CrossResults;
