import React from 'react';
import { apiGetSingleAdmin, apiChangeStatus } from '../../../lib/apiUsers';
import { withRouter, Link } from 'react-router-dom';
import ModalDesactivar from '../modalDesactivar';

const DetailsAdministrador = (props) => {

    const { id } = props.match.params;
    const { NotificationManager } = props;

    const [administrador, setAdministrador] = React.useState({ name: '', username: '', last_sync: '', device_id: '' });
    const [modalDesactivar, setModalDesactivar] = React.useState({
        show: false,
        name: administrador.name,
        activate: true,
        type: 'desactivar',
        typeUser: 'administrador',
        id: administrador.id
    });

    React.useEffect(() => {
        apiGetSingleAdmin(id)
            .then(response => {
                if (response.error) {

                }
                else {
                    setAdministrador(response);
                    setModalDesactivar({
                        show: false,
                        name: response.name,
                        typeUser: 'supervisor',
                        id: response.id
                    });
                }
            })
    }, [id])

    const handleClose = () => {
        setModalDesactivar({ ...modalDesactivar, show: false });
    }

    const ConfirmModalActivate = () => {
        apiChangeStatus(modalDesactivar.id)
            .then(response => {
                if (response.error) {
                    console.log("paso un error al desactivar");
                    NotificationManager.error("algo salio mal", '', 4000);
                } else {
                    setAdministrador({ ...administrador, active: !administrador.active });

                    NotificationManager.success("Se ha cambiado el status del usuario exitósamente", '', 4000);
                }
            })
    }

    const ShowModal = () => {
        setModalDesactivar({
            ...modalDesactivar,
            type: !administrador.active ? 'activar' : 'desactivar',
            show: true,
        });
    }

    return (<div className="">
        <div className="row ">
            <div className="col-md-6 col-12">
                <h5><i className="fas fa-arrow-left" onClick={() => props.history.goBack()} /> Detalles de administrador</h5>
            </div>

            <div className="col-md-6 col-12 tar">
                {
                    administrador.active ?
                        <button className="btn btn-lgt btn-danger" onClick={ShowModal}><i className="fas fa-ban" /> Desactivar</button>
                        :
                        <button className="btn btn-lgt btn-info" onClick={ShowModal}><i className="fas fa-ban" /> Activar</button>
                }
                <Link to={"/usuarios/editar/admin/" + id} className="btn btn-lgt btn-success"><i className="fas fa-edit" /> Editar</Link>
            </div>

        </div>
        <div className="card separate">
            <div className="card-body">
                <h4>Información administrador</h4>
                <hr />
                <div className="row">
                    <div className="col-md-4 col-12">
                        <p className="lbl-title">Nombre administrador</p>
                        <label>{administrador.name}</label>
                    </div>
                    <div className="col-md-4 col-12">
                        <p className="lbl-title">Nombre usuario</p>
                        <label>{administrador.username}</label>
                    </div>
                    <div className="col-md-4 col-12">
                        <p className="lbl-title">Credencial RFID</p>
                        <label>{administrador.rfid}</label>
                    </div>
                </div>
            </div>
            <ModalDesactivar
                {...modalDesactivar}
                confirm={ConfirmModalActivate}
                handleClose={handleClose}
            />
        </div>

    </div>)
}

export default withRouter(DetailsAdministrador);