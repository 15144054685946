import React from "react";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { apiGetTrabajadores } from "../../lib/apiSys21";
import { apiValidateCardWorkers } from '../../lib/apiPaseLista';

const CardInfo = (props) => {
    const { changeTab, NotificationManager, createCard, verifiedData } = props;
    const typeaheadRef = React.useRef(null);
    const [typeaheadObj, setTypeaheadObj] = React.useState({ isLoading: false, options: [] });
    const [selectedWorkers, setSelectedWorkers] = React.useState([]);

    const handleSelectedElementOnTypeahead = (obj) => {
        if (obj.length === 0) {
            typeaheadRef.current._instance.clear();
        } else {
            const elm = obj[0];
            if (!selectedWorkers.find(worker => worker.sys21_id === elm.sys21_id)) {
                setSelectedWorkers([elm, ...selectedWorkers]);
                typeaheadRef.current._instance.clear();
            } else {
                NotificationManager.warning("El trabajador seleccionado ya se encuentra en la lista", "", 5000);
            }
        }
    }

    const deletWorkerFromList = (id_worker) => {
        setSelectedWorkers(selectedWorkers.filter(worker => worker.sys21_id !== id_worker));
    }

    const searchElement = (search) => {
        setTypeaheadObj({ isLoading: true, options: [] });
        apiGetTrabajadores({ supervisor: 'false', search, limit: 7 })
            .then(response => {
                if (response.error) {

                } else {
                    setTypeaheadObj({ isLoading: false, options: response.rows.map(elm => ({ ...elm, id_name: elm.sys21_id + ' - ' + elm.nombre })) });
                }
            })
            .catch()
            .finally(response => {
                setTypeaheadObj({ isLoading: false });
            })
    }

    const CreateCard = () => {
        if (selectedWorkers.length > 0) {
            let workers = selectedWorkers.map(worker => ({ id: worker.sys21_id, manual: true, destajo: 0 }));
            apiValidateCardWorkers({ ...verifiedData, trabajadores: workers })
                .then(response => {
                    if (response.error) {
                        NotificationManager.warning(response.message, '', 5000);
                        let notValid = response.trabajadores.filter(worker => worker.valido === false);

                        for (let i = 0; i < notValid.length; i++) {
                            const element = notValid[i];
                            setSelectedWorkers(
                                selectedWorkers.map(elm => elm.sys21_id === element.id ?
                                    { ...elm, notValid: true } : elm));
                        }
                    } else {
                        createCard(workers);
                    }
                }).catch(error => {

                })
        } else {
            NotificationManager.warning('No has seleccionado trabajadores', '', 5000);
        }
    }

    return <div className="card-body">
        <div className="form">
            <div className="row separate">
                <div className="col-md-6">
                    <label><b>Elegir trabajadores</b></label>
                    <AsyncTypeahead
                        ref={typeaheadRef}
                        id={"typeahead"}
                        labelKey='id_name'
                        minLength={1}
                        onChange={handleSelectedElementOnTypeahead}
                        isLoading={typeaheadObj.isLoading}
                        filterBy={['nombre']}
                        selectHintOnEnter={true}
                        clearButton={true}
                        searchText="Buscando..."
                        options={typeaheadObj.options}
                        placeholder={"Ingrese nombre de trabajador "}
                        onSearch={(query) => searchElement(query)}
                        renderMenuItemChildren={(option, props) => {
                            return <div key={option.id}>
                                {option.id_name}
                            </div>
                        }
                        }
                        required={true}
                    />
                </div>
                <div className="col-md-6">
                    <p>{selectedWorkers.length >= 20 ? "*El numero recomendado de trabajadores es de 20" : '.'}</p>
                    <label>trabajadores agregados: <b className="color-green">{selectedWorkers.length}</b></label>

                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <p><b>Trabajadores seleccionados</b></p>
                </div>
                <div className="col-md-6">
                    <div className="row no-row-margin">
                        <div className="col-md-4 tac">
                            <label><b>No.</b></label>
                        </div>
                        <div className="col-md-8 tac">
                            <label><b>Nombre de trabajador</b></label>
                        </div>
                    </div>
                    {
                        selectedWorkers.map(worker =>
                            <div key={worker.sys21_id} className={worker.notValid ? "row no-row-margin bg-card-error" : "row no-row-margin bg-card-gray"}>
                                <div className="col-md-2 " onClick={() => deletWorkerFromList(worker.sys21_id)}>
                                    <label><b><i className="fas fa-times" /></b></label>
                                </div>
                                <div className="col-md-2 ">
                                    <label><b>{worker.sys21_id}</b></label>
                                </div>
                                <div className="col-md-8 tac">
                                    <label><b>{worker.nombre}</b></label>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
        <div className="tac separate">
            <button className="btn btn-primary btn-lgt " onClick={() => changeTab(2)}>Anterior</button>
            <button className="btn btn-success btn-lgt " onClick={CreateCard}>Crear Tarjeta</button>
        </div>
    </div>
}

export default CardInfo;