import React from 'react';
import { NotificationManager } from 'react-notifications';
import EditableLote from './editableLote';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { apiGetLotes } from '../../../lib/apiSys21';
import DateTime from 'react-datetime';
import moment from 'moment';
import { apiGetCruce, apiSaveCruce, apiUpdateCruce } from '../../../lib/apiCruceInfo';

const CrossResults = () => {
  const [selectedLotesList, setSelectedLotesList] = React.useState([]);
  const [typeahead, setTypeahead] = React.useState({ isLoading: false, options: [] });
  const [selectedDate, setSelectedDate] = React.useState(moment());
  const [isEditing, setIsEditing] = React.useState(false);
  const typeaheadRef = React.useRef(null);

  React.useEffect(() => {
    apiGetCruce(moment(selectedDate).format('YYYY-MM-DD'))
      .then(response => {
        if (response.error) {
          NotificationManager.error(response.message, '', 5000);
        } else {
          //#todo: hacer sort solo al agregar
          setSelectedLotesList(
            sortAscArray(
              response.lotes.map(lote => ({
                ...lote,
                actividades: sortAscArray(lote.actividades.filter(elm => elm.actividad_tarjetas === 0)),
              })),
            ),
          );
          if (response.lotes.length > 0) {
            setIsEditing(true);
          } else {
            setIsEditing(false);
          }
        }
      })
      .finally();
  }, [selectedDate]);

  const sortAscArray = array => {
    return array.sort(function(a, b) {
      return a.sys21_id - b.sys21_id;
    });
  };

  const handleSelectedWorkerOnTypeahead = obj => {
    let lote = obj[0];
    if (!selectedLotesList.find(l => l.sys21_id === lote.sys21_id)) {
      let newLotes = [{ ...lote, actividades: [] }, ...selectedLotesList];
      setSelectedLotesList(sortAscArray(newLotes));
      typeaheadRef.current._instance.clear();
    } else {
      NotificationManager.warning('El lote ya está en la lista', '', 5000);
    }
    typeaheadRef.current._instance.focus();
  };

  const searchLote = search => {
    apiGetLotes({ search, limit: 7 })
      .then(response => {
        if (response.error) {
          //
        } else {
          setTypeahead({ isLoading: false, options: response.rows.map(elm => ({ ...elm, id_name: elm.sys21_id + ' - ' + elm.nombre })) });
        }
      })
      .catch();
  };

  const deleteLote = lote => {
    setSelectedLotesList(selectedLotesList.filter(elm => elm.sys21_id !== lote.sys21_id));
  };

  const valid = current => {
    const today = moment().endOf('day');
    return today.isSameOrAfter(current);
  };

  const handleChangeDTP = date => {
    setSelectedDate(moment(date));
  };

  const updateActivity = (activityList, loteToUpdate) => {
    //buscar lote y hacerle push
    let loteList = selectedLotesList.map(lote => (lote.sys21_id === loteToUpdate.sys21_id ? { ...lote, actividades: activityList } : lote));
    setSelectedLotesList(loteList);
  };

  const SaveCruce = () => {
    let lotesList = {
      lotes: selectedLotesList.map(lote => ({
        sys21_id: lote.sys21_id,
        actividades: lote.actividades.map(actividad => ({
          sys21_id: actividad.sys21_id,
          total: actividad.total,
          jornales: actividad.jornales,
        })),
      })),
    };
    if (isEditing) {
      console.log('update');
      apiUpdateCruce(moment(selectedDate).format('YYYY-MM-DD'), lotesList).then(response => {
        if (response.error) {
          NotificationManager.error(response.message, '', 5000);
        } else {
          NotificationManager.success('Se ha guardado correctamente', '', 5000);
          setSelectedLotesList(
            response.lotes.map(lote => ({
              ...lote,
              actividades: lote.actividades.filter(elm => elm.actividad_tarjetas === 0),
            })),
          );
          setIsEditing(true);
        }
      });
    } else {
      console.log('add');
      lotesList.fecha = moment(selectedDate).format('YYYY-MM-DD');
      apiSaveCruce(lotesList).then(response => {
        if (response.error) {
          NotificationManager.error(response.message, '', 5000);
        } else {
          //setSelectedLotesList(response.lotes);
          NotificationManager.success('Se ha guardado correctamente', '', 5000);
          setIsEditing(true);
        }
      });
    }
  };

  return (
    <div>
      <div className="page-title">
        <h3>
          <i className="fas fa-clipboard-list" /> Pase de lista
        </h3>
      </div>
      <div className="card bg-card-violet">
        <label>
          <i className="fas fa-random" /> Registro de cruce
        </label>
      </div>
      <div className="row no-row-margin separate">
        <div className="col card">
          <div className="card-body">
            <label>
              <b>Mostrando fecha</b>
            </label>
            <DateTime
              closeOnSelect={true}
              dateFormat="DD/MMM/YYYY"
              placeholder="seleccione fecha"
              timeFormat={false}
              onChange={handleChangeDTP}
              value={selectedDate}
              viewMode="days"
              locale="es"
              isValidDate={valid}
              inputProps={{
                required: true,
                className: 'form-control limit',
              }}
            />
          </div>
        </div>
        <div className="col-md-8 ">
          <div className="card-body">
            <label>
              <b>Elegir lote</b>
            </label>
            <div className="row ">
              <div className="col-md-8">
                <AsyncTypeahead
                  ref={ref => (typeaheadRef.current = ref)}
                  id="typeaheadLote"
                  labelKey="id_name"
                  multiple={false}
                  minLength={1}
                  onChange={handleSelectedWorkerOnTypeahead}
                  isLoading={typeahead.isLoading}
                  filterBy={['nombre']}
                  selectHintOnEnter={true}
                  clearButton={false}
                  searchText="Buscando..."
                  options={typeahead.options}
                  placeholder="Agregar lote"
                  inputProps={{ className: 'limit' }}
                  onSearch={query => searchLote(query)}
                  renderMenuItemChildren={(option, props) => {
                    return <div key={option.id}>{option.id_name}</div>;
                  }}
                />
              </div>
              <div className="col-md-4">
                <button className="btn btn-success form-control" onClick={SaveCruce}>
                  Guardar cambios
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {selectedLotesList.map(elm => (
          <EditableLote
            key={elm.sys21_id}
            lote={elm}
            deleteLote={deleteLote}
            updateActivity={updateActivity}
            NotificationManager={NotificationManager}
          />
        ))}
      </div>

      <div></div>
    </div>
  );
};

export default CrossResults;
