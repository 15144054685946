import { baseUrl, GET, POST, PUT, CreateAuthRequest, makeUrlGET } from './constants';

const urlModule = baseUrl + "cruce/";
//region Cultivo
export const apiGetCruce = async (fecha) => {

    let url = urlModule + fecha;
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiSaveCruce = async (obj) => {

    let url = urlModule;
    let request = await CreateAuthRequest(POST, obj, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiUpdateCruce = async (fecha, obj) => {

    let url = urlModule + fecha;
    let request = await CreateAuthRequest(PUT, obj, true);

    return fetch(url, request)
        .then(response => response.json())
}


//endregion