import React from "react";
import CustomTypeahead from './compTypeahead';
import { apiGetLotes, apiGetActividades, apiGetTrabajadores } from "../../lib/apiSys21";



const CardInfo = (props) => {

    const { NotificationManager, dataVerifications } = props;

    const [form, setForm] = React.useState({
        lotes: [],
        id_supervisor: 0,
        id_cuadrillero: 0,
        id_horario: 1
    });



    const verify = (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {
            dataVerifications(form);
        }
    }

    const changeSelected = (name, obj) => {
        let newForm = {};
        switch (name) {
            case 'Lotes':
                newForm = { ...form, lotes: obj };
                break;
            case 'Supervisor':
                if (obj.sys21_id === 0) {
                    newForm = { ...form, id_supervisor: 0 };
                }
                else {
                    if (form.id_cuadrillero !== obj.sys21_id) {
                        newForm = { ...form, id_supervisor: obj.sys21_id };
                    } else {
                        newForm = { ...form };
                        NotificationManager.warning("Este usuario ya esta asignado ", "", 5000);
                    }
                }
                break;
            case 'Cuadrillero':
                if (obj.sys21_id === 0) {
                    newForm = { ...form, id_cuadrillero: 0 };
                } else {
                    if (form.id_supervisor !== obj.sys21_id) {
                        newForm = { ...form, id_cuadrillero: obj.sys21_id };
                    } else {
                        newForm = { ...form };
                        NotificationManager.warning("Este usuario ya esta asignado ", "", 5000);
                    }
                }
                break;
            default:
                newForm = { ...form };
                break;
        }
        console.log("newForm", newForm, name);
        setForm(newForm);
    }

    const changeHorario = (e) => {
        setForm({ ...form, id_horario: parseInt(e.target.value, 10) });
    }

    return <div className="card-body">
        <form onSubmit={verify}>
            <div className="row separate">
                <div className="col-md-8">
                    <label><b>Lotes</b></label>
                    <CustomTypeahead getElements={apiGetLotes} name="Lotes"
                        multiple={true} filterBy={["nombre"]} form={form} setSelectedElm={changeSelected} clear={false} />
                </div>

            </div>
            <div className="row">
                <div className="col-md-4">
                    <label><b>Turno</b></label>
                    <select className="form-control" onChange={changeHorario} value={form.id_horario}>
                        <option value={1}>Matutino</option>
                        <option value={2}>Vespertino</option>
                    </select>
                </div>
                <div className="col-md-4">
                    <label><b>Supervisor</b></label>
                    <CustomTypeahead getElements={apiGetTrabajadores} name="Supervisor"
                        filterBy={["nombre"]} form={form}
                        setSelectedElm={changeSelected} clear={true}
                        paramsToSearch={{ supervisor: true }} />
                </div>
                <div className="col-md-4">
                    <label><b>Cuadrillero</b></label>
                    <CustomTypeahead getElements={apiGetTrabajadores} name="Cuadrillero"
                        filterBy={["nombre"]} form={form}
                        setSelectedElm={changeSelected} clear={true}
                        paramsToSearch={{ supervisor: 'false' }} />
                </div>
            </div>
            <div className="row separate">
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-md-6">
                            <input className="form-control" value={form.id_horario === 1 ? "08:00:00" : '13:00:00'} readOnly />
                        </div>
                        <div className="col-md-6">
                            <input className="form-control" value={form.id_horario === 1 ? "13:00:00" : '18:00:00'} readOnly />
                        </div>
                    </div>
                </div>
            </div>
            <div className="tac">
                <button type="submit" className="btn btn-success btn-lgt" >Siguiente</button>
            </div>
        </form>
    </div>
}

export default CardInfo;