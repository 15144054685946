import React from "react";
import { Modal } from 'react-bootstrap';


const ModalIncidecia = (props) => {
    const { show, confirm, handleClose, isAdd } = props;
    const [comentario, setComentario] = React.useState('');

    const HandleClose = () => {
        handleClose();
    }

    const Confirm = () => {
        confirm(comentario);
        HandleClose();
    }

    const handleChangeComment = (e) => {
        setComentario(e.target.value);
    }

    return (<Modal show={show} onHide={HandleClose} centered
        dialogClassName="modal-20w"
    >
        <Modal.Header closeButton>
            <Modal.Title>{isAdd ? "Crear " : 'Finalizar '} incidencia</Modal.Title>
        </Modal.Header>
        <Modal.Body  >
            <div className="">
                <p>{isAdd ? "Estas a punto de crear una incidencia" : 'Estas a  punto de finalizar una incidencia'}</p>
                <label>Ingresa comentario</label>
                <textarea className="form-control" value={comentario} onChange={handleChangeComment}></textarea>
            </div>
            <div className="modal-footer">
                <button className="btn btn-danger btn-lgt" onClick={HandleClose}>Cancelar</button>
                <button className="btn btn-success btn-lgt" onClick={Confirm}>{isAdd ? "Crear" : "Finalizar"}</button>
            </div>
        </Modal.Body>
    </Modal>)
}

export default ModalIncidecia;