import React from 'react';

const TabsNavigation = (props) => {

    const { activeTab, setActiveTab } = props;

    let usersType = [{ name: "Apuntadores", value: '1' },
    { name: "Secretarios", value: '2' },
    { name: "Supervisores", value: '3' },
    { name: "RH", value: '4' },
    { name: "Gerentes", value: '5' },
    { name: "Administradores", value: '6' }]

    const handleNavigate = (e) => {
        setActiveTab(e.target.value);
    }

    return <div>
        <select className="form-control" value={activeTab} onChange={handleNavigate}>
            {
                usersType.map(
                    type =>
                        <option key={type.value} value={type.value}>{type.name}</option>
                )
            }
        </select>
    </div>

}

export default TabsNavigation;