import moment from 'moment';
import jwtDecode from 'jwt-decode';
import icon from '../imgs/background.png';

export const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

export const hoursOptions = [
    { value: 0, hour: '00:00' },
    { value: 0.5, hour: '00:30' },
    { value: 1.0, hour: '01:00' },
    { value: 1.5, hour: '01:30' },
    { value: 2.0, hour: '02:00' },
    { value: 2.5, hour: '02:30' },
    { value: 3.0, hour: '03:00' },
    { value: 3.5, hour: '03:30' },
    { value: 4.0, hour: '04:00' },
    { value: 4.5, hour: '04:30' },
    { value: 5.0, hour: '05:00' },
    { value: 5.5, hour: '05:30' },
    { value: 6.0, hour: '06:00' },
    { value: 6.5, hour: '06:30' },
    { value: 7.0, hour: '07:00' },
    { value: 7.5, hour: '07:30' },
    { value: 8.0, hour: '08:00' },
    { value: 8.5, hour: '08:30' },
    { value: 9.0, hour: '09:00' },
    { value: 9.5, hour: '09:30' },
    { value: 10.0, hour: '10:00' },
    { value: 10.5, hour: '10:30' },
    { value: 11.0, hour: '11:00' },
    { value: 11.5, hour: '11:30' },
    { value: 12.0, hour: '12:00' },
    { value: 12.5, hour: '12:30' },
    { value: 13.0, hour: '13:00' },
    { value: 13.5, hour: '13:30' },
    { value: 14.0, hour: '14:00' },
    { value: 14.5, hour: '14:30' },
    { value: 15.0, hour: '15:00' },
    { value: 15.5, hour: '15:30' },
    { value: 16.0, hour: '16:00' },
    { value: 16.5, hour: '16:30' },
    { value: 17.0, hour: '17:00' },
    { value: 17.5, hour: '17:30' },
    { value: 18.0, hour: '18:00' },
    { value: 18.5, hour: '18:30' },
    { value: 19.0, hour: '19:00' },
    { value: 19.5, hour: '19:30' },
    { value: 20.0, hour: '20:00' },
    { value: 20.5, hour: '20:30' },
];

export const roles = [
    { id: 1, name: 'Administrador' },
    { id: 2, name: 'Gerente' },
    { id: 3, name: 'RH' },
    { id: 4, name: 'Supervisor' },
    { id: 5, name: 'Secretario' },
    { id: 6, name: 'Apuntador' },
];

export const makeUrlGET = objs => {
    let params = [];
    for (const key in objs) {
        const val = objs[key];
        if (val) {
            params.push(`${key}=${val}`);
        }
    }
    return params.join('&');
};

export const fetchData = (url, request, resolve, reject) => {
    return fetch(url, request)
        .then(response => {
            if (response.status === 200) {
                resolve(response.json());
            } else {
                reject(response);
            }
        })
        .catch(error => {
            reject(error);
        });
};

//region A U T H   R E Q U E S T
export const CreateAuthRequest = async (method, body, isJson = true) => {
    const token = await UpdateToken();
    const request = {
        method,
        headers: new Headers({
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        }),
    };
    if (isJson) {
        if (body) {
            request.body = JSON.stringify(body);
        }
    } else {
        request.headers.delete('Content-Type');
        request.body = body;
    }

    return request;
};

//Call to refresh token
const refreshToken = () => {
    let url = baseUrl + 'auth/token';
    let headers = new Headers();
    headers.append('authorization', `Bearer ${localStorage.token}`);
    let request = { method: POST, headers };
    return fetch(url, request)
        .then(response => response.json())
        .then(response => response.token);
};

//Update token before it expires
export const UpdateToken = async () => {
    if (localStorage.token && localStorage.token !== 'undefined') {
        const currentTime = moment().unix();
        const decoded = jwtDecode(localStorage.token);
        if (decoded.exp < currentTime) {
            const currentToken = await refreshToken();
            localStorage.setItem('token', currentToken);
            return localStorage.getItem('token');
        } else {
            return localStorage.getItem('token');
        }
    } else {
        localStorage.removeItem('token');
        return null;
    }
};

//region Notifications
export let permissionNotification = Notification.permission === 'granted';

export const AskNotificationPermission = () => {
    Notification.requestPermission(function(permission) {
        if (permission === 'granted') {
            let n = new Notification('Sonora Vending', { body: 'Ahora recibirás notificaciones' });
            setTimeout(n.close.bind(n), 5000);
        }
    });
};

export const showNotification = (title, thebody) => {
    console.log(title, thebody);
    let options = {
        body: thebody,
        icon,
    };
    let n = new Notification(title, options);
    n.onclick = function() {
        window.location.href = '/alertas';
    };
    //setTimeout(n.close.bind(n), 5000);
};
