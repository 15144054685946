import React from "react";
import { connect } from "react-redux";
import "./reportLoader.scss";

const ReportLoader = ({ showGenerating }) => {
  if (!showGenerating) return null;
  return (
    <div className="report-alert">
      <div className="alert alert-warning" role="alert">
        <strong>Generando reporte...</strong>
        <br />
        <span>Espere por favor. Cuando el reporte este listo se abrira una ventana emergente.</span>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    showGenerating: state.generating.show,
  }),
  {}
)(ReportLoader);
