import React from 'react';

const Counters = (props) => {
    const { counters } = props;
    return (
        <div className="row">
            <div className="col-md-3 pd-t">
                <div className="card bg-card-green">
                    <label>Apuntadores</label>
                    <h1>{counters.num_proceso}</h1>
                    <label>Tarjetas pendientes de finalizar</label>
                </div>
            </div>
            <div className="col-md-3 pd-t">
                <div className="card bg-card-blue">
                    <label>Secretario</label>
                    <h1>{counters.num_finalizada_apuntador}</h1>
                    <label>Tarjetas pendientes de aprobar</label>
                </div>
            </div>
            <div className="col-md-3 pd-t">
                <div className="card bg-card-violet">
                    <label>Supervisores</label>
                    <h1>{counters.num_finalizada_secretario}</h1>
                    <label>Tarjetas pendientes de validar</label>
                </div>
            </div>
            <div className="col-md-3 pd-t">
                <div className="card bg-card-pink">
                    <label>RH</label>
                    <h1>{counters.num_validada_supervisor}</h1>
                    <label>Tarjetas pendientes de validar</label>
                </div>
            </div>
        </div>)
}

Counters.defaultProps = {
    counters: {
        num_proceso: 21,
        num_finalizada_apuntador: 5,
        num_finalizada_secretario: 5,
        num_validada_supervisor: 1,
    }
}

export default Counters;