import React from 'react';
import Table from '../../Components/table';

const UsersTable = (props) => {

    const { elementActive, data } = props;

    return <div className="table-responsive">
        <Table
            columns={elementActive.columns}
            data={data}
            tableClass="table table-striped separate"
        />
    </div>
}

export default UsersTable;