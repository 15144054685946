import { baseUrl, CreateAuthRequest, makeUrlGET } from './constants';
const urlModule = baseUrl + 'lista/';

export const apiGetCuadrilleros = async obj => {
  let url = urlModule + 'cuadrillero?' + makeUrlGET(obj);
  let request = await CreateAuthRequest('GET', null, true);

  return fetch(url, request).then(response => response.json());
};

export const apiGetCuadrilleroTrabajadores = async id => {
  let url = urlModule + 'cuadrillero/' + id;
  let request = await CreateAuthRequest('GET', null, true);

  return fetch(url, request).then(response => response.json());
};

export const apiAsignCuadrillero = async (id, body) => {
  let url = urlModule + 'cuadrillero/' + id;
  let request = await CreateAuthRequest('POST', body, true);

  return fetch(url, request).then(response => response.json());
};

export const apiGetEmpleados = async obj => {
  let url = baseUrl + 'sync/user/list?' + makeUrlGET(obj);
  let request = await CreateAuthRequest('GET', null, true);

  return fetch(url, request).then(response => response.json());
};
