import React from 'react';
import InputValidator from '../../../Components/Generals/input';
import { apiCreateSecretary, apiUpdateSecretary, apiGetSingleSecretary } from '../../../lib/apiUsers';
import { withRouter } from 'react-router-dom';

const AddDetailsSecretario = (props) => {
    const { id } = props.match.params;

    const [form, setForm] = React.useState({
        name: "",
        username: "",
        password: ""
    });

    const [passwords, setPasswords] = React.useState({ password: '', confirmPassword: '' });
    const [submited, setSubmited] = React.useState(false);


    React.useEffect(() => {
        if (id) {
            apiGetSingleSecretary(id)
                .then(response => {
                    if (response.error) {

                    }
                    else {
                        setForm({ ...response });
                    }
                })
        } else {
            console.log('se esta agregando');
        }

    }, [id]);

    const setFormasValues = (e) => {
        const { name, value } = e.target;
        let valueFormat = value;
        if (name === "username") {
            valueFormat = value.replace(/\s/g, '')
        }

        setForm({ ...form, [name]: valueFormat });

    }

    const showMessage = () => {
        //console.log('match', passwords.match, typeof passwords.match);
        if (passwords.match !== undefined) {
            if (passwords.match) {
                return <label className="message"><i className="fas fa-check" /> Las contraseñas coinciden</label>
            }
            else {
                return <label className="message"><i className="fas fa-times" /> Las contraseñas no coinciden</label >
            }
        }
        else {
            return '';
        }
    }


    const ChangePassword = (e) => {
        const { name, value } = e.target;
        //this.setState({ [name]: value });

        if (name === "confirmPassword") {
            if (passwords.password.length >= 4) {
                setPasswords({ ...passwords, [name]: value, match: passwords.password === value });
            }
            else {
                setPasswords({ ...passwords, [name]: value, match: undefined });
            }
        }
        else {
            if (passwords.confirmPassword.length >= 4) {
                setPasswords({ ...passwords, [name]: value, match: passwords.confirmPassword === value });
            }
            else {
                setPasswords({ ...passwords, [name]: value, match: undefined });
            }
        }
    }

    const submit = (e) => {
        e.preventDefault();

        if (e.target.checkValidity() && passwords.match) {
            if (id) {
                apiUpdateSecretary(id, { ...form })
                    .then(response => {
                        //mandar a pagina de detalle
                        if (response.error) {
                            props.NotificationManager.error(response.message, 'Algo salió mal', 5000);
                        }
                        else {
                            props.NotificationManager.success('Usuario actualizado con éxito', '', 5000);

                        }

                        //mostrar notificacion de exito
                        //limpiar fomulario maybe
                    })
                    .catch()
            }
            else {
                apiCreateSecretary({ ...form, password: passwords.password })
                    .then(response => {
                        //mandar a pagina de detalle
                        if (response.error) {
                            props.NotificationManager.error(response.message, 'Algo salió mal', 5000);
                        }
                        else {
                            props.NotificationManager.success('Usuario agregado con éxito', '', 5000);
                            props.history.push("/usuarios/detalles/secretario/" + response.id);
                        }

                        //mostrar notificacion de exito
                        //limpiar fomulario maybe
                    })
                    .catch()
            }
        }
        else {
            //mostrar notificacion de que las contraseñas no coinciden o existe otro problema
        }

    }

    const Valitate = () => {
        console.log('validated');
        setSubmited(true);
    }

    return (
        <div>
            <div>
                <h5><i className="fas fa-arrow-left" onClick={() => props.history.goBack()} /> {id ? "Editar" : "Crear"} secretario</h5>
            </div>


            <div className="card">
                <div className="card-body">
                    <h5>Información de secretario</h5>
                    <hr />
                    <form onSubmit={submit} className={"needs-validation" + (submited ? " was-validated" : "")} noValidate="novalidate">
                        <div className="row">
                            <div className="col-md-4 col-12">
                                <label>Nombre del secretario</label>
                                <InputValidator placeholder="Nombre del secretario" name="name" value={form.name} onChange={setFormasValues} feedback={{ valid: "", invalid: "Este campo requerido" }} required />
                            </div>
                            <div className="col-md-4 col-12">
                                <label>Nombre de usuario</label>
                                <InputValidator placeholder="Nombre de usuario" name="username" value={form.username} onChange={setFormasValues} feedback={{ valid: "", invalid: "Este campo requerido y no acepta espacios" }} required />
                            </div>
                            <div className="col-md-4 col-12">
                                <label>contraseña</label>
                                <InputValidator type="password" placeholder="Contraseña" name="password" value={passwords.password} onChange={ChangePassword} feedback={{ valid: "", invalid: "Este campo es requerido" }} />
                            </div>
                            <div className="col-md-4 col-12">
                                <label>Repetir contraseña</label>
                                <InputValidator type="password" placeholder="Confirmar contraseña" name="confirmPassword" value={passwords.confirmPassword} onChange={ChangePassword} required feedback={{ valid: "", invalid: "Este campo es requerido" }} />
                            </div>
                            <div className="col-md-4 separate">
                                {
                                    showMessage()
                                }
                            </div>
                        </div>
                        <div className="tac separate">
                            <button className="btn btn-submit" type="submit" onClick={Valitate}>Crear secretario</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>)
}

export default withRouter(AddDetailsSecretario);