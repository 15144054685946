import React from 'react';
import { Modal } from 'react-bootstrap';


const ModalAprobar = (props) => {
    const { show, handleClose, confirm } = props;
    const [rfid, setRFID] = React.useState('');

    const Read = (key) => {
        if (key === "Enter") {

        } else {
            setRFID(rfid + key);
        }
    }

    const handleCloseModal = () => {
        setRFID('');
        handleClose();
    }

    const continuar = () => {
        if (rfid.length > 0) {
            confirm({ rfid });
            handleCloseModal();
        }
    }


    return (
        <Modal show={show} onHide={handleCloseModal} centered
            dialogClassName="modal-20w">
            <Modal.Header closeButton>
                <Modal.Title>Confirmar validación de tarjeta</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className="tac" tabIndex="0" onKeyPress={(e) => Read(e.key)}>
                    <label>Para validar las tarjetas es necesario escanear la tarjeta del Supervisor</label>
                    <button className="btn btn-success btn-lgt" >Click para escanear</button>
                </div>

                <hr />
                <div className="tac separate">
                    <button className="btn btn-lgt btn-primary" onClick={continuar} >Validar</button>
                </div>

            </Modal.Body>
        </Modal>
    );
}

ModalAprobar.defaultProps = {
    show: false,
    name: '',
    type: 'desactivar',
    typeUser: '',
    confirm: () => { },
    handleClose: () => { },
}

export default ModalAprobar;