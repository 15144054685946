import React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { apiGetLotes } from '../../../lib/apiSys21';
import { apiUpdateLotesList } from '../../../lib/apiPaseLista';

const CardLotes = (props) => {
    const { lotes, id, NotificationManager, editable } = props;
    const [lotesList, setLotesList] = React.useState(lotes);
    const [isEditing, setIsEditing] = React.useState(false);
    const [typeaheadLote, setTypeaheadLote] = React.useState({ loading: false, options: [] });

    const typeaheadRef = React.useRef(null);

    React.useEffect(() => {
        setLotesList(lotes);
    }, [lotes]);


    const handleClickEliminar = (lote) => {
        if (lote.toEliminate) {
            setLotesList(lotesList.map(elm => elm.id_lote === lote.id_lote ?
                { ...elm, toEliminate: false }
                : elm))
        } else {
            setLotesList(lotesList.map(elm => elm.id_lote === lote.id_lote ?
                { ...elm, toEliminate: true }
                : elm))
        }
    }

    const searchLotes = (search) => {
        setTypeaheadLote({ ...typeaheadLote, loading: true });
        apiGetLotes({ search, page: 1, limit: 4 })
            .then(response => {
                if (response.error) {

                } else {
                    const typeah = {
                        ...typeaheadLote, loading: false,
                        options: response.rows.map(elm => ({ ...elm, id_name: elm.sys21_id + " - " + elm.nombre }))
                    }
                    console.log(typeah);
                    setTypeaheadLote(typeah);
                }
            })
            .catch()
            .finally(() => {
                //console.log(typeaheadWorker);
                //setTypeaheadLote({ ...typeaheadLote, loading: false });
            })
    }

    const handleSelectedLoteOnTypeahead = (obj) => {
        const lote = obj[0];
        console.log(lote);
        if (lote) {
            if (lotesList.find(l => l.id_lote === lote.sys21_id)) {
                NotificationManager.warning("El lote seleccionado ya se encuentra agregado ", "", 5000);
            } else {
                setLotesList([{
                    id_lote: lote.sys21_id,
                    nombre: lote.nombre,
                    hectareas: 0,
                }, ...lotesList])
            }
        }
        //limpiar y auto focus con un ref
        typeaheadRef.current._instance.clear()
        typeaheadRef.current._instance.focus()
        //setTypeaheadWorker({ ...typeaheadWorker, loading: false, options: [] });
    }

    const changeIsEditing = () => {
        if (isEditing) {
            setLotesList(lotes);
        } else {

        }
        setIsEditing(!isEditing);
    }

    const saveLotes = () => {
        apiUpdateLotesList(id,
            {
                lotes: lotesList.filter(elm => !elm.toEliminate).map(elm => elm.id_lote)
            }
        )
            .then(response => {
                if (response.error) {
                    NotificationManager.error(response.message, '', 5000);
                } else {
                    NotificationManager.success('Se ha guardado correctamente', '', 5000);
                    setLotesList(response.lotes)
                    setIsEditing(false);
                }
            })
            .catch(() => {
                NotificationManager.error('Ocurrió un error. Inténtelo más tarde', '', 5000);
            })
    }

    return <div className="card separate">
        <div className="card-body">
            <div className="row no-row-margin">
                <div className="col-md-6 col-12">
                    <h5><i className="fas fa-id-card" /> Lotes</h5>
                </div>

                <div className="col-md-6 col-12 tar">
                    {
                        editable &&
                        (!isEditing ?
                            <button className="btn btn-lgt btn-success" onClick={changeIsEditing}><i className="fas fa-edit" /> Editar lotes</button>
                            :
                            <React.Fragment>
                                <button className="btn btn-lgt btn-danger" onClick={changeIsEditing}><i className="fas fa-times" /> Cancelar</button>
                                <button className="btn btn-lgt btn-success" onClick={saveLotes} ><i className="fas fa-save" /> Guardar</button>
                            </React.Fragment>)
                    }
                </div>
            </div>
            <div >
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Número</th>
                                <th>Nombre</th>
                                <th>Hectáreas</th>
                                {
                                    isEditing &&
                                    <th>Eliminar</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isEditing &&
                                <tr>
                                    <td colSpan={5}>
                                        <AsyncTypeahead
                                            ref={(ref) => typeaheadRef.current = ref}
                                            id="typeaheadLotes"
                                            labelKey='id_name'
                                            multiple={false}
                                            minLength={1}
                                            onChange={handleSelectedLoteOnTypeahead}
                                            isLoading={typeaheadLote.isLoading}
                                            filterBy={['nombre']}
                                            selectHintOnEnter={true}
                                            clearButton={false}
                                            searchText="Buscando..."
                                            options={typeaheadLote.options}
                                            placeholder="Ingrese nombre de lote"
                                            onSearch={(query) => searchLotes(query)}
                                            renderMenuItemChildren={(option, props) => {
                                                return <div key={option.id}>
                                                    {option.id_name}
                                                </div>
                                            }
                                            }
                                        />
                                    </td>
                                </tr>

                            }
                            {lotesList.map(elm =>
                                <tr key={elm.id_lote} className={elm.toEliminate ? 'to-eliminate' : ''}>
                                    <td>{elm.id_lote}</td>
                                    <td>{elm.nombre}</td>
                                    <td>{elm.hectareas}</td>
                                    {
                                        isEditing &&
                                        <td>
                                            <button className="btn btn-danger btn-lgt" onClick={() => handleClickEliminar(elm)}>
                                                {elm.toEliminate ? <i className="fas fa-ban" /> : <i className="fas fa-times" />} </button>
                                        </td>
                                    }
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
}

CardLotes.defaultProps = {
    lotes: []
}

export default CardLotes;