import React from 'react';

const Tabs = (props) => {

    const { activeTab, setActiveTab } = props;

    let usersType = [{ name: "Apuntadores", value: '1' },
    { name: "Secretarios", value: '2' },
    { name: "Supervisores", value: '3' },
    { name: "RH", value: '4' },
    { name: "Gerentes", value: '5' },
    { name: "Administradores", value: '6' }]

    const changeActive = (tab) => {
        setActiveTab(tab);
    }


    return (
        <div className="">
            <div className="btn-group btn-tabs" role="group" >
                {
                    usersType.map(elm =>
                        <button key={elm.name} type="button" onClick={() => changeActive(elm.value)}
                            className={"btn btn-light" + (activeTab === elm.value ? " active" : '')}>
                            {elm.name}
                        </button>
                    )
                }
            </div>
        </div>
    )
}

export default Tabs;