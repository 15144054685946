import React, { useEffect, Fragment } from "react";
import { useWebsocket } from "@wafo/react-websocket";
import useGeneratingReport from "../../hooks/useGeneratingReport";
import FileSaver from "file-saver";

function b64toFile(b64Data, filename, contentType) {
  var sliceSize = 512;
  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);

      for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
  }
  var file = new File(byteArrays, filename, {type: contentType});
  return file;
}

const WSReportLoader = () => {
  const generating = useGeneratingReport();

  const { socketStatus, socket } = useWebsocket({
    url: process.env.REACT_APP_WSS,
    reconnect: false,
    onMessage: ({ data }, ws) => {
      const dataObj = JSON.parse(data);
      const ayylmao = dataObj.event || dataObj.action;
      switch (ayylmao) {
        case "ping":
          ws.send('{"action":"pong"}');
          break;
        case "report":
          const {
            payload: { type, buffer },
          } = dataObj;

          const file = b64toFile(buffer, "report.xlsx", type);
          FileSaver.saveAs(file, "report.xlsx");

          generating.stop();
          break;
        default:
          break;
      }
    },
  });

  useEffect(() => {
    if (socketStatus === 1) {
      socket.send(
        JSON.stringify({
          action: "authenticate",
          payload: localStorage.token,
        })
      );
    }
  }, [socketStatus, socket]);

  return <Fragment></Fragment>;
};

export default WSReportLoader;
