import React from 'react';
import { hoursOptions } from '../../../lib/constants';
import styles from './cards.module.css';

const CardActivities = props => {
  const { actividades, id, NotificationManager, editable, is_destajo } = props;

  const activitiesList = React.useMemo(() => {
    console.log(actividades);
    return actividades.map(a => ({ ...a }));
  }, [actividades]);

  const hours = React.useMemo(() => {
    return hoursOptions.reduce(
      (accumulator, hours) => {
        return {
          ...accumulator,
          [hours.value]: hours.hour,
        };
      },
      [{}],
    );
  }, []);

  return (
    <div className="card separate">
      <div className="card-body">
        <div className="row no-row-margin">
          <div className="col-md-6 col-12">
            <h5>
              <i className="fas fa-id-card" /> Actividades
            </h5>
          </div>

          {/* <div className="col-md-6 col-12 tar">
                    {
                        editable &&
                        (!isEditing ?
                            <button className="btn btn-lgt btn-success" onClick={changeIsEditing}><i className="fas fa-edit" /> Editar actividades</button>
                            :
                            <React.Fragment>
                                <button className="btn btn-lgt btn-danger" onClick={changeIsEditing}><i className="fas fa-times" /> Cancelar</button>
                                <button className="btn btn-lgt btn-success" onClick={saveActivities} ><i className="fas fa-save" /> Guardar</button>
                            </React.Fragment>)
                    }
                </div> */}
        </div>
        <div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Número</th>
                  <th>Nombre</th>
                  {!is_destajo && (
                    <React.Fragment>
                      <th>Horas laborales</th>
                      <th>Horas extra</th>
                    </React.Fragment>
                  )}
                </tr>
              </thead>
              <tbody>
                {activitiesList.map(activity => {
                  let laborales = 0;
                  let extra = 0;

                  if (activity.cultivos && activity.cultivos.length) {
                    activity.cultivos.forEach(cultivo => {
                      laborales += parseFloat(cultivo.numero_horas);
                      extra += parseFloat(cultivo.horas_extra);
                    });
                  }

                  return (
                    <React.Fragment key={activity.id_actividad}>
                      <tr className="">
                        <td>{activity.id_actividad}</td>
                        <td>{activity.nombre_actividad}</td>
                        {!is_destajo && (
                          <React.Fragment>
                            <td>{hours[laborales]}</td>
                            <td>{hours[extra]}</td>
                          </React.Fragment>
                        )}
                      </tr>
                      {!is_destajo && activity.cultivos &&
                        activity.cultivos.map(cultivo => (
                          <tr key={cultivo.id_cultivo} className={styles['cultivo-tr']}>
                            <td colSpan="2">
                              <b>Cultivo: </b>
                              {cultivo.nombre_cultivo}
                            </td>
                            <td>{hours[parseFloat(cultivo.numero_horas)]}</td>
                            <td>{hours[parseFloat(cultivo.horas_extra)]}</td>
                          </tr>
                        ))}
                    </React.Fragment>
                  );
                })}
                {/* {isEditing && (
                  <tr>
                    <td colSpan={5}>
                      <AsyncTypeahead
                        ref={ref => (typeaheadRef.current = ref)}
                        id="typeaheadActividades"
                        labelKey="id_name"
                        multiple={false}
                        minLength={1}
                        onChange={handleSelectedActivityOnTypeahead}
                        isLoading={typeaheadActivity.isLoading}
                        filterBy={['nombre']}
                        selectHintOnEnter={true}
                        clearButton={false}
                        searchText="Buscando..."
                        options={typeaheadActivity.options}
                        placeholder="Ingrese nombre de actividad"
                        onSearch={query => searchActivities(query)}
                        renderMenuItemChildren={(option, props) => {
                          return <div key={option.id}>{option.id_name}</div>;
                        }}
                      />
                    </td>
                  </tr>
                )} */}
                {/* {activitiesList.length > 0 ? (
                  activitiesList.map(activity => (
                    <tr key={activity.id_actividad} className={activity.toEliminate ? 'to-eliminate' : ''}>
                      <td>{activity.id_actividad}</td>
                      <td>{activity.nombre_actividad}</td>
                      {!is_destajo && (
                        <React.Fragment>
                          <td>
                            {isEditing ? (
                              <select
                                className="form-control"
                                value={activity.numero_horas}
                                onChange={e => setHours(e, activity.id_actividad)}
                              >
                                {hoursOptions.map(hour => (
                                  <option key={hour.hour} value={hour.value}>
                                    {hour.hour}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              hoursOptions.find(o => o.value === parseFloat(activity.numero_horas)).hour
                            )}
                          </td>
                          <td>
                            {// isEditing ?
                            //     <div className="custom-control custom-checkbox">
                            //         <input type="checkbox" className="custom-control-input" id={"check-" + activity.id_actividad} checked={activity.hora_extra} />
                            //         <label className="custom-control-label" for={'check-' + activity.id_actividad} onClick={() => setExtraHours(activity.id_actividad)}></label>
                            //     </div> :
                            //     (activity.hora_extra ? "Si" : 'No')
                            activity.horas_extra === '0.00'
                              ? '0:00'
                              : hoursOptions.find(o => o.value === parseFloat(activity.horas_extra)).hour}
                          </td>
                        </React.Fragment>
                      )}
                      {isEditing && (
                        <td>
                          <button className="btn btn-danger btn-lgt" onClick={() => handleClickEliminar(activity)}>
                            {activity.toEliminate ? <i className="fas fa-ban" /> : <i className="fas fa-times" />}{' '}
                          </button>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>No se han seleccionado actividades</td>
                  </tr>
                )} */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

CardActivities.defaultProps = {
  actividades: [],
};

export default CardActivities;
