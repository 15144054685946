const initialState = { show: false };

const SHOW_LOADER = "SHOW_LOADER";

export const setShowLoader = (status) => ({
  type: SHOW_LOADER,
  payload: status,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, show: action.payload };
    default:
      return { ...state };
  }
};
