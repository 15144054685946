import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter, useHistory } from 'react-router-dom';
import logo from '../../imgs/Logo-agrofesa.gif';

const roles = [
  { id: 1, name: 'Administrador' },
  { id: 2, name: 'Gerente' },
  { id: 3, name: 'RH' },
  { id: 4, name: 'Supervisor' },
  { id: 5, name: 'Secretario' },
  { id: 6, name: 'Apuntador' },
];

const instance_type = process.env.REACT_APP_MODE;

const SideBar = ({ user }) => {
  function toggle() {
    var element = document.getElementById('wrapper');
    if (element.classList.contains('toggled')) {
      element.classList.remove('toggled');
    } else {
      element.classList.add('toggled');
    }
  }

  function logout() {
    localStorage.clear();
    history.push('/');
  }

  const history = useHistory();

  return (
    <div id="sidebar-wrapper">
      <div className="sidebar-logo">
        <img className="img" src={logo} alt="Agrofesa" />

        <ul className="nav nav-bar nav-flex-icons row tac ">
          <li className="col-md-4" onClick={toggle}>
            <i className="fas fa-lg fa-bars" />
          </li>
          <li className="col-md-8 name-user">
            <b>{user.username}</b>
          </li>
        </ul>
        <div className="tac name-user">
          <label>{roles.find(elm => elm.id === user.role_id).name}</label>
        </div>
      </div>
      <hr className="lil-hr" />
      <ul className="sidebar-nav">
        <li>
          <NavLink to={'/inicio'} title={'Inicio'} className="">
            <i className={'fas fa-lg fa-tachometer-alt'} /> Inicio
          </NavLink>
        </li>
        {(user.role_id === 1 || user.role_id === 5) && (
          <React.Fragment>
            {/* <li>
              <NavLink to={'/paselista/nuevatarjeta'} title={'Pase de lista - Nueva tarjeta'} className="">
                <i className={'fas fa-lg fa-plus'} /> Nueva tarjeta
              </NavLink>
            </li> */}
            <li>
              <NavLink to={'/paselista/secretario'} title={'Pase de lista - Secretario'} className="">
                <i className={'fas fa-lg fa-th-list'} /> Secretario
              </NavLink>
            </li>
          </React.Fragment>
        )}
        {(user.role_id === 1 || user.role_id === 3) && (
          <li>
            <NavLink to={'/paselista/rh'} title={'Pase de lista - RH'} className="">
              <i className={'fas fa-lg fa-clipboard-list'} /> RH
            </NavLink>
          </li>
        )}
        {(user.role_id === 1 || user.role_id === 2) && (
          <li>
            <NavLink to={'/paselista/gerente'} title={'Gerente'} className="">
              <i className={'fas fa-lg fa-clipboard-list'} /> Gerente 
            </NavLink>
          </li>
        )}
        <li>
          <NavLink to={'/paselista/estadisticas'} title={'Pase de lista - Estadísticas'} className="">
            <i className={'fas fa-lg fa-chart-line'} /> Estadísticas
          </NavLink>
        </li>
        {instance_type !== "oficina" && (
          <li>
            <NavLink to={'/paselista/registrocruce'} title={'Pase de lista - Registro de cruce'} className="">
              <i className={'fas fa-lg fa-random'} /> Registro de cruce
            </NavLink>
          </li>
        )}
        <li>
          <NavLink to={'/paselista/resultadoscruce'} title={'Pase de lista - Resultados cruce'} className="">
            <i className={'fas fa-lg fa-list-alt'} /> Resultados
          </NavLink>
        </li>
        {/*
            <li>
                <Accordion defaultActiveKey="1">
                    <Accordion.Toggle as={NavLink} to={"/paselista"} variant="link" eventKey="0">
                        <i className="fas fa-lg fa-clipboard-list"></i>  Pase de lista
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">

                        <ul className="sidebar-nav bg-green">
                            {
                                (user.role_id === 1 || user.role_id === 5) &&
                                <li>
                                    <NavLink to={'/paselista/secretario'} title={'Pase de lista - Secretario'} className=''><i className={'fas fa-lg fa-clipboard-list'} />  Secretario</NavLink>
                                </li>
                            }
                            {
                                (user.role_id === 1 || user.role_id === 3) &&
                                <li>
                                    <NavLink to={'/paselista/rh'} title={'Pase de lista - RH'} className=''><i className={'fas fa-lg fa-clipboard-list'} />  RH</NavLink>
                                </li>
                            }
                            <li>
                                <NavLink to={'/paselista/estadisticas'} title={'Pase de lista - Estadísticas'} className=''><i className={'fas fa-lg fa-clipboard-list'} />  Estadísticas</NavLink>
                            </li>
                            <li>
                                <NavLink to={'/paselista/registrocruce'} title={'Pase de lista - Registro de cruce'} className=''><i className={'fas fa-lg fa-clipboard-list'} />  Registro de cruce</NavLink>
                            </li>
                            <li>
                                <NavLink to={'/paselista/resultadoscruce'} title={'Pase de lista - Resultados cruce'} className=''><i className={'fas fa-lg fa-clipboard-list'} />  Resultados</NavLink>
                            </li>
                        </ul>
                    </Accordion.Collapse>
                </Accordion>
            </li>
        */}
        <li>
          <NavLink to={'/sys21'} title={'Sys21'} className="">
            <i className={'fas fa-lg fa-book-open'} /> Sys21
          </NavLink>
        </li>
        {(user.role_id === 1 || user.role_id === 3) && (
          <li>
            <NavLink to={'/usuarios'} title={'Usuarios'} className="">
              <i className={'fas fa-lg fa-users'} /> Usuarios
            </NavLink>
          </li>
        )}

        <li>
          <NavLink to={'/reportes'} title={'Reportes'} className="">
            <i className={'fas fa-lg fa-chart-bar'} /> Reportes
          </NavLink>
        </li>

        {user.role_id === 1 && instance_type !== "oficina" && (
          <li>
            <NavLink to={'/incidencias/apuntador'} title={'Incidencias apuntador'} className="">
              <i className={'fas fa-lg fa-exclamation-triangle'} /> Incidencias apuntador
            </NavLink>
          </li>
        )}

        {(user.role_id === 1 || user.role_id === 3) && instance_type !== "oficina" && (
          <li>
            <NavLink to={'/cuadrilleros'} title={'Reportes'} className="">
              <i className={'fas fa-lg fa-address-card'} /> Cuadrilleros
            </NavLink>
          </li>
        )}

        <li onClick={logout}>
          <a href="/" title={'Cerrar sesión'}>
            <i className="fas fa-lg fa-sign-out-alt" /> Cerrar sesión
          </a>
        </li>
      </ul>
    </div>
  );
};

SideBar.propTypes = {
  user: PropTypes.any,
};

export default withRouter(SideBar);
