const initialState = { show: false };

const SHOW_GENERATING = "SHOW_GENERATING";

export const setShowGenerating = (state) => ({
  type: SHOW_GENERATING,
  payload: state,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_GENERATING:
      return { ...state, show: action.payload };
    default:
      return { ...state };
  }
};
