import React from 'react';

const Tabs = (props) => {

    const { activeTab, setActiveTab } = props;

    let usersType = [
        { name: "Temporadas", value: '1' },
        { name: "Cultivos", value: '2' },
        { name: "Lotes", value: '3' },
        { name: "Actividades", value: '4' },
        { name: "Trabajadores", value: '5' },
        { name: "Empresas", value: '6' },
    ]

    const changeActive = (tab) => {
        setActiveTab(tab);
    }


    return (
        <div className="">
            <div className="btn-group btn-tabs" role="group" >
                {
                    usersType.map(elm =>
                        <button key={elm.name} type="button" onClick={() => changeActive(elm.value)}
                            className={"btn btn-light" + (activeTab === elm.value ? " active" : '')}>
                            {elm.name}
                        </button>
                    )
                }
            </div>
        </div>
    )
}

export default Tabs;