import React from 'react';
import moment from 'moment';
import Counters from './counters';
import { apiGetCardStatistics } from '../../lib/apiPaseLista';
import loaderconnet from '../../Components/Loader/loaderConnect';
import TablaCosto from './tablaCostos';

const Inicio = props => {
  const { NotificationManager, showLoader } = props;

  const [statistics, setStatistics] = React.useState({
    num_trabajadores: 0,
    num_actividad: 0,
    num_creadas: 0,
    num_terminados: 0,
  });

  moment.updateLocale('es', {
    monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  });

  React.useEffect(() => {
    async function getStatistics() {
      try {
        showLoader(true);
        const response = await apiGetCardStatistics(moment().format('YYYY-MM-DD'));
        if (response.error) {
          NotificationManager.error('Por el moneto no se pueden mostrar las estadísticas. intentelo más tarde.', '', 5000);
        } else {
          setStatistics(response);
        }
        showLoader(false);
      } catch (error) {
        showLoader(false);
      }
    }
    getStatistics();
  }, [showLoader]);

  return (
    <div>
      <div className="page-title ">
        <h5>Buenos días,</h5>
      </div>

      <div className="row">
        <div className="col-md-6">
          <h5> {moment().format('dddd DD, MMMM YYYY')}</h5>
        </div>
        {/* <div className="col-md-6"></div> */}
      </div>

      <div className="card bg-card-violet">
        <label>
          <i className="fas fa-chart-line" /> Estadísticas generales
        </label>
      </div>
      <Counters counters={statistics} />

      {/* <div>
            <TablaCosto />
        </div> */}
    </div>
  );
};

export default loaderconnet(Inicio);
