import React from 'react';
import Tabs from './tabs';
import TabsNavigation from './tabsNavigation';
import UsersTable from './usersTable';
import {
  apiGetPrompter,
  apiGetSecretary,
  apiGetSupervisor,
  apiGetGerente,
  apiGetRH,
  apiGetAdmin,
  apiChangeStatus,
} from '../../lib/apiUsers';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Paginations from '../../Components/Pagination/pagination';
import ModalDesactivar from './modalDesactivar';
import Filters from './filters';
import loaderConnect from '../../Components/Loader/loaderConnect';
import useDebounce from '../../Components/useDebounce';
import { useMediaQuery } from 'react-responsive';

const tabs = [
  {
    num: 1,
    link: '/usuarios/agregar/apuntador',
    buttonText: 'Nuevo apuntador',
    GetFunction: apiGetPrompter,
    columns: ['Nombre apuntador', 'Nombre de usuario', 'Última sincronización', 'Opciones'],
  },
  {
    num: 2,
    link: '/usuarios/agregar/secretario',
    buttonText: 'Nuevo secretario',
    GetFunction: apiGetSecretary,
    columns: ['Nombre secretario', 'Nombre de usuario', 'Última sincronización', 'Opciones'],
  },
  {
    num: 3,
    link: '/usuarios/agregar/supervisor',
    buttonText: 'Nuevo supervisor',
    GetFunction: apiGetSupervisor,
    columns: ['Nombre supervisor', 'Nombre de usuario', 'Opciones'],
  },
  {
    num: 4,
    link: '/usuarios/agregar/rh',
    buttonText: 'Nuevo rh',
    GetFunction: apiGetRH,
    columns: ['Nombre rh', 'Nombre de usuario', 'Última sincronización', 'Opciones'],
  },
  {
    num: 5,
    link: '/usuarios/agregar/gerente',
    buttonText: 'Nuevo gerente',
    GetFunction: apiGetGerente,
    columns: ['Nombre gerente', 'Nombre de usuario', 'Última sincronización', 'Opciones'],
  },
  {
    num: 6,
    link: '/usuarios/agregar/admin',
    buttonText: 'Nuevo administrador',
    GetFunction: apiGetAdmin,
    columns: ['Nombre administrador', 'Nombre de usuario', 'Opciones'],
  },
];

const reducer = (state, action) => {
  switch (action.type) {
    case 'setActiveTab':
      return { ...state, activeTab: action.payload };
    case 'setResults':
      return { ...state, results: action.payload };
    case 'setElementActive':
      return { ...state, elementActive: action.payload };
    case 'setFilters':
      return { ...state, filters: action.payload };
    case 'setModalDesactivar':
      return { ...state, modalDesactivar: action.payload };
    case 'setSearch':
      return { ...state, search: action.payload };
    default:
      return { ...state };
  }
};

const initialState = {
  activeTab: '1',
  filters: { page: 1, limit: 10, active: true },
  search: '',
  results: { count: 0, rows: [] },
  elementActive: tabs[0],
  modalDesactivar: {
    show: false,
    name: '',
    activate: true,
    type: 'desactivar',
    typeUser: '',
    id: 0,
  },
};

const Users = props => {
  const firstState = props.location.state
    ? {
        ...initialState,
        filters: props.location.state.filters,
        activeTab: props.location.state.activeTab,
        elementActive: tabs.find(elm => elm.num === parseInt(props.location.state.activeTab, 10)),
      }
    : initialState;

  const { showLoader, NotificationManager } = props;
  const [state, dispatch] = React.useReducer(reducer, firstState);
  const { activeTab, results, elementActive, filters, modalDesactivar, search } = state;
  const [lastSearch, setLastSearch] = React.useState(null);
  const debounce = useDebounce(search, 300);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 });

  React.useEffect(() => {
    showLoader(true);

    elementActive
      .GetFunction({ ...filters, search: debounce })
      .then(response => {
        if (!response.error) {
          dispatch({ type: 'setResults', payload: response });
        }
      })
      .finally(r => {
        showLoader(false);
      });
  }, [elementActive, filters, showLoader, debounce]);

  const GoDetails = path => {
    const location = {
      pathname: `${props.location.pathname}`,
      state: { filters, activeTab },
    };

    props.history.replace(location);
    props.history.push(path);
  };

  const ShowModalDesactivar = (obj, type, typeUser) => {
    dispatch({
      type: 'setModalDesactivar',
      payload: {
        show: true,
        type,
        typeUser,
        name: obj.name,
        obj,
      },
    });
  };

  const mapData = data => {
    let dataTable = [];
    switch (activeTab) {
      case '1': //apuntador
        dataTable = data.map(elm => ({
          name: elm.name,
          username: elm.username,
          last_sync: elm.last_sync ? moment(elm.last_sync).format('DD/MMM/YYYY') : '',
          options: (
            <React.Fragment>
              <button onClick={() => GoDetails('/usuarios/detalles/apuntador/' + elm.id)} className="btn btn-lgt btn-primary">
                <i className="fas fa-list" /> <span className="btn-text">Detalles</span>
              </button>
              {elm.active ? (
                <button className="btn btn-lgt btn-danger" onClick={() => ShowModalDesactivar(elm, 'desactivar', 'apuntador')}>
                  <i className="fas fa-ban" /> <span className="btn-text">Desactivar</span>
                </button>
              ) : (
                <button className="btn btn-lgt btn-success" onClick={() => ShowModalDesactivar(elm, 'activar', 'apuntador')}>
                  <i className="fas fa-check" /> <span className="btn-text">Activar</span>
                </button>
              )}
              <button onClick={() => GoDetails('/usuarios/editar/apuntador/' + elm.id)} className="btn btn-lgt btn-success">
                <i className="fas fa-edit" /> <span className="btn-text">Editar</span>
              </button>
            </React.Fragment>
          ),
        }));
        break;
      case '2': //secretario
        dataTable = data.map(elm => ({
          name: elm.name,
          username: elm.username,
          last_sync: elm.last_sync ? moment(elm.last_sync).format('DD/MMM/YYYY') : '',
          options: (
            <React.Fragment>
              <button onClick={() => GoDetails('/usuarios/detalles/secretario/' + elm.id)} className="btn btn-lgt btn-primary">
                <i className="fas fa-list" /> <label className="btn-text">Detalles</label>
              </button>
              {elm.active ? (
                <button className="btn btn-lgt btn-danger" onClick={() => ShowModalDesactivar(elm, 'desactivar', 'secretario')}>
                  <i className="fas fa-ban" /> <span className="btn-text">Desactivar</span>
                </button>
              ) : (
                <button className="btn btn-lgt btn-success" onClick={() => ShowModalDesactivar(elm, 'activar', 'secretario')}>
                  <i className="fas fa-check" /> <span className="btn-text">Activar</span>
                </button>
              )}
              <button onClick={() => GoDetails('/usuarios/editar/secretario/' + elm.id)} className="btn btn-lgt btn-success">
                <i className="fas fa-edit" /> <span className="btn-text">Editar</span>
              </button>
            </React.Fragment>
          ),
        }));
        break;
      case '3': //supervisores
        dataTable = data.map(elm => ({
          name: elm.name,
          username: elm.username,
          last_sync: elm.last_sync ? moment(elm.last_sync).format('DD/MMM/YYYY') : '',
          options: (
            <React.Fragment>
              <button onClick={() => GoDetails('/usuarios/detalles/supervisor/' + elm.id)} className="btn btn-lgt btn-primary">
                <i className="fas fa-list" /> <span className="btn-text">Detalles</span>
              </button>
              {elm.active ? (
                <button className="btn btn-lgt btn-danger" onClick={() => ShowModalDesactivar(elm, 'desactivar', 'supervisor')}>
                  <i className="fas fa-ban" /> <span className="btn-text">Desactivar</span>
                </button>
              ) : (
                <button className="btn btn-lgt btn-success" onClick={() => ShowModalDesactivar(elm, 'activar', 'supervisor')}>
                  <i className="fas fa-check" /> <span className="btn-text">Activar</span>
                </button>
              )}
              <button onClick={() => GoDetails('/usuarios/editar/supervisor/' + elm.id)} className="btn btn-lgt btn-success">
                <i className="fas fa-edit" /> <span className="btn-text">Editar</span>
              </button>
            </React.Fragment>
          ),
        }));
        break;
      case '4': //rh
        dataTable = data.map(elm => ({
          name: elm.name,
          username: elm.username,
          last_sync: elm.last_sync ? moment(elm.last_sync).format('DD/MMM/YYYY') : '',
          options: (
            <React.Fragment>
              <button onClick={() => GoDetails('/usuarios/detalles/rh/' + elm.id)} className="btn btn-lgt btn-primary">
                <i className="fas fa-list" /> Detalles
              </button>
              {elm.active ? (
                <button className="btn btn-lgt btn-danger" onClick={() => ShowModalDesactivar(elm, 'desactivar', 'rh')}>
                  <i className="fas fa-ban" /> <span className="btn-text">Desactivar</span>
                </button>
              ) : (
                <button className="btn btn-lgt btn-success" onClick={() => ShowModalDesactivar(elm, 'activar', 'rh')}>
                  <i className="fas fa-check" /> <span className="btn-text">Activar</span>
                </button>
              )}
              <button onClick={() => GoDetails('/usuarios/editar/rh/' + elm.id)} className="btn btn-lgt btn-success">
                <i className="fas fa-edit" /> <span className="btn-text">Editar</span>
              </button>
            </React.Fragment>
          ),
        }));
        break;
      case '5': //gerente
        dataTable = data.map(elm => ({
          name: elm.name,
          username: elm.username,
          last_sync: elm.last_sync ? moment(elm.last_sync).format('DD/MMM/YYYY') : '',
          options: (
            <React.Fragment>
              <button onClick={() => GoDetails('/usuarios/detalles/gerente/' + elm.id)} className="btn btn-lgt btn-primary">
                <i className="fas fa-list" /> <span className="btn-text">Detalles</span>
              </button>
              {elm.active ? (
                <button className="btn btn-lgt btn-danger" onClick={() => ShowModalDesactivar(elm, 'desactivar', 'gerente')}>
                  <i className="fas fa-ban" /> <span className="btn-text">Desactivar</span>
                </button>
              ) : (
                <button className="btn btn-lgt btn-success" onClick={() => ShowModalDesactivar(elm, 'activar', 'gerente')}>
                  <i className="fas fa-check" /> <span className="btn-text">Activar</span>
                </button>
              )}
              <button onClick={() => GoDetails('/usuarios/editar/gerente/' + elm.id)} className="btn btn-lgt btn-success">
                <i className="fas fa-edit" /> <span className="btn-text">Editar</span>
              </button>
            </React.Fragment>
          ),
        }));
        break;
      case '6': //admin
        dataTable = data.map(elm => ({
          name: elm.name,
          username: elm.username,
          last_sync: elm.last_sync ? moment(elm.last_sync).format('DD/MMM/YYYY') : '',
          options: (
            <React.Fragment>
              <button onClick={() => GoDetails('/usuarios/detalles/admin/' + elm.id)} className="btn btn-lgt btn-primary">
                <i className="fas fa-list" /> Detalles
              </button>
              {elm.active ? (
                <button className="btn btn-lgt btn-danger" onClick={() => ShowModalDesactivar(elm, 'desactivar', 'admin')}>
                  <i className="fas fa-ban" /> Desactivar
                </button>
              ) : (
                <button className="btn btn-lgt btn-success" onClick={() => ShowModalDesactivar(elm, 'activar', 'admin')}>
                  <i className="fas fa-check" /> Activar
                </button>
              )}
              <button onClick={() => GoDetails('/usuarios/editar/admin/' + elm.id)} className="btn btn-lgt btn-success">
                <i className="fas fa-edit" /> Editar
              </button>
            </React.Fragment>
          ),
        }));
        break;
      default:
        break;
    }
    return dataTable;
  };

  const paginationHandleClick = page => {
    dispatch({ type: 'setFilters', payload: { ...filters, page } });
    // const location = {
    //     pathname: `${props.location.pathname}`,
    //     state: { ...filters, page }
    // }
    // props.history.replace(location);
  };

  const limitChange = limit => {
    dispatch({ type: 'setFilters', payload: { ...filters, limit } });
  };

  const setActiveTab = tab => {
    dispatch({ type: 'setFilters', payload: { page: 1, active: true, limit: 10 } });
    dispatch({ type: 'setSearch', payload: '' });
    dispatch({ type: 'setElementActive', payload: tabs.find(elm => elm.num === parseInt(tab, 10)) });
    dispatch({ type: 'setActiveTab', payload: tab });
  };

  const confirm = () => {
    apiChangeStatus(modalDesactivar.obj.id).then(response => {
      if (response.error) {
        console.log('paso un error al desactivar');
        NotificationManager.error('algo salio mal', '', 4000);
      } else {
        if (filters.active === '') {
          dispatch({
            type: 'setResults',
            payload: {
              ...results,
              rows: results.rows.map(elm => (elm.id === modalDesactivar.obj.id ? { ...elm, active: !elm.active } : elm)),
            },
          });
        } else {
          dispatch({
            type: 'setResults',
            payload: {
              count: results.count - 1,
              rows: results.rows.filter(elm => elm.id !== modalDesactivar.obj.id),
            },
          });
        }
        NotificationManager.success('Se ha cambiado el status del usuario exitósamente', '', 4000);
      }
      console.log({
        count: results.count - 1,
        rows: results.rows.filter(elm => elm.id !== modalDesactivar.id),
      });
    });
  };

  const handleClose = () => {
    dispatch({ type: 'setModalDesactivar', payload: initialState.modalDesactivar });
  };

  const changeSearch = value => {
    dispatch({ type: 'setSearch', payload: value });
  };

  const changeActiveFilter = value => {
    dispatch({ type: 'setFilters', payload: { ...filters, active: value } });
  };

  const AddUser = path => {
    const location = {
      pathname: `${props.location.pathname}`,
      state: { filters, activeTab },
    };

    props.history.replace(location);
    console.log('path', path);
    props.history.push(path);
  };

  return (
    <div>
      <div className="page-title ">
        <h3>
          <i className="fas fa-users" /> Usuarios
        </h3>
      </div>

      {isTabletOrMobile ? (
        <TabsNavigation activeTab={activeTab} setActiveTab={setActiveTab} />
      ) : (
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      )}

      <div className="card separate">
        <div className="row no-row-margin separate">
          <Filters
            className="col-md-8"
            filters={filters}
            search={search}
            changeSearch={changeSearch}
            changeActiveFilter={changeActiveFilter}
          />

          <div className="col-md-4 tar pd-t">
            <button className="form-control btn btn-success btn-lgt" onClick={() => AddUser(elementActive.link)}>
              <i className="fas fa-plus" /> {elementActive.buttonText}
            </button>
          </div>
        </div>
        <UsersTable elementActive={elementActive} data={mapData(results.rows)} />
        <Paginations
          total={results.count}
          page={filters.page}
          limit={filters.limit}
          pageClick={paginationHandleClick}
          showLimit={true}
          limitOption={[10, 25, 100]}
          limitChange={limitChange}
        />

        <ModalDesactivar {...modalDesactivar} confirm={confirm} handleClose={handleClose} />
      </div>
    </div>
  );
};

export default loaderConnect(Users);
