import React from 'react';
import PropTypes from 'prop-types';
import styles from './lista.module.css';

const PaselistaRFID = ({ updateRFID }) => {
  const [RFID, setRFID] = React.useState('');
  const [scanning, setScanning] = React.useState(false);

  const handleKeyPress = ({ key, target }) => {
    if (key === 'Enter') {
      // Get cards or "scan finished"
      target.blur(); // Updates RFID onBlur
      setTimeout(() => {
        setScanning(false);
      }, 150);
    } else {
      // Receiving code
      setRFID(prev => prev + key);
    }
  };

  const startScanning = () => {
    setScanning(true);
    setRFID('');
  };

  const onBlur = () => {
    setScanning(false);
    updateRFID(RFID);
  };

  const clearRFID = () => {
    setRFID('');
    updateRFID('');
  };

  return (
    <div className="card separate">
      <div className="card-body" style={{ paddingTop: 0, paddingBottom: 0 }}>
        <div className="row">
          <div className="col-12 col-md-1">
            <button type="button" className={`btn btn-primary btn-lgt ${styles['w100']}`} onClick={clearRFID}>
              Limpiar
            </button>
          </div>
          <div className="col-12 col-md-2">
            <button
              className={`btn btn-success btn-lgt ${styles['w100']}`}
              onKeyPress={handleKeyPress}
              onFocus={startScanning}
              onBlur={onBlur}
            >
              {scanning ? 'Leyendo tarjeta...' : 'Escanear tarjeta para filtrar'}
              {scanning && <i className="fas fa-sync fa-spin" />}
            </button>
          </div>
          <div className="col-12 col-md-9">
            <span>
              <strong>RFID: </strong>
            </span>
            <span>{RFID}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

PaselistaRFID.propTypes = {
  updateRFID: PropTypes.func,
};

export default PaselistaRFID;
