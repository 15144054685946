import { createStore, combineReducers } from "redux";
//import { composeWithDevTools } from 'redux-devtools-extension';

//app-reducers
// import notificationReducer from './notificationReducer';
import loaderReducer from "./loaderReducer";
import generatingReportReducer from "./generatingReportReducer";

const mainReducer = combineReducers({
  // notification: notificationReducer,
  loader: loaderReducer,
  generating: generatingReportReducer,
});

export default createStore(mainReducer);
