import React, { useEffect } from "react";
import DateTime from "react-datetime";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import moment from "moment";
import FileSaver from "file-saver";
import { apiGetAttendanceReport } from "../../lib/apiReports";
import { apiGetTemporadas, apiGetTrabajadores } from "../../lib/apiSys21";
import {
  baseUrl,
  makeUrlGET,
  CreateAuthRequest,
  POST,
} from "../../lib/constants";
import useLoading from "../../hooks/useLoading";
import useGeneratingReport from "../../hooks/useGeneratingReport";
import { NotificationManager } from "react-notifications";
import { Fragment } from "react";

const Reports = () => {
  const [options, setOptions] = React.useState({
    fecha_inicial: moment(),
    fecha_final: moment(),
    turno: null,
    supervisor: null,
    tipo: null,
    acumulado: false,
    temporada: null,
  });
  const [typeahead, setTypeahead] = React.useState({
    isLoading: false,
    options: [],
  });

  const [temporadas, setTemporadas] = React.useState([]);

  useEffect(() => {
    apiGetTemporadas()
      .then((response) => {
        if (!response.error) {
          setTemporadas(
            response.rows.map((elm) => ({
              display: elm.descripcion,
              value: elm.sys21_id,
            }))
          );
        }
      })
      .catch();
  }, []);

  const loading = useLoading();
  const generating = useGeneratingReport();

  const handleChangeDTP = (name, date) => {
    setOptions({ ...options, [name]: moment(date) });
  };

  const handleChangeTurno = (e) => {
    setOptions({ ...options, turno: e.target.value });
  };

  const valid = (current) => {
    const today = moment().endOf("day");
    return today.isSameOrAfter(current);
  };

  const handleSelectedWorkerOnTypeahead = (obj) => {
    let supervisor = obj[0];
    setOptions({ ...options, supervisor: supervisor.sys21_id });
  };

  const searchSupervisor = (search) => {
    apiGetTrabajadores({ search, limit: 7 })
      .then((response) => {
        if (response.error) {
        } else {
          setTypeahead({
            isLoading: false,
            options: response.rows.map((elm) => ({
              ...elm,
              id_name: elm.sys21_id + " - " + elm.nombre,
            })),
          });
        }
      })
      .catch();
  };

  const GenerateReport = async () => {
    switch (options.tipo) {
      case "1": // Pase de lista
        try {
          generating.set();
          const name = "ganancias.xlsx";
          const request = await CreateAuthRequest(POST);
          let obj = {
            fecha_inicial: moment(options.fecha_inicial).format("YYYY-MM-DD"),
            fecha_final: moment(options.fecha_final).format("YYYY-MM-DD"),
            background: true,
          };

          if (options.turno) {
            obj.turno = options.turno;
          }

          if (options.supervisor > 0) {
            obj.supervisor = options.supervisor;
          }
          let url = baseUrl + "reporte/asistencia?" + makeUrlGET(obj);
          const resp = await fetch(url, request);

          const contentType = resp.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            const test = await resp.json();
            if (test.error) {
              NotificationManager.error(
                "Ocurrió un error. Inténtelo más tarde.",
                "",
                5000
              );
            } else if (test.type === "Reportes/GlobalLock") {
              NotificationManager.error(test.message, "", 5000);
            }
          } else {
            const file = await resp.blob();
            FileSaver.saveAs(file, name);
          }
        } catch (error) {
          generating.stop();
          console.error(error);
          NotificationManager.error(
            "Ocurrió un error. Inténtelo más tarde.",
            "",
            5000
          );
        }
        break;
      case "2":
      case "3": // Nomina
        try {
          generating.set();
          const name = "nomina.xlsx";
          const request = await CreateAuthRequest(POST);
          let obj = {
            fecha_inicial: moment(options.fecha_inicial).format("YYYY-MM-DD"),
            fecha_final: moment(options.fecha_final).format("YYYY-MM-DD"),
            ...(options.tipo === "3" && { isRH: true }),
            ...(options.acumulado && { acumulado: true }),
            background: true,
          };
          let url = baseUrl + "reporte/nomina?" + makeUrlGET(obj);
          const resp = await fetch(url, request);

          const contentType = resp.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            const test = await resp.json();
            if (test.error) {
              NotificationManager.error(
                "Ocurrió un error. Inténtelo más tarde.",
                "",
                5000
              );
            } else if (test.type === "Reportes/GlobalLock") {
              NotificationManager.error(test.message, "", 5000);
            }
          } else {
            const file = await resp.blob();
            FileSaver.saveAs(file, name);
          }
        } catch (error) {
          generating.stop();
          console.error(error);
          NotificationManager.error(
            "Ocurrió un error. Inténtelo más tarde.",
            "",
            5000
          );
        }
        break;
      case "4": // Horas
        try {
          generating.set();
          const name = "horas.xlsx";
          const request = await CreateAuthRequest(POST);
          let obj = {
            fecha_inicial: moment(options.fecha_inicial).format("YYYY-MM-DD"),
            fecha_final: moment(options.fecha_final).format("YYYY-MM-DD"),
            background: true,
          };
          let url = baseUrl + "reporte/horas?" + makeUrlGET(obj);
          const resp = await fetch(url, request);

          const contentType = resp.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            const test = await resp.json();
            if (test.error) {
              NotificationManager.error(
                "Ocurrió un error. Inténtelo más tarde.",
                "",
                5000
              );
            } else if (test.type === "Reportes/GlobalLock") {
              NotificationManager.error(test.message, "", 5000);
            }
          } else {
            const file = await resp.blob();
            FileSaver.saveAs(file, name);
          }
        } catch (error) {
          generating.stop();
          console.error(error);
          NotificationManager.error(
            "Ocurrió un error. Inténtelo más tarde.",
            "",
            5000
          );
        }
        break;
      case "5": // Nomina por tarjeta
        try {
          generating.set();
          const name = "nomina_tarjeta.xlsx";
          const request = await CreateAuthRequest(POST);
          let obj = {
            fecha_inicial: moment(options.fecha_inicial).format("YYYY-MM-DD"),
            fecha_final: moment(options.fecha_final).format("YYYY-MM-DD"),
            background: true,
          };
          let url = baseUrl + "reporte/tarjeta?" + makeUrlGET(obj);
          const resp = await fetch(url, request);

          const contentType = resp.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            const test = await resp.json();
            if (test.error) {
              NotificationManager.error(
                "Ocurrió un error. Inténtelo más tarde.",
                "",
                5000
              );
            } else if (test.type === "Reportes/GlobalLock") {
              NotificationManager.error(test.message, "", 5000);
            }
          } else {
            const file = await resp.blob();
            FileSaver.saveAs(file, name);
          }
        } catch (error) {
          generating.stop();
          console.error(error);
          NotificationManager.error(
            "Ocurrió un error. Inténtelo más tarde.",
            "",
            5000
          );
        }
        break;
      case "6":
        try {
          generating.set();
          const name = "incidencias.xlsx";
          const request = await CreateAuthRequest(POST);
          let obj = {
            fecha_inicial: moment(options.fecha_inicial).format("YYYY-MM-DD"),
            fecha_final: moment(options.fecha_final).format("YYYY-MM-DD"),
            background: true,
          };
          let url = baseUrl + "reporte/incidencia?" + makeUrlGET(obj);
          const resp = await fetch(url, request);

          const contentType = resp.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            const test = await resp.json();
            if (test.error) {
              NotificationManager.error(
                "Ocurrió un error. Inténtelo más tarde.",
                "",
                5000
              );
            } else if (test.type === "Reportes/GlobalLock") {
              NotificationManager.error(test.message, "", 5000);
            }
          } else {
            const file = await resp.blob();
            FileSaver.saveAs(file, name);
          }
        } catch (error) {
          generating.stop();
          console.error(error);
          NotificationManager.error(
            "Ocurrió un error. Inténtelo más tarde.",
            "",
            5000
          );
        }
        break;
      case "7":
        try {
          generating.set();
          const name = "costos.xlsx";
          const request = await CreateAuthRequest(POST);
          let obj = {
            fecha_inicial: moment(options.fecha_inicial).format("YYYY-MM-DD"),
            fecha_final: moment(options.fecha_final).format("YYYY-MM-DD"),
            temporada: options.temporada,
            background: true,
          };
          let url = baseUrl + "reporte/costo?" + makeUrlGET(obj);
          const resp = await fetch(url, request);

          const contentType = resp.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            const test = await resp.json();
            if (test.error) {
              NotificationManager.error(
                "Ocurrió un error. Inténtelo más tarde.",
                "",
                5000
              );
            } else if (test.type === "Reportes/GlobalLock") {
              NotificationManager.error(test.message, "", 5000);
            }
          } else {
            const file = await resp.blob();
            FileSaver.saveAs(file, name);
          }
        } catch (error) {
          generating.stop();
          console.error(error);
          NotificationManager.error(
            "Ocurrió un error. Inténtelo más tarde.",
            "",
            5000
          );
        }
        break;
      case "8":
        try {
          generating.set();
          const name = "reporte_pm.xlsx";
          const request = await CreateAuthRequest(POST);
          let obj = {
            fecha_inicial: moment(options.fecha_inicial).format("YYYY-MM-DD"),
            fecha_final: moment(options.fecha_final).format("YYYY-MM-DD"),
            background: true,
          };
          let url = baseUrl + "reporte/pm?" + makeUrlGET(obj);
          const resp = await fetch(url, request);

          const contentType = resp.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            const test = await resp.json();
            if (test.error) {
              NotificationManager.error(
                "Ocurrió un error. Inténtelo más tarde.",
                "",
                5000
              );
            } else if (test.type === "Reportes/GlobalLock") {
              NotificationManager.error(test.message, "", 5000);
            }
          } else {
            const file = await resp.blob();
            FileSaver.saveAs(file, name);
          }
        } catch (error) {
          generating.stop();
          console.error(error);
          NotificationManager.error(
            "Ocurrió un error. Inténtelo más tarde.",
            "",
            5000
          );
        }
        break;
      case "9":
        try {
          generating.set();
          const name = "reporte_cajas.xlsx";
          const request = await CreateAuthRequest(POST);
          let obj = {
            fecha_inicial: moment(options.fecha_inicial).format("YYYY-MM-DD"),
            fecha_final: moment(options.fecha_final).format("YYYY-MM-DD"),
            background: true,
          };
          let url = baseUrl + "reporte/cajas?" + makeUrlGET(obj);
          const resp = await fetch(url, request);

          const contentType = resp.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            const test = await resp.json();
            if (test.error) {
              NotificationManager.error(
                "Ocurrió un error. Inténtelo más tarde.",
                "",
                5000
              );
            } else if (test.type === "Reportes/GlobalLock") {
              NotificationManager.error(test.message, "", 5000);
            }
          } else {
            const file = await resp.blob();
            FileSaver.saveAs(file, name);
          }
        } catch (error) {
          generating.stop();
          console.error(error);
          NotificationManager.error(
            "Ocurrió un error. Inténtelo más tarde.",
            "",
            5000
          );
        }
        break;
      default:
        break;
    }
  };

  const handleChangeTipo = ({ target: { value } }) => {
    setOptions((prev) => ({ ...prev, tipo: value }));
  };

  const toggleAcumulado = () => {
    setOptions((prev) => ({ ...prev, acumulado: !prev.acumulado }));
  };

  return (
    <div>
      <div className="page-title">
        <h3>
          <i className="fas fa-chart-bar" /> Reportes
        </h3>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-3">
              <label>
                <b>Tipo de reporte</b>
              </label>
              <select
                className="form-control limit"
                value={options.tipo}
                onChange={handleChangeTipo}
              >
                <option selected disabled>
                  Seleccione tipo
                </option>
                <option value="1">Reporte de Pase de Lista</option>
                <option value="2">Reporte de Nómina</option>
                <option value="3">Reporte de Nómina RH</option>
                <option value="5">Reporte de Nómina por Tarjeta</option>
                <option value="4">Reporte de Horas</option>
                <option value="6">Reporte de Incidencia</option>
                <option value="7">Reporte de Costos</option>
                <option value="8">Reporte de PM</option>
                <option value="9">Reporte de Cajas de Cosecha</option>
              </select>
            </div>
            <div className="col-md-2">
              <label>
                <b>Fecha inicio</b>
              </label>
              <DateTime
                closeOnSelect={true}
                dateFormat="DD/MMM/YYYY"
                placeholder="seleccione fecha"
                timeFormat={false}
                onChange={(value) => handleChangeDTP("fecha_inicial", value)}
                value={options.fecha_inicial}
                viewMode="days"
                locale="es"
                isValidDate={valid}
                inputProps={{
                  required: true,
                  className: "form-control limit",
                }}
              />
            </div>
            <div className="col-md-2">
              <label>
                <b>Fecha fin</b>
              </label>
              <DateTime
                closeOnSelect={true}
                dateFormat="DD/MMM/YYYY"
                placeholder="seleccione fecha"
                timeFormat={false}
                onChange={(value) => handleChangeDTP("fecha_final", value)}
                value={options.fecha_final}
                viewMode="days"
                locale="es"
                isValidDate={valid}
                inputProps={{
                  required: true,
                  className: "form-control limit",
                }}
              />
            </div>
            {options.tipo !== "7" && (
              <Fragment>
                <div className="col-md-2">
                  <label>
                    <b>Turno</b>
                  </label>
                  <select
                    className="form-control limit"
                    value={options.turno}
                    onChange={handleChangeTurno}
                    disabled={options.tipo !== "1"}
                  >
                    <option value={null}>Todos</option>
                    <option value="1">Matitino</option>
                    <option value="2">Vespertino</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <label>
                    <b>Supervisor</b>
                  </label>
                  <AsyncTypeahead
                    id="typeaheadSupervisor"
                    labelKey="id_name"
                    multiple={false}
                    minLength={1}
                    onChange={handleSelectedWorkerOnTypeahead}
                    isLoading={typeahead.isLoading}
                    filterBy={["nombre"]}
                    selectHintOnEnter={true}
                    clearButton={false}
                    searchText="Buscando..."
                    options={typeahead.options}
                    placeholder="Seleccionar supervisor"
                    inputProps={{ className: "limit" }}
                    onSearch={(query) => searchSupervisor(query)}
                    renderMenuItemChildren={(option, props) => {
                      return <div key={option.id}>{option.id_name}</div>;
                    }}
                    disabled={options.tipo !== "1"}
                  />
                </div>
              </Fragment>
            )}
            {options.tipo === "7" && (
              <div className="col-md-3">
                <label>
                  <b>Temporadas</b>
                </label>
                <select
                  className="form-control limit"
                  value={options.temporada}
                  onChange={({ target: { value } }) =>
                    setOptions((prev) => ({ ...prev, temporada: value }))
                  }
                >
                  <option selected disabled>
                    Seleccione temporada
                  </option>
                  {temporadas.map((temp) => (
                    <option value={temp.value}>{temp.display}</option>
                  ))}
                </select>
              </div>
            )}
          </div>
          <div style={{ margin: "1rem" }}></div>
          <div className="row justify-content-between">
            <div className="col-md-2">
              <button
                className="btn btn-light btn-lgt"
                onClick={toggleAcumulado}
                disabled={!["2", "3"].includes(options.tipo)}
              >
                <i
                  className={
                    options.acumulado ? "fas fa-check-square" : "far fa-square"
                  }
                />{" "}
                Reporte de acumulados
              </button>
            </div>
            <div className="col-md-2 tar">
              <button
                className="btn btn-success btn-lgt"
                onClick={GenerateReport}
              >
                Generar reporte
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
