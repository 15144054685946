import React from 'react';
import { apiGetSingleRH } from '../../../lib/apiUsers';
import { withRouter, Link } from 'react-router-dom';
import ModalDesactivar from '../modalDesactivar';
import { apiChangeStatus } from '../../../lib/apiUsers';

const DetailsRH = (props) => {

    const { id } = props.match.params;
    const { NotificationManager } = props;

    const [recursosHumanos, setRecursosHumanos] = React.useState({ name: '', username: '', last_sync: '', device_id: '' });
    const [modalDesactivar, setModalDesactivar] = React.useState({
        show: false,
        name: recursosHumanos.name,
        activate: true,
        type: 'desactivar',
        typeUser: 'Recursos humanos',
        id: recursosHumanos.id
    });

    React.useEffect(() => {
        apiGetSingleRH(id)
            .then(response => {
                if (response.error) {

                }
                else {
                    setRecursosHumanos(response);
                    setModalDesactivar({
                        show: false,
                        name: response.name,
                        typeUser: 'recursos humanos',
                        id: response.id
                    });
                }
            })
    }, [id])

    const handleClose = () => {
        setModalDesactivar({ ...modalDesactivar, show: false });
    }

    const ConfirmModalActivate = () => {
        apiChangeStatus(modalDesactivar.id)
            .then(response => {
                if (response.error) {
                    console.log("paso un error al desactivar");
                    NotificationManager.error("algo salio mal", '', 4000);
                } else {
                    setRecursosHumanos({ ...recursosHumanos, active: !recursosHumanos.active });

                    NotificationManager.success("Se ha cambiado el status del usuario exitósamente", '', 4000);
                }
            })
    }

    const ShowModal = () => {
        setModalDesactivar({
            ...modalDesactivar,
            type: !recursosHumanos.active ? 'activar' : 'desactivar',
            show: true,
        });
    }

    return (<div className="">
        <div className="row ">
            <div className="col-md-6 col-12">
                <h5><i className="fas fa-arrow-left" onClick={() => props.history.goBack()} /> Detalles de RH</h5>
            </div>

            <div className="col-md-6 col-12 tar">
                {
                    recursosHumanos.active ?
                        <button className="btn btn-lgt btn-danger" onClick={ShowModal}><i className="fas fa-ban" /> Desactivar</button>
                        :
                        <button className="btn btn-lgt btn-info" onClick={ShowModal}><i className="fas fa-ban" /> Activar</button>
                }
                <Link to={"/usuarios/editar/rh/" + id} className="btn btn-lgt btn-success"><i className="fas fa-edit" /> Editar</Link>
            </div>

        </div>
        <div className="card separate">
            <div className="card-body">
                <h4>Información RH</h4>
                <hr />
                <div className="row">
                    <div className="col-md-4 col-12">
                        <p className="lbl-title">Nombre RH</p>
                        <label>{recursosHumanos.name}</label>
                    </div>
                    <div className="col-md-4 col-12">
                        <p className="lbl-title">Nombre usuario</p>
                        <label>{recursosHumanos.username}</label>
                    </div>
                    <div className="col-md-4 col-12">
                        <p className="lbl-title">Credencial RFID</p>
                        <label>{recursosHumanos.rfid}</label>
                    </div>
                </div>

            </div>
        </div>
        <ModalDesactivar
            {...modalDesactivar}
            confirm={ConfirmModalActivate}
            handleClose={handleClose}
        />

    </div>)
}

export default withRouter(DetailsRH);