import React from 'react';

const Filters = (props) => {
    const changeSearch = (e) => {
        props.changeSearch(e.target.value);
    }
    // const changeActiveFilter = (e) => {
    //     if (e.target.value === "Todos") {
    //         props.changeActiveFilter(null);
    //     }
    //     else {
    //         props.changeActiveFilter(e.target.value);
    //     }
    // }

    return <div className={props.className}>
        <div className="row">
            <div className="col-md-8">
                <input className="form-control limit" placeholder="Escriba nombre para filtrar" value={props.search} onChange={changeSearch} />
            </div>
            {/* <div className="col-md-4">
                <select className="form-control limit" value={props.filters.active} onChange={changeActiveFilter}>
                    <option value={true}>Activos</option>
                    <option value={false}>Inactivos</option>
                    <option value={''}>Todos</option>
                </select>
            </div> */}
        </div>

    </div>
}

export default Filters;