import React from 'react';
import { DataTable } from '@wafo/table';
import useLoading from '../../hooks/useLoading';
import { apiGetCuadrilleros, apiGetCuadrilleroTrabajadores, apiAsignCuadrillero } from '../../lib/apiCuadrilleros';
import { NotificationManager } from 'react-notifications';
import styles from './cuadrilleros.module.css';
import { Link } from 'react-router-dom';

const CuadrillerosTable = () => {
  const loading = useLoading();

  /** ********************************************** */
  /** Trabajadores */
  /** ********************************************** */

  const [selected, setSelected] = React.useState(undefined);
  const [trabajadores, setTrabajadores] = React.useState([]);

  React.useEffect(() => {
    async function getTrabajadores() {
      loading.set();
      try {
        const resp = await apiGetCuadrilleroTrabajadores(selected);
        setTrabajadores(resp);
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }
    if (selected) getTrabajadores();
  }, [loading, selected]);

  const deleteAsignacion = React.useCallback(
    async id => {
      loading.set();
      try {
        await apiAsignCuadrillero(id, { trabajadores: [] });
        tableRef.current.forceUpdate();
        setSelected(undefined);
        setTrabajadores([]);
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    },
    [loading],
  );

  /** ********************************************** */
  /** Table */
  /** ********************************************** */
  const tableRef = React.useRef();

  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const getData = React.useCallback(
    async ({ size, page, search }) => {
      loading.set();
      try {
        const resp = await apiGetCuadrilleros({
          page,
          limit: size,
          search,
        });
        if (!resp.error) {
          setRows(
            resp.rows.map(row => ({
              id: row.id_cuadrillero,
              name: row.nombre,
              options: row,
              // activo: true,
            })),
          );
          setTotalRows(resp.count);
        } else {
          NotificationManager.error(resp.message);
          setRows([]);
          setTotalRows(0);
        }
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    },
    [loading],
  );

  const columnsConfig = React.useMemo(
    () => ({
      options: val => (
        <React.Fragment>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            title="Detalles"
            style={{ marginRight: '.5rem' }}
            onClick={() => setSelected(val.id_cuadrillero)}
          >
            <i className="fas fa-list" />
          </button>
          <Link
            type="button"
            className="btn btn-sm btn-success"
            title="Editar"
            style={{ marginRight: '.5rem' }}
            to={{
              pathname: '/cuadrilleros/asignar',
              state: {
                cuadrillero: val,
              },
            }}
          >
            <i className="fas fa-edit" />
          </Link>
          <button
            type="button"
            className="btn btn-sm btn-danger"
            title="Eliminar"
            onClick={() => deleteAsignacion(val.id_cuadrillero)}
          >
            <i className="fas fa-trash-alt" />
          </button>
        </React.Fragment>
      ),
    }),
    [deleteAsignacion],
  );

  /** ********************************************** */
  /** Render */
  /** ********************************************** */

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="card">
          <div className="card-body">
            <DataTable
              ref={tableRef}
              locale="es"
              columns={['ID', 'Nombre', 'Opciones']}
              rows={rows}
              totalRows={totalRows}
              tableClass="table table-striped table-sm separate"
              onPagination={getData}
              noRowsMessage="No hay cuadrilleros con trabajadores asignados"
              initialPage={1}
              columnsConfig={columnsConfig}
              // tableWrapperClass={styles['just-table']}
              controls={
                <React.Fragment>
                  <Link className="btn btn-primary" title="Asignar cuadrillero" to="/cuadrilleros/asignar">
                    <i className="fas fa-plus" /> <span>Asignar cuadrillero</span>
                  </Link>
                </React.Fragment>
              }
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div className="card">
          <div className="card-body">
            <h5>
              <i className="fas fa-users" /> Trabajadores asignados a cuadrillero
            </h5>
            {trabajadores.length === 0 && (
              <div className={styles['empty']}>
                <p>Seleccione un cuadrillero para ver trabajadores</p>
              </div>
            )}
            {trabajadores.length > 0 && (
              <ul className={styles['list']}>
                {trabajadores.map(t => (
                  <li key={t.sys21_id}>
                    <span>
                      #{t.sys21_id} - {t.nombre}
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CuadrillerosTable;
