import React from 'react';

const Counters = (props) => {
    const { counters } = props;
    return (
        <div className="row">
            <div className="col-md-3 pd-t">
                <div className="card bg-card-green ">
                    <label><i className="fas fa-id-card" /> Trabajadores</label>
                    <div className="row no-row-margin separate">
                        <div className="col-md-5 tac no-pdg">
                            <h1>{counters.num_trabajadores}</h1>
                        </div>
                        <div className="col-md-7">
                            <label>Activos</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-3 pd-t">
                <div className="card bg-card-blue">
                    <label><i className="fas fa-people-carry" /> Actividades</label>
                    <div className="row no-row-margin separate">
                        <div className="col-md-5 tac no-pdg">
                            <h1>{counters.num_actividad}</h1>
                        </div>
                        <div className="col-md-7">
                            <label>Realizandose</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 pd-t">
                <div className="card bg-card-yellow">
                    <label><i className="fas fa-clipboard-list" /> Tarjetas</label>
                    <div className="row no-row-margin separate">
                        <div className="col-md-6">
                            <div className="row no-row-margin ">
                                <div className="col-md-5 tac no-pdg">
                                    <h1>{counters.num_creadas}</h1>
                                </div>
                                <div className="col-md-7">
                                    <label>Trajetas creadas</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row no-row-margin ">
                                <div className="col-md-5 tac no-pdg">
                                    <h1>{counters.num_terminados}</h1>
                                </div>
                                <div className="col-md-7">
                                    <label>Tarjetas terminadas</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

Counters.defaultProps = {
    counters: {
        num_trabajadores: 0,
        num_actividad: 0,
        num_creadas: 0,
        num_terminados: 0
    }
}

export default Counters;