import { baseUrl, GET, POST, PUT, CreateAuthRequest, makeUrlGET } from './constants';

const urlModule = baseUrl + "sync/";
//region Cultivo
export const apiGetCultivos = async (filters) => {

    let url = urlModule + "prod/list?" + makeUrlGET(filters);
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetSingleCultivo = async (id) => {

    let url = urlModule + "prod?id=" + id;
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}
//endregion

//region Temporadas
export const apiGetTemporadas = async (filters) => {

    let url = urlModule + "temp/list?" + makeUrlGET(filters);
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetSingleTemporada = async (id) => {

    let url = urlModule + "temp?id=" + id;
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}
//endregion

//region LOTES
export const apiGetLotes = async (filters) => {

    let url = urlModule + "lote/list?" + makeUrlGET(filters);
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetSingleLote = async (id) => {

    let url = urlModule + "lote?id=" + id;
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}
//endregion

//region ACTIVIDADES
export const apiGetActividades = async (filters) => {

    let url = urlModule + "act/list?" + makeUrlGET(filters);
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetSingleActividad = async (id) => {

    let url = urlModule + "act?id=" + id;
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetActivityType = async () => {
    let url = urlModule + "tipo_act";
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}

export const apiSetActivityType = async (id, obj) => {
    let url = urlModule + "tipo_act?id=" + id;
    let request = await CreateAuthRequest(PUT, obj, true);

    return fetch(url, request)
        .then(response => response.json())
}
//endregion


//region ACTIVIDADES
export const apiGetTrabajadores = async (filters) => {

    let url = urlModule + "user/list?" + makeUrlGET(filters);
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}


//endregion


//region ACTIVIDADES
export const apiGetEmpresas = async (filters) => {

    let url = urlModule + "empresa/list?" + makeUrlGET(filters);
    let request = await CreateAuthRequest(GET, null, true);

    return fetch(url, request)
        .then(response => response.json())
}
//endregion
