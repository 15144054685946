import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import PaseLista from './paseLista';
import NewCard from '../NewCard/newCardContainer';
import CardDetails from './CardDetails/cardDetails';
import Estadisticas from './Estadisticas/estadisticasPL';
import ResultsCruce from './CruceInfo/resultsCruce';
import RegisterCruce from './CruceInfo/registerCruce';
import BonusTable from './RH/bonusTable/bonusTable';
import Gerente from './Gerente/gerente';

const PaseListaRoutes = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={match.url} to={`${match.url}/rh`} />
      <Route
        path={`${match.url}/secretario`}
        render={props => <PaseLista key="secretario" {...props} listMode="secretario" />}
      />
      <Route exact path={`${match.url}/rh`} render={props => <PaseLista key="rh" {...props} listMode="rh" />} />
      <Route exact path={`${match.url}/nuevatarjeta`} component={NewCard} />
      <Route exact path={`${match.url}/detalle/:id`} component={CardDetails} />
      <Route exact path={`${match.url}/estadisticas`} component={Estadisticas} />
      <Route exact path={`${match.url}/resultadoscruce`} component={ResultsCruce} />
      <Route exact path={`${match.url}/registrocruce/:id?`} component={RegisterCruce} />
      <Route exact path={`${match.url}/bono`} component={BonusTable} />
      <Route exact path={`${match.url}/gerente`} component={Gerente} />
    </Switch>
  );
};

export default PaseListaRoutes;
