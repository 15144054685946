import React, { useState } from 'react';
import jwtDecode from 'jwt-decode';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Switch, Redirect, Route } from 'react-router-dom';
import SideBar from './sideBar';
import Loader from '../../Components/Loader/index';
import Usuarios from '../Users/users';
import DetailsUser from '../Users/detailsUser';
import Sys21 from '../Sys21/sysCatalogos';
import CatalogoDetails from '../Sys21/catalogoDetail';
import Home from '../Inicio/inicio';
import Reports from '../Reports/reportes';
import Incidents from '../incidents/incidents';
import PaseListaRoutes from '../PaseLista/paseListaRoutes';
import './style.scss';
import Cuadrilleros from '../Cuadrilleros/cuadrilleros';
import ReportLoader from '../../Components/ReportLoader/reportLoader';
import WSReportLoader from '../../Components/ReportLoader/wsReportLoader';

const Container = () => {
  const user = jwtDecode(localStorage.token);
  const [city, setCity] = useState(localStorage.city_id ? localStorage.city_id : user.city_id);
  const [notificationCounter] = useState(10);

  const propsEmpaquetados = React.useMemo(
    () => ({
      user: jwtDecode(localStorage.token),
      city,
      setCity: id => {
        localStorage.setItem('city_id', id);
        setCity(id);
      },
      NotificationManager,
    }),
    [city],
  );

  return (
    <div id="wrapper" className="">
      <WSReportLoader />
      <Loader />
      <ReportLoader />
      <SideBar notificationCounter={notificationCounter} {...propsEmpaquetados} />
      <div id="page-content-wrapper">
        <div className="page-container">
          <Switch>
            <Redirect exact={true} from="/" to="/inicio" />
            <Route path="/inicio" exact={true} render={props => <Home {...props} {...propsEmpaquetados} />} />
            <Route path="/usuarios/agregar/" exact={false} render={props => <DetailsUser {...props} {...propsEmpaquetados} />} />
            <Route path="/usuarios/detalles/" exact={false} render={props => <DetailsUser {...props} {...propsEmpaquetados} />} />
            <Route path="/usuarios/editar/" exact={false} render={props => <DetailsUser {...props} {...propsEmpaquetados} />} />
            <Route path="/usuarios" exact={true} render={props => <Usuarios {...props} {...propsEmpaquetados} />} />
            <Route path="/sys21/detalles/" exact={false} render={props => <CatalogoDetails {...props} {...propsEmpaquetados} />} />
            <Route path="/sys21" exact={true} render={props => <Sys21 {...props} {...propsEmpaquetados} />} />
            <Route path="/reportes" exact={true} render={props => <Reports {...props} {...propsEmpaquetados} />} />
            <Route path="/paselista" component={PaseListaRoutes} />
            {user.role_id === 1 && (
              <Route path="/incidencias" exact={false} render={props => <Incidents {...props} {...propsEmpaquetados} />} />
            )}
            {(user.role_id === 1 || user.role_id === 3) && (
              <Route path="/cuadrilleros" exact={false} component={Cuadrilleros} />
            )}
            <Redirect exact={true} from="*" to="/" />
          </Switch>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default Container;

