import React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const CustomTypeahead = props => {
  const { getElements, name, filterBy, multiple, clear, setSelectedElm, paramsToSearch, initialValue } = props;
  const [typeaheadObj, setTypeaheadObj] = React.useState({ isLoading: false, options: [] });
  const [selectedElement, setSelectedElement] = React.useState(() => {
    if (multiple) {
      return initialValue ? initialValue : [];
    } else {
      return initialValue ? [initialValue] : null;
    }
  });

  React.useEffect(() => {
    if (initialValue) {
      setSelectedElm(name, initialValue);
    }
  }, [name, initialValue, setSelectedElm]);

  const handleSelectedElementOnTypeahead = obj => {
    console.log('test');
    if (obj.length === 0) {
      if (multiple) {
        setSelectedElm(name, []);
        setSelectedElement([]);
      } else {
        setSelectedElm(name, { sys21_id: 0 });
        setSelectedElement({ sys21_id: 0 });
      }
    } else {
      const elm = obj[0];
      if (multiple) {
        setSelectedElm(name, obj);
        setSelectedElement(obj);
      } else {
        setSelectedElm(name, elm);
        setSelectedElement([elm]);
      }
    }
  };

  const searchElement = search => {
    setTypeaheadObj({ isLoading: true, options: [] });
    let filters = { search, limit: 7 };
    if (paramsToSearch) {
      filters = { search, limit: 7, ...paramsToSearch };
    }
    getElements(filters)
      .then(response => {
        if (response.error) {
        } else {
          setTypeaheadObj({
            isLoading: false,
            options: response.rows.map(elm => ({ ...elm, id_name: elm.sys21_id + ' - ' + elm.nombre })),
          });
        }
      })
      .catch()
      .finally(response => {
        setTypeaheadObj({ isLoading: false });
      });
  };

  return (
    <div>
      <AsyncTypeahead
        id={'typeahead' + name}
        labelKey="id_name"
        multiple={multiple}
        minLength={1}
        onChange={handleSelectedElementOnTypeahead}
        isLoading={typeaheadObj.isLoading}
        filterBy={filterBy}
        selectHintOnEnter={true}
        clearButton={clear}
        searchText="Buscando..."
        selected={selectedElement}
        options={typeaheadObj.options}
        placeholder={'Ingrese nombre de ' + name}
        onSearch={query => searchElement(query)}
        renderMenuItemChildren={(option, props) => {
          return <div key={option.id}>{option.id_name}</div>;
        }}
        required={true}
      />
    </div>
  );
};

CustomTypeahead.defaultProps = {
  multiple: false,
};

export default CustomTypeahead;
