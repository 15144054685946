import React from 'react';

const MobileNavigation = (props) => {
    const { activeTab, setActiveTab } = props;
    
    let navsOptions = [
        { name: "Temporadas", value: '1' },
        { name: "Cultivos", value: '2' },
        { name: "Lotes", value: '3' },
        { name: "Actividades", value: '4' },
        { name: "Trabajadores", value: '5' },
        { name: "Empresas", value: '6' },
    ]

    const handleNavigate = (e) => {
        setActiveTab(e.target.value);
    }

    return <div>
        <select className="form-control" value={activeTab} onChange={handleNavigate}>
            {
                navsOptions.map(
                    type =>
                        <option key={type.value} value={type.value}>{type.name}</option>
                )
            }
        </select>
    </div>

}

export default MobileNavigation;