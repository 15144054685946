import React from 'react';
import { apiGetSingleSupervisor, apiChangeStatus } from '../../../lib/apiUsers';
import { withRouter, Link } from 'react-router-dom';
import ModalDesactivar from '../modalDesactivar';

const DetailsSupervisor = (props) => {

    const { id } = props.match.params;
    const { NotificationManager } = props;

    const [supervisor, setSupervisor] = React.useState({ name: '', username: '', last_sync: '', device_id: '' });
    const [modalDesactivar, setModalDesactivar] = React.useState({
        show: false,
        name: supervisor.name,
        activate: true,
        type: 'desactivar',
        typeUser: 'supervisor',
        id: supervisor.id
    });

    React.useEffect(() => {
        apiGetSingleSupervisor(id)
            .then(response => {
                if (response.error) {

                }
                else {
                    setSupervisor(response);
                    setModalDesactivar({
                        show: false,
                        name: response.name,
                        typeUser: 'supervisor',
                        id: response.id
                    });
                }
            })
    }, [id])

    const handleClose = () => {
        setModalDesactivar({ ...modalDesactivar, show: false });
    }

    const ConfirmModalActivate = () => {
        apiChangeStatus(modalDesactivar.id)
            .then(response => {
                if (response.error) {
                    console.log("paso un error al desactivar");
                    NotificationManager.error("algo salio mal", '', 4000);
                } else {
                    setSupervisor({ ...supervisor, active: !supervisor.active });

                    NotificationManager.success("Se ha cambiado el status del usuario exitósamente", '', 4000);
                }
            })
    }

    const ShowModal = () => {
        setModalDesactivar({
            ...modalDesactivar,
            type: !supervisor.active ? 'activar' : 'desactivar',
            show: true,
        });
    }

    return (<div className="">
        <div className="row ">
            <div className="col-md-6 col-12">
                <h5><i className="fas fa-arrow-left" onClick={() => props.history.goBack()} /> Detalles de supervisor</h5>
            </div>

            <div className="col-md-6 col-12 tar">
                {
                    supervisor.active ?
                        <button className="btn btn-lgt btn-danger" onClick={ShowModal}><i className="fas fa-ban" /> Desactivar</button>
                        :
                        <button className="btn btn-lgt btn-info" onClick={ShowModal}><i className="fas fa-ban" /> Activar</button>
                }
                <Link to={"/usuarios/editar/supervisor/" + id} className="btn btn-lgt btn-success"><i className="fas fa-edit" /> Editar</Link>
            </div>

        </div>
        <div className="card separate">
            <div className="card-body">
                <h4>Información supervisor</h4>
                <hr />
                <div className="row">
                    <div className="col-md-4 col-12">
                        <p className="lbl-title">Nombre supervisor</p>
                        <label>{supervisor.name}</label>
                    </div>
                    <div className="col-md-4 col-12">
                        <p className="lbl-title">Nombre usuario</p>
                        <label>{supervisor.username}</label>
                    </div>
                    <div className="col-md-4 col-12">
                        <p className="lbl-title">Credencial RFID</p>
                        <label>{supervisor.rfid}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-12">
                        <p className="lbl-title">Id Sys21</p>
                        <label>{supervisor.sys21_id} {supervisor.sys21_name}</label>
                    </div>

                </div>
            </div>
            <ModalDesactivar
                {...modalDesactivar}
                confirm={ConfirmModalActivate}
                handleClose={handleClose}
            />
        </div>

    </div>)
}

export default withRouter(DetailsSupervisor);