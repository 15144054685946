import React from "react";
import { apiGetGerente, apiGetGerentePDF } from "../../../lib/apiPaseLista";
import Cultivo from "./cultivo";
import moment from "moment";
import DateTime from "react-datetime";
import { NotificationManager } from "react-notifications";
import FileSaver from "file-saver";
import useLoading from "../../../hooks/useLoading";

const Gerente = () => {
  const loading = useLoading();

  const [info, setInfo] = React.useState({
    cultivos: [],
    total: 0,
  });
  const [selectedDate, setSelectedDate] = React.useState(moment());

  React.useEffect(() => {
    async function getGerente() {
      const resp = await apiGetGerente({
        fecha: selectedDate.format("yyyy-MM-DD"),
      });
      setInfo(resp);
    }
    getGerente();
  }, [selectedDate]);

  const valid = (current) => {
    const today = moment().endOf("day");
    return today.isSameOrAfter(current);
  };

  const getPDF = React.useCallback(async () => {
    loading.set();
    try {
      const resp = await apiGetGerentePDF({
        fecha: selectedDate.format("yyyy-MM-DD"),
      });

      const contentType = resp.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const respObj = resp.json();
        if (respObj.error) {
          NotificationManager.error(
            "Ocurrió un error. Inténtelo más tarde.",
            "",
            5000
          );
        }
      } else {
        const file = await resp.blob();
        FileSaver.saveAs(file, "horasExtra.pdf");
      }
    } catch (error) {
      console.error(error);
      NotificationManager.error(
        "Ocurrió un error. Inténtelo más tarde.",
        "",
        5000
      );
    }
    loading.stop();
  }, [selectedDate, loading]);

  return (
    <div>
      <div className="page-title">
        <h3>
          <i className="fas  fa-clipboard-list" /> Gerente
        </h3>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div
            className="card card-body"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div style={{ flex: 1, marginRight: "1rem" }}>
              <DateTime
                closeOnSelect={true}
                dateFormat="DD/MMM/YYYY"
                placeholder="seleccione fecha"
                timeFormat={false}
                onChange={(date) => setSelectedDate(moment(date))}
                value={selectedDate}
                viewMode="days"
                locale="es"
                isValidDate={valid}
                inputProps={{
                  required: true,
                  className: "form-control limit",
                }}
              />
            </div>

            <button type="button" className="btn btn-success" onClick={getPDF}>
              Generar PDF
            </button>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card card-body">
            <h5>
              <small>Total de horas extras: </small>
              {info.total}
            </h5>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="row">
            {!info.cultivos.length && (
              <div className="col-12">
                <div className="card" style={{ marginTop: ".5rem" }}>
                  <div className="card-body">
                    Sin cultivos en la fecha seleccionada
                  </div>
                </div>
              </div>
            )}
            {info.cultivos.map((c) => (
              <Cultivo
                cultivo={{
                  sys21_id: c.id,
                  nombre_cultivo: c.nombre,
                  total: c.total,
                  actividades: c.actividades.map((a) => ({
                    sys21_id: a.id_actividad,
                    nombre: a.nombre,
                    total: a.total,
                    trabajadores: a.num_trabajadores || 0,
                  })),
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gerente;
