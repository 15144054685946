import React from 'react';
import PropTypes from 'prop-types';

const CardCultivos = props => {
  const { cultivos } = props;
  return (
    <div className="card separate">
      <div className="card-body">
        <div className="">
          <h5>
            <i className="fas fa-id-card" /> Cultivos
          </h5>
        </div>

        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Id</th>
                <th>Sys21 Id</th>
                <th>Nombre de cultivo</th>
              </tr>
            </thead>
            <tbody>
              {cultivos.length > 0 ? (
                cultivos.map(cultivo => (
                  <tr key={cultivo.id}>
                    <td>{cultivo.id}</td>
                    <td>{cultivo.sys21_id}</td>
                    <td>{cultivo.nombre}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">Sin cultivo definido</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

CardCultivos.propTypes = {
  cultivos: PropTypes.array,
};

CardCultivos.defaultProps = {
  cultivos: [],
};

export default CardCultivos;
