import { useMemo } from "react";
import { useDispatch } from "react-redux";

const useGeneratingReport = () => {
  const dispatch = useDispatch();

  const generating = useMemo(
    () => ({
      set: () => dispatch({ type: "SHOW_GENERATING", payload: true }),
      stop: () => dispatch({ type: "SHOW_GENERATING", payload: false }),
    }),
    [dispatch]
  );

  return generating;
};

export default useGeneratingReport;
