import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '../Modules/Login/login';
// import PrivateRoute from './privateRoute';
import WafoPrivateRoute from './wafoPrivateRoute';
import Container from '../Modules/Container/Container';

const AppRoutes = () => (
  <Fragment>
    <Switch>
      {/* <PrivateRoute></PrivateRoute> */}
      <Route path="/login" component={Login} />
      <WafoPrivateRoute path="/" component={Container} />
    </Switch>
  </Fragment>
);

export default AppRoutes;
