import React from 'react';
import ApuntadoresList from './apuntadoresList';
import SupervisoresList from './supervisoresList';
import loaderConnect from '../../../Components/Loader/loaderConnect';
import Counters from './counters';
import { aapiGetStatistics } from '../../../lib/apiPaseLista';
import DateTime from 'react-datetime';
import moment from 'moment';

const EstadisticasPaseLista = (props) => {
    const { showLoader } = props;

    const [statistics, setStatistics] = React.useState({
        num_proceso: 21,
        num_finalizada_apuntador: 5,
        num_finalizada_secretario: 5,
        num_validada_supervisor: 1,
        lst_apuntadores: [],
        lst_supervisores: []
    });

    const [dates, setDates] = React.useState({ start_date: moment(), end_date: moment() });

    React.useEffect(() => {
        showLoader(true);
        console.log({ start_date: moment(dates.start_date).format('YYYY-MM-DD'), end_date: moment(dates.end_date).format('YYYY-MM-DD') });
        aapiGetStatistics({ fecha_inicial: moment(dates.start_date).format('YYYY-MM-DD'), 
        fecha_final: moment(dates.end_date).format('YYYY-MM-DD') })
            .then(response => {
                if (response.error) {

                } else {
                    setStatistics(response);
                }
            })
            .catch()
            .finally(() => {
                showLoader(false);
            })
    }, [dates])

    const valid = (name, dateValue) => {
        const today = moment().endOf('day');
        return today.isSameOrAfter(dateValue);
    };

    const handleChangeDTP = (name, dateValue) => {

        const today = moment().endOf('day');

        if (today.isSameOrAfter(dateValue)) {
            if (name === "end_date") {
                if (moment(dates.start_date).isAfter(dateValue)) {
                    setDates({ ...dates, start_date: dateValue, [name]: moment(dateValue) });
                }
                else {
                    setDates({ ...dates, [name]: moment(dateValue) });
                }
            } else {
                if (moment(dates.end_date).isBefore(dateValue)) {
                    setDates({ ...dates, end_date: dateValue, [name]: moment(dateValue) });
                }
                else {
                    setDates({ ...dates, [name]: moment(dateValue) });
                }
            }
        }
        else {
            return false;
        }

    }

    return (
        <div>
            <div className="page-title">
                <h3><i className="fas fa-clipboard-list" /> Pase de lista</h3>
            </div>
            <div className="card bg-card-violet">
                <div className="row no-row-margin">
                    <div className="col-md-6">
                        <label><i className="fas fa-chart-line" /> Estadísticas generales</label>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="">
                            <div className="">
                                <div className="row no-row-margin">
                                    <div className="col-md-6">
                                        <DateTime closeOnSelect={true}
                                            dateFormat='DD/MMM/YYYY'
                                            placeholder="seleccione fecha"
                                            timeFormat={false}
                                            onChange={(date) => handleChangeDTP("start_date", date)}
                                            value={dates.start_date}
                                            viewMode='days'
                                            locale="es"
                                            isValidDate={(current) => valid('start_date', current)}
                                            inputProps={{
                                                required: true,
                                                className: "form-control limit"
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <DateTime closeOnSelect={true}
                                            dateFormat='DD/MMM/YYYY'
                                            placeholder="seleccione fecha"
                                            timeFormat={false}
                                            onChange={(date) => handleChangeDTP("end_date", date)}
                                            value={dates.end_date}
                                            viewMode='days'
                                            locale="es"
                                            isValidDate={(current) => valid('end_date', current)}
                                            inputProps={{
                                                required: true,
                                                className: "form-control limit"
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Counters counters={{
                num_proceso: statistics.num_proceso,
                num_finalizada_apuntador: statistics.num_finalizada_apuntador,
                num_finalizada_secretario: statistics.num_finalizada_secretario,
                num_validada_supervisor: statistics.num_validada_supervisor,
            }} />

            <ApuntadoresList
                list={statistics.lst_apuntadores}
            />
            <SupervisoresList
                list={statistics.lst_supervisores}
            />


        </div>)
}

export default loaderConnect(EstadisticasPaseLista);