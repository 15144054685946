import React from 'react';
import { Link } from 'react-router-dom';
import Table from '../../Components/table';



const UsersTable = (props) => {

    const { elementActive, data } = props;


    return <div className="card-body">
        <div className="table-responsive users-table">
            <Table
                columns={elementActive.columns}
                data={data}
                tableClass="table table-striped separate"
            />
        </div>
    </div>
}



export default UsersTable;