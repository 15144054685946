import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const WafoPrivateRoute = ({ component: Component, ...rest }) => {
  const token = window.localStorage.token;
  if (token && token !== 'undefined') {
    return <Route {...rest} component={Component} />;
  } else {
    localStorage.removeItem('token');
    return <Redirect to="/login" />;
  }
};

export default WafoPrivateRoute;
