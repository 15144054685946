import React from 'react';
import jwtDecode from 'jwt-decode';

const useUser = () => {
  const user = React.useMemo(() => {
    const token = localStorage.token;
    if (token) {
      return jwtDecode(token);
    }
    return {
      id: 0,
      role_id: -1,
      name: 'No name',
      username: 'username',
      iat: 0,
      exp: 0,
    };
  }, []);

  return user;
};

export default useUser;
