import React from 'react';
import { Route, Switch } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import CultivoDetails from './detailCultivo';
import LoteDetails from './detailLote';
import ActividadDetail from './detailActividad';

const CatalogoDetails = (props) => {

    const view = () => {
        return <Switch>
            
            <Route key="2" path="/sys21/detalles/cultivo/:id" exact={true} component={() => <CultivoDetails {...props} />} />
            <Route key="3" path="/sys21/detalles/lote/:id" exact={true} component={() => <LoteDetails {...props} />} />
            <Route key="4" path="/sys21/detalles/actividad/:id" exact={true} component={() => <ActividadDetail {...props} />} />

        </Switch>
    }

    return <div>
        <div className="page-title ">
            <h3><i className="fas fa-book-open" /> Sys21</h3>
        </div>
        <div>
            <h5><i className="fas fa-arrow-left" onClick={() => { props.history.goBack() }} /> Volver</h5>
        </div>
        {view()}
    </div>
}

export default withRouter(CatalogoDetails);