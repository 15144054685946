import React from "react";
import { Modal } from 'react-bootstrap';


const ModalConfirmSecretary = (props) => {
    const { show, confirm, handleClose } = props;

    const HandleClose = () => {
        handleClose();
    }

    const Confirm = () => {
        confirm();
        HandleClose();
    }


    return (<Modal show={show} onHide={HandleClose} centered
        dialogClassName="modal-20w"
    >
        <Modal.Header closeButton>
            <Modal.Title>Aprobar tarjeta</Modal.Title>
        </Modal.Header>
        <Modal.Body  >
            <div className="tac">
                <label>¿Esta seguro que desea aprobar esta tarjeta?</label>
                <label>Una vez aprobada, ésta pasará al supervisor para su validación</label>
            </div>
            <div className="modal-footer">
                <button className="btn btn-danger btn-lgt" onClick={HandleClose}>Cancelar</button>
                <button className="btn btn-success btn-lgt" onClick={Confirm}>Aprobar</button>
            </div>
        </Modal.Body>
    </Modal>)
}

export default ModalConfirmSecretary;