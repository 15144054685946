import React from 'react';
import Tabs from './tabs';
import CatalogoTable from './catalogoTable';
import Paginations from '../../Components/Pagination/pagination';
import { Link } from 'react-router-dom';
import loaderConnect from '../../Components/Loader/loaderConnect';
import { apiGetTemporadas, apiGetCultivos, apiGetLotes, apiGetActividades, apiGetTrabajadores, apiGetEmpresas } from '../../lib/apiSys21';
import Filters from './filters';
import useDebounce from '../../Components/useDebounce';
import { useMediaQuery } from 'react-responsive';
import MobileNavigation from './mobileNavigation';

const tabs = [
    {
        num: 1,//temporadas
        GetFunction: apiGetTemporadas,
        columns: ["ID", "Descripción", "Fecha inicial", "Fecha final", "Activo"],
    },
    {
        num: 2,//cultivos
        GetFunction: apiGetCultivos,
        columns: ["ID", "Nombre de cultivo", "Variedades", "Activo", "Opciones"],
    },
    {
        num: 3, //lotes
        GetFunction: apiGetLotes,
        columns: ["ID", "Nombre de lote", "Nomenclatura", "Activo", "Opciones"],
    },
    {
        num: 4,
        GetFunction: apiGetActividades,
        columns: ["ID", "Nombre de actividad", "Tipo actividad", "Activo", "Opciones"],
    },
    {
        num: 5,
        GetFunction: apiGetTrabajadores,
        columns: ["N° de trabajador", "Nombre de trabajador", "Id credencial", 'PM', "Activo"],
    },
    {
        num: 6,
        GetFunction: apiGetEmpresas,
        columns: ["ID", "Nombre de empresa", "Activo"],
    },
]

const initialState = {
    activeTab: "1",
    filters: { page: 1, limit: 10, active: true },
    results: { count: 0, rows: [] },
    elementActive: tabs[0],
    search: ''
};

const reducer = (state, action) => {
    switch (action.type) {
        case "setActiveTab":
            return { ...state, activeTab: action.payload, search: '', filters: initialState.filters }
        case "setResults":
            return { ...state, results: action.payload }
        case "setElementActive":
            return { ...state, elementActive: action.payload }
        case 'setFilters':
            return { ...state, filters: action.payload }
        case 'setSearch':
            return { ...state, search: action.payload }
        default:
            return { ...state }
    }
}

const Sys21 = (props) => {

    const firstState = props.location.state ? {
        ...initialState,
        filters: props.location.state.filters,
        activeTab: props.location.state.activeTab,
        elementActive: tabs.find(elm => elm.num === parseInt(props.location.state.activeTab, 10))
    }
        : initialState;

    const { showLoader } = props;
    const [state, dispatch] = React.useReducer(reducer, firstState);
    const { activeTab, results, elementActive, filters, search } = state;
    const debounce = useDebounce(search, 300);
    const isTabletOrMobile = useMediaQuery({ maxWidth: 768 })

    React.useEffect(() => {
        showLoader(true);
        elementActive.GetFunction({ ...filters, search: debounce })
            .then(response => {
                if (!response.error) {
                    dispatch({ type: 'setResults', payload: response });
                }
            })
            .finally(r => {
                showLoader(false);
            })
    }, [elementActive, filters, debounce, showLoader])


    const GoDetails = (path, data) => {

        const location = {
            pathname: `${props.location.pathname}`,
            state: { filters, activeTab}
        }

        props.history.replace(location);
        props.history.push(path, { extra: data });
    }


    const mapData = (data) => {
        let dataTable = [];
        switch (activeTab) {
            case "1"://Temporadas
                dataTable = data.map(elm => ({
                    id: elm.sys21_id,
                    description: elm.descripcion,
                    initial_date: elm.fecha_inicial,
                    final_date: elm.fecha_final,
                    activo: elm.activo === 1 ? "si" : 'no'
                }))
                break;
            case "2"://cultivos
                dataTable = data.map(elm => ({
                    id: elm.sys21_id,
                    name: elm.nombre,
                    varidades: elm.variedades,
                    activo: elm.activo === 1 ? "si" : 'no',
                    options: <button className="btn btn-lgt btn-primary"
                        onClick={() => GoDetails('/sys21/detalles/cultivo/' + elm.sys21_id)}>
                        <i className="fas fa-list" /> Detalles</button>
                }))
                break;
            case "3"://lotes
                dataTable = data.map(elm => ({
                    id: elm.sys21_id,
                    name: elm.nombre,
                    nomenclatura: elm.nomenclatura,
                    activo: elm.activo === 1 ? "si" : 'no',
                    options: <button className="btn btn-lgt btn-primary"
                        onClick={() => GoDetails('/sys21/detalles/lote/' + elm.sys21_id)}>
                        <i className="fas fa-list" /> Detalles</button>
                }))
                break;
            case "4"://actividades
                dataTable = data.map(elm => ({
                    id: elm.sys21_id,
                    name: elm.nombre,
                    type: elm.tipo_actividad,
                    activo: elm.activo === 1 ? "si" : 'no',
                    options: <button className="btn btn-lgt btn-primary"
                        onClick={() => GoDetails('/sys21/detalles/actividad/' + elm.sys21_id, elm)}>
                        <i className="fas fa-list" /> Detalles</button>

                }))
                break;
            case "5"://trabajador
                dataTable = data.map(elm => ({
                    id: elm.sys21_id,
                    name: elm.nombre,
                    id_credencial: elm.sys21_id,
                    contratista_externo: elm.contratista_externo === 1 ? "Si" : "No",
                    activo: elm.activo === 1 ? "si" : 'no'
                }))
                break;
            case "6"://empresa
                dataTable = data.map(elm => ({
                    id: elm.sys21_id,
                    name: elm.nombre,
                    activo: elm.activo === 1 ? "si" : 'no'
                }))
                break;
            default:
                break;
        }
        return dataTable;
    }

    const paginationHandleClick = (page) => {
        dispatch({ type: "setFilters", payload: { ...filters, page } });
    }

    const limitChange = (limit) => {
        dispatch({ type: "setFilters", payload: { ...filters, limit } });
    }

    const setActiveTab = (tab) => {
        let elementActive = tabs.find(elm => elm.num === parseInt(tab, 10));
        dispatch({ type: 'setActiveTab', payload: tab });
        dispatch({ type: 'setElementActive', payload: elementActive });
    }

    const changeSearch = (value) => {
        dispatch({ type: 'setSearch', payload: value });
    }

    return <div>
        <div className="page-title ">
            <h3><i className="fas fa-book-open" /> Sys21</h3>
        </div>

        {
            isTabletOrMobile ?
                <MobileNavigation activeTab={activeTab} setActiveTab={setActiveTab} />
                :
                <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
        }


        <div className="card separate">
            <div className="card-body">
                <Filters changeSearch={changeSearch} search={search} />
                <CatalogoTable elementActive={elementActive}
                    data={mapData(results.rows)}
                />
            </div>
            <Paginations
                total={results.count}
                page={filters.page}
                limit={filters.limit}
                pageClick={paginationHandleClick}
                showLimit={true}
                limitOption={[10, 25, 100]}
                limitChange={limitChange}
            />
        </div>
    </div>
}

export default loaderConnect(Sys21);