import React from 'react';
import PropTypes from 'prop-types';
import DateTime from 'react-datetime';
import moment from 'moment';

function isValidDate(current) {
  const today = moment().endOf('day');
  return today.isSameOrAfter(current);
}

const WafoDateTime = ({ name, customClass, handleInputChange, value, valid, touched, errors, placeholder, dateFormat, viewMode }) => {
  const handleOnChange = value => {
    let newValue = '';
    if (moment.isMoment(value)) {
      newValue = value.format('YYYY-MM-DD');
    }

    handleInputChange({
      target: { name, value: newValue },
      type: 'change',
    });
  };

  const inputValue = React.useMemo(() => {
    return moment(value);
  }, [value]);

  return (
    <div className={`form-group ${customClass}`}>
      <DateTime
        locale="es"
        placeholder={placeholder}
        dateFormat={dateFormat}
        viewMode={viewMode}
        timeFormat={false}
        closeOnSelect
        inputProps={{
          required: true,
          className: 'form-control tac limit',
        }}
        isValidDate={isValidDate}
        onChange={handleOnChange}
        value={inputValue}
      />
      {!valid && touched && (
        <ul className="errors">
          {errors.map(error => (
            <li key={error.error}>{error.message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

WafoDateTime.propTypes = {
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  viewMode: PropTypes.string,
  handleInputChange: PropTypes.func,
};

WafoDateTime.defaultProps = {
  placeholder: 'Seleccione fecha',
  dateFormat: 'DD/MMM/YYYY',
  viewMode: 'days',
  handleInputChange: f => f,
};

export default WafoDateTime;
